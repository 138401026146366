import mock from "MockServer/utils/mock";
import Data from "MockServer/db.json";

let { propertyManagementUsers } = Data;

mock.onPost("/property_management_property_details").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const phone = parseInt(data.phone);
      const responseData = propertyManagementUsers.filter(
        (item) => item.phone.toString() === phone.toString()
      );
      resolve([
        200,
        {
          message: responseData.length > 0 ? "SUCCESS" : "No Record Found",
          responseData: responseData.length > 0 ? responseData : null,
        },
      ]);
    }, 1000);
  });
});
