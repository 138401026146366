import React, { useRef, useCallback } from "react";
import Typography from "@material-ui/core/Typography";
import PhotoCameraIcon from "@material-ui/icons/PhotoCamera";
import CardContent from "@material-ui/core/CardContent";
import Card from "@material-ui/core/Card";
import IconButton from "@material-ui/core/IconButton";
import styles from "assets/jss/material-dashboard-react/components/customCardStyle";

function DocumentUpload(props) {
  const inputRef = useRef();
  const classes = styles();
  const {
    actionUpload,
    description,
    selected,
    inputText,
    imageHandler,
    onChange,
    input,
    inputref,
    error,
    ...rest
  } = props;

  const fileUpload = useCallback(() => {
    inputRef.current.click();
  }, []);
  return (
    <div className={classes.root} {...rest}>
      {selected !== null ? (
        <>
          <img
            src={selected}
            alt="...."
            className={classes.img}
            onClick={fileUpload}
          />

          <input
            accept="image/jpeg"
            id="icon-button-file"
            type="file"
            onChange={imageHandler}
            ref={inputRef}
          />
        </>
      ) : (
        <>
          <Card className={classes.card}>
            <CardContent>
              <div>
                <input
                  accept="image/jpeg"
                  style={{ display: "none" }}
                  id="icon-button-file"
                  type="file"
                  onChange={imageHandler}
                  ref={inputRef}
                />
                <IconButton
                  aria-label="upload picture"
                  className={classes.icon}
                  onClick={fileUpload}
                >
                  <PhotoCameraIcon />
                </IconButton>
              </div>
              <Typography className={classes.text}>{"Upload Photo"}</Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </CardContent>
          </Card>
        </>
      )}
    </div>
  );
}
export default DocumentUpload;
