import React, { useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import BrowseImages from "Components/Dialogs/orders/BrowseImages";
import { withStyles } from "@material-ui/core/styles";
import DeleteIcon from "@material-ui/icons/Delete";
export const Tablecell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#020202",
    font: "normal normal normal 16px/18px Helvetica Neue",
  },
  body: {
    font: "normal normal normal 16px/18px Helvetica Neue",
    color: "#020202",
  },
}))(TableCell);

export const Tablerow = withStyles((theme) => ({
  // root: {
  //   backgroundColor: "gr",
  //   borderBottom: "none",
  //   textAlign: "center",
  // },
}))(TableRow);

function OrdersList(props) {
  const { selectedCategories, onDelete } = props;
  const [dialog, setDialog] = useState({
    dialog: false,
    images: null,
  });

  const handleOpenDialog = (row) => {
    setDialog({
      dialog: true,
      images: row,
    });
  };

  const handleCloseDialog = () => {
    setDialog({
      dialog: false,
      images: null,
    });
  };

  return (
    <>
      {selectedCategories.length > 0 ? (
        <TableContainer component={Paper}>
          <Table aria-label="simple table">
            <TableHead>
              <Tablerow>
                <Tablecell>Category</Tablecell>
                <Tablecell align="left">Sub Category</Tablecell>
                <Tablecell align="left">Problem Type</Tablecell>
                <Tablecell align="left">Description</Tablecell>
                <Tablecell align="left">Browse Images</Tablecell>
                <Tablecell align="left">Actions</Tablecell>
              </Tablerow>
            </TableHead>
            <TableBody>
              {selectedCategories.map((row, index) => (
                <Tablerow key={index}>
                  <Tablecell component="th" scope="row">
                    {row.category.slice(0, -1)}
                  </Tablecell>
                  <Tablecell align="left">
                    {row.subcategory.slice(0, -1)}
                  </Tablecell>
                  <Tablecell align="left">
                    {row.problemType.slice(0, -2)}
                  </Tablecell>
                  <Tablecell align="left">{row.description}</Tablecell>
                  <Tablecell align="left">
                    <IconButton onClick={() => handleOpenDialog(row.images)}>
                      <EditIcon />
                    </IconButton>
                  </Tablecell>
                  <Tablecell align="left">
                    <IconButton onClick={() => onDelete(row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </Tablecell>
                </Tablerow>
              ))}
            </TableBody>
          </Table>
          {dialog.dialog ? (
            <BrowseImages
              open={dialog.dialog}
              onClose={handleCloseDialog}
              images={dialog.images}
            />
          ) : null}
        </TableContainer>
      ) : null}
    </>
  );
}

export default OrdersList;
