import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { users } = Data;

mock.onPost("/users").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const responseData = users;
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
