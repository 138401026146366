export const signupUrl = "/openapi/signup";
export const getUserUrl = "/api/user-management/users/getUser";
export const addUserUrl = "/api/user-management/users/addUserDetails";
export const getCities = "/api/admin/city/getcities";
export const uploadImage = "/api/user-management/users/imageupload";
export const categoryList = "/api/user-management/category/userCategoryList";
export const updateUserCategories =
  "/api/user-management/category/updateUserCategories";
export const getUserDocuments =
  "/api/user-management/users/getUsersDocumentsByDocumentStatus";
export const updateUserDocuments =
  "/api/user-management/users/updateUserDocumentStatus";
export const uploadDocuments = "/api/user-management/boUsers/uploadDocuments";
export const savePanCardDetails =
  "/api/user-management/boUsers/savePanCardDetails";
export const saveAadhaarCardDetails =
  "/api/user-management/boUsers/saveAadharCardDetails";
export const bankDetailsProof =
  "/api/user-management/boUsers/uploadVendorBankDetailsProof";
export const updateBankDetails =
  "/api/user-management/boUsers/addVendorBankDetails";
export const cityBasedCategories = "/api/admin/category/getCityCategories";
export const getUsersDocumentsByUserName =
  "/api/user-management/boUsers/getVendorDocumentsDetails";
export const getCategoryBasedSkills = "/api/admin/category/getCategorySkills";
export const mapSkillsToVendor = "/api/user-management/boUsers/addSkillsToUser";
export const updateServiceAreaDetails =
  "/api/user-management/users/updateUserServiceAreaDetails";
export const updatePersonalDetails =
  "/api/user-management/users/updateUserPersonDetails";
export const getLookupValues = "/api/admin/utils/getLookupValues";
export const getSubCategories =
  "/api/admin/category/getCategoryBasedSubCategories";
export const getProblemTypes =
  "/api/admin/category/getSubCategoryBasedProblemTypes";
export const getCityServiceArea = "/api/admin/city/getCityServiceAreas";
export const saveCategory = "/api/admin/category/savecategory";
export const removeUserSkills =
  "/api/user-management/boUsers/unmapSkillsFromUser";
export const removeUserCategories =
  "/api/user-management/boUsers/unmapCategoriesFromUser";
export const getCategories = "/api/admin/category/getcategories";
export const fetOrdersByStatus = "/api/order/order/getOrderHeaderByStatus";
export const getCustomerList = "/api/user-management/users/getCustomerList";
export const getB2BCustomer = "api/user-management/users/getB2BCustomerList";
export const getCustomer = "/api/user-management/users/getCustomer";
export const createB2BCustomer = "api/user-management/users/createB2BCustomer";
export const createB2CCustomer = "/api/user-management/users/createB2CCustomer";
export const fetchB2BCustomer = "/api/user-management/users/getB2BCustomer/";
export const fetchB2CCustomer = "/api/user-management/users/getCustomer/";
export const getCategory = "/api/admin/category/getcategory";
export const createSubCategory = "/api/admin/category/saveSubcategory";
export const addProblemType = "/category/saveProblemType";
export const getSkills = "/api/admin/category/getSkills";
export const addSkill = "/api/admin/category/saveSkills";
export const websiteLeads = "/api/websiteLeadsList";
