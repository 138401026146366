import React, { useEffect, useState } from "react";
import PersonalDetails from "./PersonalDetails";
import PersonalDocuments from "./PanCard";
import AadhaarCard from "./AadhaarCard";
import Categories from "./Categories";
import ServiceLocation from "./ServiceLocation";
import BankDetails from "./BankDetails";
import Skills from "./Skills";
import Button from "Components/CustomButtons/Button";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/sectionStyle";
import { callAPI } from "Utils/HttpUtil";
import { getUsersDocumentsByUserName } from "Utils/ApiEndpoints";
import { removeuserdetails } from "Utils/Session";

function SectionApprovals(props) {
  const [update, setUpdate] = useState();
  const [userDetails, setUserDetails] = useState();
  const classes = useStyles();

  let username = localStorage.getItem("username");

  const handleBackBtn = () => {
    setUserDetails(null);
    removeuserdetails();
    props.history.push("/admin/approvals/onboarding_approvals");
  };

  const apiSuccessCallback = async (res) => {
    if (res.data.message === "SUCCESS") {
      setUserDetails(await res.data.responseData);
    } else {
      setUserDetails(null);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
    setUserDetails(null);
  };

  useEffect(() => {
    callAPI(
      "POST",
      getUsersDocumentsByUserName,
      {
        userName: username,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  }, [update, username]);

  if (userDetails === undefined || userDetails === null) {
    return null;
  }

  return (
    <div className={classes.margin}>
      <div className={classes.appBar}>
        <Button color="secondary" onClick={handleBackBtn}>
          back
        </Button>
      </div>
      <PersonalDetails
        personalDetails={userDetails.userDetails}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
      />
      <PersonalDocuments
        personalDocuments={userDetails.personalDocuments}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
      />
      <AadhaarCard
        personalDocuments={userDetails.personalDocuments}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
      />
      <Categories
        categories={userDetails.userDetails.userCategoryDTOList}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
        cityId={userDetails.userDetails.cityDTO?.cityId}
      />
      <Skills
        userSkillsDTO={userDetails.userSkillsDTO}
        setUpdate={setUpdate}
        userCategories={userDetails.userDetails.userCategoryDTOList}
        userId={userDetails.userDetails.userId}
      />
      <ServiceLocation
        servicearea={userDetails.serviceArea}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
      />
      <BankDetails
        bankdetails={userDetails.bankDetails}
        userId={userDetails.userDetails.userId}
        setUpdate={setUpdate}
      />
    </div>
  );
}

export default SectionApprovals;
