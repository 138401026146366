import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";

// core components
//import AuthNavbar from "Components/Navbars/AuthNavbar.js";
import "../assets/css/style.css";

import routes from "routes.js";

import Card from "Components/Card/Card.js";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import CardBody from "Components/Card/CardBody.js";
import logoSkyld from "../assets/img/Capa_1.png";

import useStyles from "../assets/jss/material-dashboard-react/components/loginpageStyle";

function Auth() {
  const classes = useStyles();
  const [cardAnimaton, setCardAnimation] = React.useState("cardHidden");
  setTimeout(function () {
    setCardAnimation("");
  }, 700);
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <>
      <div className="main-content" style={{ background: "#F5F5FA" }} id="main">
        {/* <AuthNavbar /> */}
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6} lg={4}>
                <div className={classes[cardAnimaton]}>
                  <Card>
                    <div className={classes.logo}>
                      <img src={logoSkyld} alt="..."></img>
                    </div>
                    <CardBody>
                      <Switch>
                        {getRoutes(routes)}
                        <Redirect from="*" to="/auth/login" />
                      </Switch>
                    </CardBody>
                  </Card>
                </div>
              </GridItem>
            </GridContainer>
          </div>
        </div>
      </div>
    </>
  );
}

export default Auth;
