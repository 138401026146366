import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/sectionStyle";
import Button from "Components/CustomButtons/CustomButton";
import AadhaarCardUpdte from "Components/Dialogs/approvals/AadhaarCardUpdate";
import { callAPI } from "Utils/HttpUtil";
import { updateUserDocuments } from "Utils/ApiEndpoints";
import Tablerow from "Components/Table/Tablerow";

function AadhaarCard(props) {
  const [dialog, setDialog] = useState(false);
  const { personalDocuments, userId, setUpdate } = props;

  const classes = useStyles(props);

  const handleClose = () => {
    setDialog(false);
  };

  const handleOnEdit = () => {
    setDialog(true);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.responseData === "Status Updated") {
      setUpdate(new Date());
      alert(responseData.data.responseData);
    } else {
      alert(responseData.data.responseData);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const updateStatus = (e) => {
    callAPI(
      "POST",
      updateUserDocuments,
      {
        userId: userId,
        status: e.currentTarget.value,
        model: "personalDocuments",
        docType: "aadhar",
        serviceAreaId: null,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  if (!personalDocuments) {
    return null;
  }

  return (
    <div>
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardSubHeader}
          action={
            <IconButton aria-label="settings" onClick={handleOnEdit}>
              <EditIcon />
            </IconButton>
          }
          title={"Aadhaar Card"}
        />
        {dialog ? (
          <AadhaarCardUpdte
            open={dialog}
            handleClose={handleClose}
            aadharCardNumber={personalDocuments.aadharCardNumber}
            setUpdate={setUpdate}
          />
        ) : null}
        <div className={classes.root}>
          <GridContainer>
            <>
              <GridItem xs={12} md={6}>
                <Tablerow
                  name="Aadhar Card Number"
                  value={personalDocuments.aadharCardNumber}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Tablerow
                  name="Status"
                  value={personalDocuments.statusAadhar}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Tablerow
                  name="Front Image"
                  value={
                    personalDocuments.aadharCardFrontImage === null ? (
                      "null"
                    ) : (
                      <img
                        className={classes.img}
                        alt="...."
                        src={`data:image/jpeg;base64,${personalDocuments.aadharCardFrontImage}`}
                      />
                    )
                  }
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Tablerow
                  name="Back Image"
                  value={
                    personalDocuments.aadharCardBackImage === null ? (
                      "null"
                    ) : (
                      <img
                        className={classes.img}
                        alt="...."
                        src={`data:image/jpeg;base64,${personalDocuments.aadharCardBackImage}`}
                      />
                    )
                  }
                />
              </GridItem>
            </>
          </GridContainer>
          {personalDocuments.statusAadhar === "Verification" ||
          personalDocuments.statusAadhar === "Pending" ? (
            <div className={classes.btnContainer}>
              <Button
                backgroundcolor="#11F79F48"
                border="#0ED68A"
                value="Approved"
                onClick={updateStatus}
              >
                Approve
              </Button>
              <Button
                backgroundcolor="#F7244D58"
                border="#FC3D62D8"
                value="Rejected"
                onClick={updateStatus}
              >
                Reject
              </Button>
              <Button
                backgroundcolor="#F8931D58"
                border="#F08A14"
                value="Reassign"
                onClick={updateStatus}
                disabled
              >
                Re-assign
              </Button>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

export default AadhaarCard;
