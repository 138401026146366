import { React, useState, useEffect } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/categories/tableStyle";
import { useHistory } from "react-router-dom";
import { callAPI } from "Utils/HttpUtil";
import { getB2BCustomer } from "Utils/ApiEndpoints";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Button from "Components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";

function CustomersB2B() {
  const classes = useStyles();
  const history = useHistory();
  const [page, setPage] = useState(0);

  const [data, setData] = useState({
    customerB2BDetails: [],
    loading: true,
  });
  const [searchInput, setSearchInput] = useState("");

  const headCells = [
    { label: "User ID" },
    { label: "Company Name" },
    { label: "POC Name" },
    { label: "SPOC Email" },
    { label: "POC Number" },
    { label: "City" },
    { label: "Edit" },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const apiSuccessCallback = (responseData) => {
    if (responseData.status === 200) {
      setData({
        customerB2BDetails: responseData.data.responseData,
        loading: false,
      });
    } else {
      setData({
        customerB2BDetails: null,
        loading: false,
      });
    }
  };
  const apiErrorCallback = (err) => {
    console.log(err);
  };
  useEffect(() => {
    callAPI("POST", getB2BCustomer, {}, apiSuccessCallback, apiErrorCallback);
  }, []);

  const handleEdit = (user) => {
    if (user) {
      history.push(`/admin/customers/B2B/edit/${user}`);
    }
  };

  return (
    <div className={classes.root}>
      <div align="right">
        <Button
          disableElevation
          disableTouchRipple
          disableRipple
          startIcon={<AddIcon />}
          color="primary"
          className={classes.appBarBtn}
          onClick={() => history.push("/admin/customers/B2B/new_user")}
        >
          Create Customer
        </Button>
      </div>
      <div className={classes.grow}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Customers"
                onChange={(e) => setSearchInput(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.customerB2BDetails
                ? data.customerB2BDetails
                    .filter((item) => {
                      if (searchInput === "") {
                        return item;
                      } else if (
                        item.companyName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.pocName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.spocEmail
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.pocNumber
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    })
                    .slice(page * 10, page * 10 + 10)
                    .map((row, index) => {
                      return (
                        <Tablerow key={index}>
                          <Tablecell align="left">{row.userId}</Tablecell>
                          <Tablecell align="left">{row.companyName}</Tablecell>
                          <Tablecell align="left">{row.pocName}</Tablecell>
                          <Tablecell align="left">{row.spocEmail}</Tablecell>
                          <Tablecell align="left">{row.pocNumber}</Tablecell>
                          <Tablecell align="left">{row.cityName}</Tablecell>
                          <Tablecell className={classes.cellLink} align="left">
                            <EditIcon onClick={() => handleEdit(row.userId)} />
                          </Tablecell>
                        </Tablerow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
          {data.customerB2BDetails ? (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data.customerB2BDetails.length}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : null}
        </TableContainer>
        {!data.customerB2BDetails.length > 0 ? (
          <div className="noResultText">0 records found</div>
        ) : null}
      </Paper>
    </div>
  );
}
export default CustomersB2B;
