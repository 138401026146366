import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { orders } = Data;

mock.onPost("/orders").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const status = data.status;
      const responseData = orders.filter((item) => item.status === status);
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/orders/create").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      orders.push({
        orderId: orders.length + 1,
        status: "Draft",
        orderDate: "2021-04-14",
        orderOwner: data.customerName,
        customerName: data.customerName,
        customerNumber: data.customerNumber,
        problemType: data.problemType,
        city: data.city,
        visitDate: data.visitDate,
      });
      resolve([200, { message: "SUCCESS", responseData: orders }]);
    }, 1000);
  });
});
