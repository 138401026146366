import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  img: {
    height: "150px",
    width: "150px",
    border: "1px solid #000000",
    marginRight: "10px",
  },

  close: {
    position: "absolute",
    top: "2px",
    right: "2px",
    zIndex: "100",
  },
};

const useStyles = makeStyles(styles);

export default function MaxWidthDialog(props) {
  const classes = useStyles();

  const { onClose, open, images } = props;

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogTitle>
        <div align="right">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        {images.map((item, index) => {
          return (
            <img className={classes.img} src={item} key={index} alt="...." />
          );
        })}
      </DialogContent>
    </Dialog>
  );
}
