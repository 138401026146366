import React, { useState, useEffect } from "react";
import Button from "Components/CustomButtons/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import TablePagination from "@material-ui/core/TablePagination";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import {
  Tablecell,
  Tablerow,
  styles,
} from "assets/jss/material-dashboard-react/components/dialogStyle";
import SearchIcon from "@material-ui/icons/Search";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { callMockAPI } from "Utils/HttpUtil";

const headCells = ["", "User Name", "Email", "Number", "Type"];

export default function MaxWidthDialog(props) {
  const { onClose, open, setUserDetails } = props;
  const classes = styles();
  const [page, setPage] = React.useState(0);
  const [data, setData] = useState({
    users: [],
    loading: true,
  });

  const [searchInput, setSearchInput] = useState("");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChange = (e, row) => {
    setUserDetails({
      email: row.email,
      phoneNumber: row.phoneNumber,
      customerName: `${row.customerFirstName} ${row.customerLastName}`,
      customerType: row.customerType,
      cityId: row.cityId,
      pin: row.pin,
      address: row.address,
      serviceArea: row.area,
      dialog: false,
    });
  };

  const apiSuccessCallback = (res) => {
    if (res.data.message === "SUCCESS") {
      setData({
        users: res.data.responseData,
        loading: false,
      });
    }
  };
  const apiErrorCallback = (err) => {
    console.log(err);
  };

  useEffect(() => {
    callMockAPI("POST", "/users", {}, apiSuccessCallback, apiErrorCallback);
  }, []);

  return (
    <Dialog
      fullWidth={true}
      maxWidth={"md"}
      open={open}
      disableEscapeKeyDown
      disableBackdropClick
    >
      <DialogTitle>
        <div align="right">
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Customer"
                onChange={(e) => setSearchInput(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>

        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.users
                .filter((item) => {
                  if (searchInput === "") {
                    return item;
                  } else if (
                    item.customerFirstName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ) {
                    return item;
                  } else if (
                    item.customerLastName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ) {
                    return item;
                  } else if (
                    item.email.toLowerCase().includes(searchInput.toLowerCase())
                  ) {
                    return item;
                  } else if (
                    item.phoneNumber
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ) {
                    return item;
                  } else {
                    return null;
                  }
                })
                .slice(page * 5, page * 5 + 5)
                .map((row, index) => {
                  return (
                    <Tablerow key={index}>
                      <Tablecell padding="checkbox" align="left">
                        <RadioGroup onChange={(e) => handleChange(e, row)}>
                          <FormControlLabel
                            value={row.userId}
                            control={<Radio color="primary" />}
                          />
                        </RadioGroup>
                      </Tablecell>
                      <Tablecell padding="default" align="left">
                        {`${row.customerFirstName} ${row.customerLastName}`}
                      </Tablecell>
                      <Tablecell padding="default" align="left">
                        {row.email}
                      </Tablecell>
                      <Tablecell padding="default" align="left">
                        {row.phoneNumber}
                      </Tablecell>
                      <Tablecell padding="default" align="left">
                        {row.customerType}
                      </Tablecell>
                    </Tablerow>
                  );
                })}
            </TableBody>
          </Table>
          <TablePagination
            rowsPerPageOptions={[10]}
            component="div"
            count={data.users.length}
            rowsPerPage={5}
            page={page}
            onChangePage={handleChangePage}
          />
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button color="primary" startIcon={<AddIcon />}>
          Create New Customer
        </Button>
      </DialogActions>
    </Dialog>
  );
}
