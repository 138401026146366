import React, { useState, useEffect } from "react";
import Button from "Components/CustomButtons/Button";
import Header from "Components/Header/TitleHeader";
import Card from "Components/Card/Card";
import Tablerow from "Components/Table/Tablerow";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
// import EditCategory from "Components/Dialogs/categories/editCategoryDetails";
// import { callAPI } from "Utils/HttpUtil";
// import { getCategory } from "Utils/ApiEndpoints";

function SubCategory(props) {
  const [data, setData] = useState({
    categories: null,
    loading: false,
  });
  //   const [dialog, setDialog] = useState(false);
  //   const [update, setUpdate] = useState();

  //   const handleDialog = () => {
  //     setDialog(!dialog);
  //   };

  //   const apiSuccessGetCategory = async (res) => {
  //     console.log(res);
  //     if (res.data.message === "SUCCESS") {
  //       setData({
  //         categories: await res.data.responseData,
  //         loading: false,
  //       });
  //     }
  //   };

  //   const apiErrorGetCategory = (err) => {
  //     console.log(err);
  //   };

  //   useEffect(() => {
  //     callAPI(
  //       "GET",
  //       getCategory,
  //       { categoryIds: [categoryId] },
  //       apiSuccessGetCategory,
  //       apiErrorGetCategory
  //     );
  //   }, [update, categoryId]);

  if (data.loading === true) {
    return null;
  }

  return (
    <>
      <Header
        title="Sub Category"
        rightLinks={
          <>
            <Button color="primary">EDIT</Button>
          </>
        }
      />
      <Card>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Sub Category"
              value={"Fresh Painting"}
              capitalize="capitalize"
            />
          </GridItem>

          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category"
              value={"Painting"}
              capitalize="capitalize"
            />
          </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default SubCategory;
