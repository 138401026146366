import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { serviceArea } = Data;

mock.onPost("/getCityBasedServiceArea").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const cityId = data.cityId;
      const responseData = serviceArea.filter((item) => item.cityId === cityId);
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/updateServiceAreaDetails").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const servicearea = serviceArea.filter((item) => item.id === data.id);
      const responseData = servicearea.map(
        (item) =>
          (item = {
            ...item,
            cityId: data.cityId,
            longitude: data.longitude,
            latitude: data.latitude,
          })
      );

      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
