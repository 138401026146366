import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import useStyles from "../../assets/jss/material-dashboard-react/components/loginpageStyle";
import axios from "axios";
import { setCookie } from "../../Utils/Session";
import { trackPromise } from "react-promise-tracker";
import AlertMessage from "../../Components/Snackbar/SnackbarError";
import { useForm } from "react-hook-form";

function Signin(props) {
  const classes = useStyles();
  const [values, setValues] = useState({
    mobile: null,
    password: null,
  });

  const { handleSubmit } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      mobile: null,
      password: null,
    },
  });

  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  function SubmitButton() {
    if (values.username && values.password) {
      return (
        <Button
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
        >
          Login
        </Button>
      );
    } else {
      return (
        <Button
          disabled
          fullWidth
          variant="contained"
          color="primary"
          type="submit"
          className={classes.submit}
        >
          Login
        </Button>
      );
    }
  }

  const handlelogin = (e) => {
    const CLIENT_ID = process.env.REACT_APP_CLIENT_ID;
    const CLIENT_SECRET = process.env.REACT_APP_CLIENT_SECRET;
    let details = {
      username: values.username,
      password: values.password,
      grant_type: "password",
      client_id: CLIENT_ID,
      client_secret: CLIENT_SECRET,
    };

    let formBody = [];
    for (let property in details) {
      let encodedKey = encodeURIComponent(property);
      let encodedValue = encodeURIComponent(details[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const token = Buffer.from(`${CLIENT_ID}:${CLIENT_SECRET}`, "utf8").toString(
      "base64"
    );

    trackPromise(
      axios({
        method: "post",
        url: "oauth/token",
        headers: {
          Authorization: `Basic ${token}`,
          "Content-Type": "application/x-www-form-urlencoded",
        },
        data: formBody,
      })
        .then((responseData) => {
          if (responseData.data.access_token) {
            setCookie(
              responseData.data.access_token,
              responseData.data.refresh_token
            );
            props.history.push("/dashboard");
          }
        })
        .catch((err) => {
          seterror({
            error: true,
            message: "User not found or account deactivated",
            key: Math.random(),
          });
        })
    );
  };

  return (
    <form
      className={classes.form}
      autoComplete="off"
      onSubmit={handleSubmit(handlelogin)}
    >
      {error.error ? (
        <AlertMessage key={error.key} message={error.message} color="error" />
      ) : null}
      <input type="hidden" value="something" />
      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        id="mobile"
        label="E-Mail/Phone Number"
        name="username"
      />

      <TextField
        variant="outlined"
        margin="normal"
        required
        fullWidth
        onChange={onChange}
        name="password"
        label="Password"
        type="password"
        id="password"
      />

      <SubmitButton />
    </form>
  );
}

export default Signin;
