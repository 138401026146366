import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import DateFnsUtils from "@date-io/date-fns";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { callAPI } from "Utils/HttpUtil";
import { getCities, updatePersonalDetails } from "Utils/ApiEndpoints";
import TextField from "Components/TextField/TextField";
import { useForm, Controller } from "react-hook-form";

function PersonalDetailsUpdate(props) {
  const classes = styles();
  const { open, handleClose, personalDetails, setUpdate } = props;
  const [data, setData] = useState({
    cities: [],
    loading: true,
  });

  const defaultValues = {
    firstName: personalDetails.firstName,
    lastName: personalDetails.lastName,
    phone: personalDetails.cellPhone,
    email: personalDetails.email,
    address: personalDetails.postalAddress,
    pincode: personalDetails.postalCode,
    userType: "Technician",
    gender: personalDetails.gender,
    city: personalDetails.cityDTO?.cityId,
    dob: personalDetails.dob,
  };

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues,
  });

  const apiSuccessCallbackgetCities = (res) => {
    if (res.data.message === "SUCCESS") {
      setData({
        cities: res.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallbackgetCities = (err) => {
    console.log(err);
  };

  useEffect(() => {
    callAPI(
      "GET",
      getCities,
      {},
      apiSuccessCallbackgetCities,
      apiErrorCallbackgetCities
    );
  }, []);

  const apiSuccessCallback = (res) => {
    if (res.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated successfully!!");
      handleClose();
    } else {
      alert(res.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const onSave = async (formDetails) => {
    console.log(formDetails.dob);
    const parseDOB = {
      date: ("0" + new Date(formDetails.dob).getDate()).slice(-2),
      month: ("0" + (new Date(formDetails.dob).getMonth() + 1)).slice(-2),
      year: new Date(formDetails.dob).getFullYear(),
    };

    const formData = new FormData();
    formData.append("userId", personalDetails.userId);
    formData.append("firstName", formDetails.firstName);
    formData.append("lastName", formDetails.lastName);
    formData.append("cellPhone", formDetails.phone);
    formData.append("email", formDetails.email);
    formData.append("gender", formDetails.gender);
    formData.append("postalAddress", formDetails.address);
    formData.append(
      "dob",
      `${parseDOB.year}-${parseDOB.month}-${
        parseDOB.date
      }${"T00:00:00.45+01:00"}`
    );
    formData.append("cityDTO.cityId", formDetails.city);
    formData.append("postalCode", formDetails.pincode);
    //formData.append("userType", "Technician");
    formData.append("addressId", "1");

    callAPI(
      "POST",
      updatePersonalDetails,
      formData,
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  if (data.loading === true) {
    return null;
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update Personal Details
        </Typography>
      </DialogTitle>

      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => onSave(data))}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Controller
                name={"userType"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      label="User Type"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      className={classes.padding}
                      inputRef={ref}
                      defaultValue="Technician"
                      select
                    >
                      <MenuItem value="Technician">Technician</MenuItem>
                      <MenuItem value="Customer">Customer</MenuItem>
                    </TextField>
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="firstName"
                label="First Name"
                className={classes.padding}
                inputRef={register({
                  required: "First Name is required.",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "Invalid first name",
                  },
                })}
                error={Boolean(errors.firstName)}
                helperText={errors.firstName?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="lastName"
                label="Last Name"
                className={classes.padding}
                inputRef={register({
                  required: "Last Name is required.",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "Invalid last name",
                  },
                })}
                error={Boolean(errors.lastName)}
                helperText={errors.lastName?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="phone"
                label="Mobile Number"
                className={classes.padding}
                inputRef={register({
                  required: "Mobile Number is required.",
                  pattern: {
                    value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                    message: "Invalid mobile number",
                  },
                })}
                error={Boolean(errors.phone)}
                helperText={errors.phone?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="email"
                label="Email"
                className={classes.padding}
                inputRef={register({
                  required: "Email is required.",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "Invalid email",
                  },
                })}
                error={Boolean(errors.email)}
                helperText={errors.email?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Controller
                name={"gender"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      label="Gender"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      className={classes.padding}
                      inputRef={ref}
                      error={Boolean(errors.gender)}
                      helperText={errors.gender?.message}
                      defaultValue={personalDetails.gender}
                      select
                    >
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Other">Other</MenuItem>
                    </TextField>
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <Controller
                  name={"dob"}
                  control={control}
                  render={({ onChange, value, ref }) => {
                    return (
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Date Of Birth"
                        format="dd/MM/yyyy"
                        fullWidth
                        value={value}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={(event) => onChange(event)}
                        className={classes.padding}
                        inputRef={ref}
                        //defaultValue={personalDetails.dob}
                        InputProps={{
                          readOnly: true,
                        }}
                      />
                    );
                  }}
                />
              </MuiPickersUtilsProvider>
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="address"
                label="Address"
                className={classes.padding}
                inputRef={register({
                  required: "Address is required.",
                })}
                error={Boolean(errors.address)}
                helperText={errors.address?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="pincode"
                label="Area Pin Code"
                className={classes.padding}
                inputRef={register({
                  required: "Area Pin Code is required.",
                })}
                error={Boolean(errors.pincode)}
                helperText={errors.pincode?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Controller
                name={"city"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      label="City"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      className={classes.padding}
                      inputRef={ref}
                      error={Boolean(errors.city)}
                      helperText={errors.city?.message}
                      defaultValue={personalDetails.cityDTO?.cityId}
                      select
                    >
                      {data.cities.map((item, index) => {
                        return (
                          <MenuItem value={item.cityId} key={index}>
                            {item.cityName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <DialogActions className={classes.dialogAction}>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PersonalDetailsUpdate;
