import React, { useState, useEffect } from "react";
// core Components
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Button from "Components/CustomButtons/Button";
import Card from "Components/Card/Card.js";
import TextField from "@material-ui/core/TextField";
import CardBody from "Components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-dashboard-react/views/customerFormStyle";
import { MenuItem } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { callAPI } from "Utils/HttpUtil";
import {
  createB2CCustomer,
  getCities,
  getLookupValues,
} from "Utils/ApiEndpoints";
import { useHistory } from "react-router-dom";
import AlertMessage from "Components/Snackbar/SnackbarError";

export default function Customer() {
  const classes = styles();
  const history = useHistory();
  const [cities, setCities] = useState();
  const [source, setSource] = useState();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      customerFirstName: null,
      customerLastName: null,
      customerNumber: "",
      customerEmail: "",
      customerType: "B2C",
      source: "",
      address: "",
      area: "",
      zoneCode: "",
      city: "",
      pinCode: "",
    },
  });
  const onCancel = () => {
    history.push("/admin/customers/B2C");
  };

  const onApiSucessCallBackSaveCustomer = (res) => {
    if (res.data.status === 200) {
      seterror({
        error: true,
        message: "Customer created successfully!",
        key: Math.random(),
        color: "success",
      });
    }
  };

  const onApiErrorCallBackSaveCustomer = (err) => {
    seterror({
      error: true,
      message: err.response.data.message,
      key: Math.random(),
      color: "error",
    });
  };

  const onApiSucessCallGetCities = (res) => {
    if (res.data.status === 200) {
      setCities(res.data.responseData);
    }
  };

  const onApiErrorCallGetCities = (err) => {
    console.log(err);
  };

  const onApiSucessCallBackGetSource = (res) => {
    if (res.data.status === 200) {
      setSource(res.data.responseData);
    }
  };

  const onApiErrorCallBackGetSource = (err) => {
    console.log(err);
  };

  const onSubmit = (data) => {
    callAPI(
      "POST",
      createB2CCustomer,
      {
        userId: null,
        customerFirstName: data.customerFirstName,
        customerLastName: data.customerLastName,
        phoneNumber: data.customerNumber,
        email: data.customerEmail,
        type: data.customerType,
        sourceId: data.source,
        source: "",
        addressId: null,
        address: data.address,
        pin: data.pinCode,
        zoneCode: data.zoneCode,
        cityId: data.city,
        city: data.city,
        area: data.area,
      },
      onApiSucessCallBackSaveCustomer,
      onApiErrorCallBackSaveCustomer
    );
  };
  useEffect(() => {
    callAPI(
      "GET",
      getCities,
      {},
      onApiSucessCallGetCities,
      onApiErrorCallGetCities
    );
  }, []);
  useEffect(() => {
    callAPI(
      "POST",
      getLookupValues,
      { lookupType: "Source" },
      onApiSucessCallBackGetSource,
      onApiErrorCallBackGetSource
    );
  }, []);
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Card>
        <CardBody>
          {error.error ? (
            <AlertMessage
              key={error.key}
              message={error.message}
              color={error.color}
            />
          ) : null}
          <Typography variant="h6" align="center">
            <div align="left">
              <Button
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <b> Create Customer</b>
          </Typography>

          <Typography align="left">
            <b>Customer Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customerFirstName"
                label="Customer First Name"
                name="customerFirstName"
                error={!!errors.customerFirstName}
                helperText={errors.customerFirstName?.message}
                inputRef={register({
                  required: "You must provide the First name!",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "You must provide a valid First name!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customerLastName"
                label="Customer Last Name "
                name="customerLastName"
                error={!!errors.customerLastName}
                helperText={errors.customerLastName?.message}
                inputRef={register({
                  required: "You must provide the Last name!",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "You must provide a valid Last name!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customerEmail"
                label="Customer Email"
                name="customerEmail"
                error={!!errors.customerEmail}
                helperText={errors.customerEmail?.message}
                inputRef={register({
                  required: "You must provide the E-mail address!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "You must provide a valid E-mail address!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="customerNumber"
                label="Customer Number"
                name="customerNumber"
                className={errors.customerNumber ? null : classes.padding}
                error={!!errors.customerNumber}
                helperText={errors.customerNumber?.message}
                inputRef={register({
                  required: "You must provide the Phone Number!",
                  pattern: {
                    value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                    message: "You must provide a valid Phone Number!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"source"}
                control={control}
                rules={{ required: "Source is required" }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="source"
                      label="Source"
                      name="source"
                      error={!!errors.source}
                      helperText={errors.source?.message}
                      defaultValue="select"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select source
                      </MenuItem>
                      {source
                        ? source.map((value, index) => {
                            return (
                              <MenuItem value={value.lkp_id} key={index}>
                                {value.value}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Customer Address</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="address"
                label="Address"
                name="address"
                error={!!errors.address}
                helperText={errors.address?.message}
                inputRef={register({
                  required: "You must provide the address!",
                  pattern: {
                    message: "You must provide a valid address!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="area"
                label="Area"
                name="area"
                error={!!errors.area}
                helperText={errors.area?.message}
                inputRef={register({
                  required: "You must provide the area!",
                  pattern: {
                    message: "You must provide a valid area!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="zoneCode"
                label="Zone Code"
                name="zoneCode"
                error={!!errors.zoneCode}
                helperText={errors.zoneCode?.message}
                inputRef={register({
                  required: "You must provide the Zone Code!",
                  pattern: {
                    message: "You must provide a valid Zone Code",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"city"}
                control={control}
                rules={{ required: "City is required" }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      defaultValue="select"
                      error={!!errors.city}
                      helperText={errors.city?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="pinCode"
                label="Pin Code"
                name="pinCode"
                error={!!errors.pinCode}
                helperText={errors.pinCode?.message}
                inputRef={register({
                  required: "You must provide the pin code!",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "You must provide a valid Pin Code!",
                  },
                })}
              />
            </GridItem>
          </GridContainer>
          <div className={classes.Button}>
            <Button type="submit" color="primary" className={classes.submit}>
              Create
            </Button>
          </div>
        </CardBody>
      </Card>
    </form>
  );
}
