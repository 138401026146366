import React from "react";
import { makeStyles } from "@material-ui/core/styles";

const styles = (theme) => ({
  root: {
    display: "flex",
    paddingLeft: "20px",
    padding: theme.spacing(3),
  },
  tableCellHead: {
    font: "normal normal bold  16px/19px Helvetica Neue",
    color: "#070606",
    flex: 1,
  },

  tableCellValue: {
    font: "normal normal  16px/18px Helvetica Neue",
    color: "#070606",
    flex: 0.6,
    textTransform: (props) =>
      props.capitalize === "uppercase"
        ? "uppercase"
        : props.capitalize === "capitalize"
        ? "capitalize"
        : null,
  },
});

const useStyles = makeStyles(styles);

const Tablerow = (props) => {
  const classes = useStyles(props);
  const { name, value } = props;
  return (
    <div className={classes.root}>
      <span className={classes.tableCellHead}>{name}</span>
      <span className={classes.tableCellValue}>{value}</span>
    </div>
  );
};

export default Tablerow;
