import React from "react";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from "@material-ui/core/styles";

const styles = {
  container: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#FFFFFF",
    flexWrap: "wrap",
    marginBottom: 20,
    marginTop: 20,
    borderRadius: "4px",
  },
  title: {
    flex: 1,
    marginLeft: "10px",
    font: "normal normal bold 24px/29px Helvetica Neue",
    color: "#070606",
    opacity: 1,
  },

  margin: {
    marginRight: "10px",
  },
};

const useStyles = makeStyles(styles);

function TitleHeader(props) {
  const classes = useStyles();
  const { title, rightLinks } = props;
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>
        <b>{title}</b>
      </Typography>
      <div className={classes.margin}>{rightLinks}</div>
    </div>
  );
}

export default TitleHeader;
