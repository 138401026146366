import mock from "../../../utils/mock";
import Data from "MockServer/db.json";

let { retriveUserDocuments } = Data;

mock.onPost("/updateAadhaarCardDetails").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const username = data.username;
      const user = retriveUserDocuments.filter(
        (item) => item.userDetails.cellPhone === username
      );
      const responseData = user.map(
        (item) =>
          (item.personalDocuments = {
            ...item.personalDocuments,
            aadharCardNumber: data.aadharCardNumber,
            aadharCardFrontImage: data.aadharCardFrontImage,
            aadharCardBackImage: data.aadharCardBackImage,
          })
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
