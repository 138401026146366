import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useForm } from "react-hook-form";
import Image from "assets/img/imgupload.jpeg";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { callMockAPI } from "Utils/HttpUtil";
import TextField from "Components/TextField/TextField";
import classNames from "classnames";

function EditCategory(props) {
  const classes = styles();
  const { open, handleClose, setUpdate, category, categoryId } = props;

  //const categoryDetails = category.reduce((item) => ({ ...item }));

  const [citySelected, setCitySelected] = useState(category.cityIds);
  const [data, setData] = useState({
    cities: [],
    loading: true,
  });

  const [image, setImage] = useState({
    categoryImage: category.categoryImage,
  });
  const defaultValues = {
    categoryName: category.categoryName,
    categoryDescription: category.categoryDescription,
  };
  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const handleCityChange = (id) => {
    let newArray = [...citySelected, id];
    if (citySelected.includes(id)) {
      newArray = newArray.filter((cityId) => cityId !== id);
    }
    setCitySelected(newArray);
  };
  const uploadCategoryImage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setImage({
          ...image,
          categoryImage: reader.result.replace("data:image/png;base64,", ""),
          categoryImageUrl: file,
        });
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const apiSuccessUpdateCategory = (res) => {
    if (res.data.message === "SUCCESS") {
      alert("Updated Successfully!");
      handleClose();
      setUpdate(new Date());
    }
  };

  const apiErrorCallbackUpdateCategory = (err) => {
    console.log(err);
  };

  const onUpdateCategory = (formDetails) => {
    callMockAPI(
      "POST",
      "/updateCategory",

      {
        categoryId: categoryId,
        categoryName: formDetails.categoryName,
        categoryDescription: formDetails.categoryDescription,
        categoryImage: image.categoryImage,
        cityIds: citySelected,
      },
      apiSuccessUpdateCategory,
      apiErrorCallbackUpdateCategory
    );
  };

  const apiSuccessCallbackgetCities = (res) => {
    if (res.data.message === "SUCCESS") {
      setData({
        cities: res.data.responseData,
        loading: false,
      });
    } else {
      setData({
        cities: null,
        loading: false,
      });
    }
  };

  const apiErrorCallbackgetCities = (err) => {
    console.log(err);
    setData({
      cities: null,
      loading: false,
    });
  };

  useEffect(() => {
    callMockAPI(
      "POST",
      "/cities",
      {},
      apiSuccessCallbackgetCities,
      apiErrorCallbackgetCities
    );
  }, []);

  if (data.loading === true) {
    return null;
  }

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update Category Details
        </Typography>
      </DialogTitle>

      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => onUpdateCategory(data))}>
          <div className={classes.root}>
            <GridContainer spacing={3}>
              <GridItem xs={12} md={6}>
                <Typography>
                  <b>Category Image</b>
                </Typography>
                <img
                  alt="...."
                  src={
                    image.categoryImage
                      ? `data:image/png;base64,${image.categoryImage}`
                      : Image
                  }
                  className={classes.imgCategory}
                />

                <div className={classes.input}>
                  <input
                    accept="image/x-png,image/jpeg"
                    name="image"
                    multiple
                    type="file"
                    onChange={uploadCategoryImage}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <Typography className={classNames(classes.padding)}>
              <b>Category Information</b>
            </Typography>
            <GridContainer spacing={3}>
              <GridItem xs={12} sm={6}>
                <TextField
                  label="Category Name"
                  name="categoryName"
                  inputRef={register({
                    required: "Category Name is required.",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: "Invalid Category Name",
                    },
                  })}
                  error={Boolean(errors.categoryName)}
                  helperText={errors.categoryName?.message}
                  className={classes.padding}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  label="Category Description"
                  name="categoryDescription"
                  multiline
                  inputRef={register({
                    required: "Category Description is required.",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: "Invalid Category Description",
                    },
                  })}
                  error={Boolean(errors.categoryDescription)}
                  helperText={errors.categoryDescription?.message}
                />
              </GridItem>
            </GridContainer>
            <Typography>
              <b>Add Cities</b>
            </Typography>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <div>
                  <FormGroup row>
                    {data.cities.map((prop, index) => {
                      return (
                        <FormControlLabel
                          key={index}
                          name="city"
                          classes={{ label: classes.label }}
                          inputRef={register({
                            required: "City is required",
                          })}
                          control={
                            <Checkbox
                              name={prop.categoryName}
                              onChange={() => handleCityChange(prop.cityId)}
                              value={prop.cityId}
                              color="primary"
                              checked={citySelected.includes(prop.cityId)}
                            />
                          }
                          label={prop.cityName}
                        />
                      );
                    })}
                  </FormGroup>
                  {errors.city && (
                    <Typography align="left" className={classes.error}>
                      Please select the cities
                    </Typography>
                  )}
                </div>
              </GridItem>
            </GridContainer>
          </div>
          <DialogActions className={classes.dialogAction}>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default EditCategory;
