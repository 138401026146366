import React, { useState, useEffect } from "react";
import Button from "Components/CustomButtons/Button";
import Header from "Components/Header/TitleHeader";
import Card from "Components/Card/Card";
import Tablerow from "Components/Table/Tablerow";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import useStyles from "assets/jss/material-dashboard-react/views/categories/categorydetailsStyle";
import EditCategory from "Components/Dialogs/categories/editCategoryDetails";
import { callAPI } from "Utils/HttpUtil";
import { getCategory } from "Utils/ApiEndpoints";

function Category(props) {
  const { categoryId } = props;
  const classes = useStyles();
  const [data, setData] = useState({
    categories: null,
    loading: true,
  });
  const [dialog, setDialog] = useState(false);
  const [update, setUpdate] = useState();

  const handleDialog = () => {
    setDialog(!dialog);
  };

  const apiSuccessGetCategory = async (res) => {
    console.log(res);
    if (res.data.message === "SUCCESS") {
      setData({
        categories: await res.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorGetCategory = (err) => {
    console.log(err);
  };

  // const apiSuccessCallbackGetCities = async (res) => {
  //   if (res.data.message === "SUCCESS") {
  //     setCities(await res.data.responseData);
  //   }
  // };

  // const apiErrorCallbackGetCities = (err) => {
  //   console.log(err);
  // };

  useEffect(() => {
    callAPI(
      "GET",
      getCategory,
      { categoryIds: [categoryId] },
      apiSuccessGetCategory,
      apiErrorGetCategory
    );
  }, [update, categoryId]);

  if (data.loading === true) {
    return null;
  }

  return (
    <>
      <Header
        title="Categories Details"
        rightLinks={
          <>
            <Button color="primary" onClick={handleDialog}>
              EDIT
            </Button>
          </>
        }
      />
      <Card>
        {dialog ? (
          <EditCategory
            open={dialog}
            handleClose={handleDialog}
            category={data.categories}
            setUpdate={setUpdate}
            categoryId={categoryId}
          />
        ) : null}
        <GridContainer>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category Name"
              value={data.categories.categoryName}
              capitalize="capitalize"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category Type"
              value={data.categories.categoryType}
              capitalize="capitalize"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category Status"
              value={data.categories.categoryStatus}
              capitalize="capitalize"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category Description"
              value={data.categories.categoryDescription}
              capitalize="capitalize"
            />
          </GridItem>
          <GridItem xs={12} md={6}>
            <Tablerow
              name="Category Image"
              value={
                <img
                  className={classes.img}
                  alt="...."
                  src={`data:image/png;base64,${data.categories.categoryImage}`}
                />
              }
              capitalize="capitalize"
            />
          </GridItem>

          {/* <GridItem xs={12} md={6}>
            <Tablerow
              name="Active Cities"
              value={
                <div className={classes.cityContainer}>
                  {cities.map((item, index) => (
                    <Typography className={classes.spacing} key={index}>
                      {item.cityName}
                    </Typography>
                  ))}
                </div>
              }
              capitalize="capitalize"
            />
          </GridItem> */}
        </GridContainer>
      </Card>
    </>
  );
}

export default Category;
