import mock from "MockServer/utils/mock";
import Data from "MockServer/db.json";

let { propertyManagementUsers } = Data;

mock.onPost("/property_management_property/create").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const getDetails = propertyManagementUsers.filter((item) => {
        return item.phone.toString() === data.phone.toString();
      });
      if (getDetails.length > 0) {
        getDetails.map((item) =>
          item.properties.push({
            id: item.properties.length + 1,
            propertyname: data.propertyname,
          })
        );
      } else {
        propertyManagementUsers.push({
          phone: data.phone,
          properties: [{ id: 1, propertyname: data.propertyname }],
        });
      }
      resolve([
        200,
        {
          message: "SUCCESS",
          responseData: propertyManagementUsers,
        },
      ]);
    }, 1000);
  });
});
