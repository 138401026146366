import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    alignSelf: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
  large: {
    width: theme.spacing(7),
    height: theme.spacing(7),
  },
  Button: {
    display: "flex",
    justifyContent: "center",
    paddingTop: 10,
    paddingBottom: 10,
    marginTop: 30,
  },
  Back: {
    display: "flex",
    justifyContent: "left",
    borderRadius: 25,
    paddingTop: 9,
    paddingBottom: 9,
  },

  controls: {
    textAlign: "center",
  },
}));

export default useStyles;
