import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { cities } = Data;

mock.onPost("/cities").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const responseData = cities;
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/getCityById").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const cityIds = data.cityIds;
      let responseData = [];
      cityIds.forEach((id) => {
        cities.map((item) => {
          if (item.cityId === id) {
            return responseData.push(item);
          } else {
            return null;
          }
        });
      });

      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
