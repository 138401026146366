import mock from "../../utils/mock";

mock.onPost("/login").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const accessToken = "dummy_token";
      if (data.username === "Development" && data.password === "password") {
        resolve([200, { message: "SUCCESS", responseData: accessToken }]);
      } else {
        resolve([
          200,
          { message: "invalid username and password", responseData: null },
        ]);
      }
    }, 1000);
  });
});
