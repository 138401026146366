import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const Tablecell = withStyles((theme) => ({
  head: {
    backgroundColor: "#FFFFFF",
    color: "#434040",
    font: "normal normal normal 20px/24px Helvetica Neue",
  },
  body: {
    font: "normal normal normal 16px/18px Helvetica Neue",
    color: "#434040",
    padding: 10,
  },
}))(TableCell);

export const Tablerow = withStyles((theme) => ({
  root: {
    //backgroundColor: "FFFFFF",
    //borderBottom: "none",
    //textAlign: "center",
  },
}))(TableRow);

export const styles = makeStyles((theme) => ({
  root: {
    "& .MuiFilledInput-input": {
      backgroundColor: "lightblue",
      border: "1px solid red",
    },
  },

  backBtn: {
    backgroundColor: "#DAEBFA",
    font: "normal normal bold 16px/19px Helvetica Neue",
    color: "#070606",
    height: "38px",
    boxShadow: "none",
    textTransform: "none",
    transform: "none",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingTop: 0,
  },

  titleText: {
    font: "normal normal bold 34px/41px Helvetica Neue",
  },

  dialogAction: {
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    borderTop: "none",
    paddingTop: 80,
  },
  imgName: {
    font: "normal normal bold 20px/29px Helvetica Neue",
    color: "#070606",
    marginBottom: 5,
  },
  imgContainer: {
    width: "auto",
    height: "230px",
    border: "1px solid #C7C4C4",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
  imgIcon: {
    fontSize: 100,
    color: "#AAAAAA",
  },

  input: {
    display: "flex",
    paddingBottom: 10,
  },
  img: {
    width: "100%",
    height: "230px",
  },
  capitialText: {
    textTransform: "uppercase",
  },

  error: {
    color: "red",
  },

  padding: {
    paddingBottom: 20,
  },

  label: {
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },

  checkboxLabel: {
    font: "normal normal bold 16px Helvetica Neue",
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },

  Typography: {
    font: "normal normal bold 25px Helvetica Neue",
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #DBD8D8",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    height: "47px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginTop: 20,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#434040",
  },
  inputRoot: {
    font: "normal normal normal 16px/18px Helvetica Neue",
    color: "#434040",
    opacity: 1,
    display: "flex",
  },
  inputInput: {
    paddingTop: "15px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
  imgCategory: {
    width: 150,
    height: 150,
    borderRadius: 150 / 2,
    overflow: "hidden",
    borderWidth: 3,
  },
}));
