import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 20,
  },

  flexGrow: {
    flexGrow: 1,
  },
  title: {
    marginLeft: 10,
    font: "normal normal bold 24px/29px Helvetica Neue",
    color: "#070606",
  },
  btnCancel: {
    color: "#FFFFFF",
    //textTransform: "none",
    backgroundColor: "gray",
    marginRight: "10px",
    boxShadow: "none",
    "&:hover": {
      boxShadow: "none",
      backgroundColor: "gray",
    },
  },
  img: {
    width: 150,
    height: 150,
    borderRadius: 150 / 2,
    overflow: "hidden",
    borderWidth: 3,
  },
  input: {
    display: "flex",
    paddingBottom: 10,
    justifyContent: "flex-start",
  },
  padding: {
    marginBottom: 20,
  },

  cityContainer: {
    border: "1px solid #D3CDCD",
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    marginBottom: 30,
    borderRadius: "4px",
  },
  flatBtn: {
    marginLeft: "20px",
    marginRight: "10px",
    borderRadius: "20px",
    marginTop: 5,
    marginBottom: 5,
    textAlign: "center",
    textTransform: "none",
    width: "20%",
    [theme.breakpoints.down("xs")]: {
      width: "30%",
    },
    height: "25px",
  },

  error: {
    color: "red",
    fontSize: "12px",
    opacity: 0.8,
  },
  label: {
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },
}));

export default useStyles;
