import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  property_details: {
    width: "150px",
    height: "150px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    marginTop: "10px",
    borderRadius: "8px",
    backgroundColor: "#F1C40F",
    fontSize: "20px",
    color: "black",
    margin: "auto",
    cursor: "pointer",
    // "&:hover": {
    //   backgroundColor: "#FFFFFF",
    //   boxShadow: "0px 0px 72px #0000000F",
    // },
    [theme.breakpoints.down("xs")]: {
      width: "100px",
      height: "100px",
      fontSize: "14px",
    },
  },
  add_property_btn: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export default useStyles;
