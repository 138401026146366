import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0px",
    marginTop: "0px",
  },
  img: {
    height: "120px",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    border: "1px solid #D3CDCD",
    backgroundColor: "#FFFFFF",
  },

  status: {
    color: "#D51734",
    font: " normal normal bold 24px/29px Helvetica Neue;",
    marginRight: 200,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    paddingBottom: "20px",
  },

  key: {
    font: " normal normal bold 16px/19px Helvetica Neue",
    color: "#070606",
    opacity: 1,
  },
  columnCount: {
    [theme.breakpoints.down("sm")]: {
      columnCount: 1,
    },
    columnCount: 2,
  },

  appBar: {
    height: "50px",
    border: "1px solid #F7F7F7",
    background: "#FFFFFF",
    paddingLeft: 20,
  },

  margin: {
    marginTop: theme.spacing(12),
  },
  cardHeader: {
    backgroundColor: "#F2F2F4",
    font: "normal normal bold 24px/29px Helvetica Neue",
    color: "#070606",
    opacity: 1,
  },
  cardSubHeader: {
    backgroundColor: "#B4D3F745",
  },
}));
export default useStyles;
