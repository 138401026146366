import mock from "../../../utils/mock";
import Data from "MockServer/db.json";

let { retriveUserDocuments } = Data;

mock.onPost("/updatePersonalDetails").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const username = data.username;
      const user = retriveUserDocuments.filter(
        (item) => item.userDetails.cellPhone === username
      );
      const responseData = user.map(
        (item) =>
          (item.userDetails = {
            ...item.userDetails,
            firstName: data.firstName,
            lastName: data.lastName,
            cellPhone: data.cellPhone,
            email: data.email,
            gender: data.gender,
            dob: data.dob,
            postalAddress: data.postalAddress,
            postalCode: data.postalCode,
            cityDTO: {
              ...item.userDetails.cityDTO,
              cityId: data.cityId,
            },
          })
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
