import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const Tablecell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F2F2F4",
    color: "#A09B9B",
    font: "normal normal bold 16px/19px Helvetica Neue",
    borderBottom: "none",
    textAlign: "center",
  },
  body: {
    font: "normal normal bold 12px/15px Helvetica Neue",
    opacity: 1,
    borderBottom: "none",
    color: (props) => (props.link ? "#1D45EE" : "#A09B9B"),
    textAlign: "center",
  },
}))(TableCell);

export const Tablerow = withStyles((theme) => ({
  root: {
    backgroundColor: "FFFFFF",
    borderBottom: "none",
    textAlign: "center",
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiPaginationItem-icon": {
      backgroundColor: "green",
    },
  },

  grow: {
    backgroundColor: "#ffffff",
    flexGrow: 1,
    padding: 20,
    paddingTop: 0,
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #DBD8D8",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    height: "47px",
    backgroundColor: "#FFFFFF",
    width: "100%",
    marginTop: 20,
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#918F8F",
  },
  inputRoot: {
    font: "normal normal bold 16px/19px Helvetica Neue",
    color: "#A09B9B",
    opacity: 1,
    display: "flex",
  },
  inputInput: {
    paddingTop: "15px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },

  cellLink: {
    textDecoration: "underline",
    font: "normal normal bold 12px/15px Helvetica Neue",
    color: "#052ED6",
    cursor: "pointer",
  },

  appBarBtnContainer: {
    marginTop: "10px",
    [theme.breakpoints.down("sm")]: {
      width: "50%",
      marginLeft: 20,
    },
  },

  menuPaper: {
    maxHeight: 200,
  },

  filter: {
    width: 300,
    [theme.breakpoints.down("sm")]: {
      width: "auto",
    },
    marginTop: "10px",
    marginLeft: 20,
    marginRight: 20,
  },

  tableHeader: {
    backgroundColor: "white",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      justifyContent: "center",
    },
  },
  noResultText: {
    textAlign: "center",
    padding: 40,
  },
}));
