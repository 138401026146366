import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { skills } = Data;

mock.onPost("/getCategoryBasedSkills").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const categoryIds = data.categoryIds;
      let responseData = [];
      skills.map((item) =>
        item.skillsDtoList.map((category) =>
          categoryIds.forEach((id) => {
            if (id === category.categoryId) {
              responseData.push(item);
            } else {
              return null;
            }
          })
        )
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
