import {
  container,
  defaultFont,
  primaryColor,
  defaultBoxShadow,
  infoColor,
  successColor,
  warningColor,
  dangerColor,
  whiteColor,
  grayColor,
} from "assets/jss/material-dashboard-react.js";

import dropdownStyle from "assets/jss/material-dashboard-react/dropdownStyle.js";

const headerStyle = (theme) => ({
  ...dropdownStyle(theme),

  appBar: {
    [theme.breakpoints.down("sm")]: {
      backgroundColor: "#F5F5FA",
    },
    backgroundColor: "#FFFFFF",
    borderBottom: "0",
    marginBottom: "0",
    //position: "absolute",
    width: "99.95%",
    zIndex: "1029",
    color: "#BCBCCB",
    border: "0",
    borderRadius: "0px",
    transition: "all 150ms ease 0s",
    minHeight: "50px",
    display: "block",
    boxShadow: "none",
    paddingBottom: "10px",
    paddingTop: "10px",
  },
  container: {
    ...container,
    minHeight: "50px",
  },
  flex: {
    flex: 1,
  },
  title: {
    ...defaultFont,
    letterSpacing: "unset",
    lineHeight: "30px",
    fontSize: "18px",
    borderRadius: "3px",
    textTransform: "none",
    color: "inherit",
    margin: "0",
    "&:hover,&:focus": {
      background: "transparent",
    },
  },
  margin: {
    zIndex: "4",
    margin: "0",
  },
  search: {
    "& > div": {
      marginTop: "0",
    },
    [theme.breakpoints.down("sm")]: {
      margin: "10px 15px !important",
      float: "none !important",
      paddingTop: "1px",
      paddingBottom: "1px",
      padding: "0!important",
      width: "60%",
      marginTop: "40px",
      "& input": {
        color: grayColor,
      },
    },
    width: "95%",
  },
  appResponsive: {
    top: "8px",
  },
  primary: {
    backgroundColor: primaryColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  info: {
    backgroundColor: infoColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  success: {
    backgroundColor: successColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  warning: {
    backgroundColor: warningColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },
  danger: {
    backgroundColor: dangerColor[0],
    color: whiteColor,
    ...defaultBoxShadow,
  },

  img: {
    width: "80px",
  },

  notifications: {
    zIndex: "4",
    [theme.breakpoints.up("xs")]: {
      position: "absolute",
      top: "0px",
      border: "1px solid " + whiteColor,
      right: "10px",
      fontSize: "9px",
      background: warningColor[0],
      color: whiteColor,
      minWidth: "16px",
      height: "15px",
      borderRadius: "10px",
      textAlign: "center",
      lineHeight: "16px",
      verticalAlign: "middle",
      display: "block",
    },
  },
  notificationicon: {
    [theme.breakpoints.down("sm")]: {
      position: "absolute",
    },
    right: "0",
  },

  manager: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    display: "inline-block",
  },

  searchIcon: {
    padding: theme.spacing(1),
    height: "100%",
    pointerEvents: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },

  inputInput: {
    "& > div": {
      marginTop: "0",
    },
    padding: theme.spacing(1, 1, 1, 1),
    flex: 1,
    color: "#606060",
    justifyContent: "center",
  },

  grow: {
    flexGrow: 1,
  },
  searchbar: {
    color: "#606060",
    padding: "2px 4px",
    display: "flex",
    alignItems: "center",
    marginRight: 0,
    boxShadow: "none",
    textAlign: "left",
    marginLeft: theme.spacing(25),

    [theme.breakpoints.down("sm")]: {
      top: "80px",
      position: "relative",
      marginLeft: "-120px",
      marginRight: "-5px",
      borderRadius: "4px",
      backgroundColor: "#FFFFFF",
      height: "58px",
      justifyContent: "center",
    },
  },

  logo: {
    width: "30px",
    display: "flex",
    height: "30px",
    marginLeft: "10px",
    marginRight: "15px",
    marginBottom: "15px",
  },
  imageLogo: {
    width: "170px",
    position: "absolute",
    verticalAlign: "middle",
    border: "0",
  },
});

export default headerStyle;
