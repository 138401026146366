import React, { useState, useEffect } from "react";
import Button from "Components/CustomButtons/Button";
import Header from "Components/Header/TitleHeader";
import Card from "Components/Card/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/categories/subcategoryStyle";
import AddProblemType from "Components/Dialogs/categories/addProblemType";
import { callAPI } from "Utils/HttpUtil";
import { getProblemTypes } from "Utils/ApiEndpoints";

const headCells = [
  { label: "" },
  { label: "Problem Type" },
  { label: "Subcategory" },
  { label: "Category" },
  { label: "Action" },
];

function ProblemTypes(props) {
  const { subcategoryId } = props;

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    problemTypes: null,
    loading: true,
  });
  const [update, setUpdate] = useState();
  const [selected, setSelected] = useState([]);
  const [dialog, setDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialog = () => {
    setDialog(!dialog);
  };

  const handleSelectProblemType = (id) => {
    let newArray = [...selected, id];
    if (selected.includes(id)) {
      newArray = newArray.filter((item) => item !== id);
    }
    setSelected(newArray);
  };

  const apiSuccessCallbackGetProblemTypes = async (res) => {
    if (res.data.message === "SUCCESS") {
      setData({
        problemTypes: await res.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallbackGetProblemTypes = (err) => {
    console.log(err);
  };

  useEffect(() => {
    callAPI(
      "POST",
      getProblemTypes,
      { subCategoryId: subcategoryId },
      apiSuccessCallbackGetProblemTypes,
      apiErrorCallbackGetProblemTypes
    );
  }, [subcategoryId]);

  if (data.loading === true) {
    return null;
  }

  return (
    <>
      <Header
        title="Problem Types"
        rightLinks={
          <>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              className={classes.space}
              onClick={handleDialog}
            >
              Add Problem Type
            </Button>
            <Button
              color="danger"
              // onClick={onDeleteAll}
              startIcon={<DeleteIcon />}
              disabled={selected.length > 0 ? false : true}
            >
              Delete
            </Button>
          </>
        }
      />
      <Card>
        {dialog ? (
          <AddProblemType
            open={dialog}
            handleClose={handleDialog}
            setUpdate={setUpdate}
            subCategoryId={subcategoryId}
          />
        ) : null}
        <TableContainer>
          <Table>
            <TableHead>
              <Tablerow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </Tablerow>
            </TableHead>
            <TableBody>
              {data?.problemTypes
                .slice(page * 10, page * 10 + 10)
                .map((row, index) => {
                  return (
                    <Tablerow role="checkbox" key={index}>
                      <Tablecell align="left">
                        <Checkbox
                          color="primary"
                          onChange={() =>
                            handleSelectProblemType(row.problemTypeId)
                          }
                          checked={selected.includes(row.problemTypeId)}
                          value={row.problemTypeId}
                        />
                      </Tablecell>
                      <Tablecell align="left" className={classes.cellLink}>
                        {row.problemTypeName}
                      </Tablecell>
                      <Tablecell align="left" className={classes.cellLink}>
                        {row.subCategory}
                      </Tablecell>
                      <Tablecell className={classes.tabelCell} align="left">
                        {row.category}
                      </Tablecell>

                      <Tablecell className={classes.tabelCell} align="left">
                        <IconButton
                          style={{ color: "#2699FB" }}
                          // onClick={() => onToggle(row.subCategoryId, row.status)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          style={{ color: "#2699FB" }}
                          // onClick={() => onDelete(row.subCategoryId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tablecell>
                    </Tablerow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data?.problemTypes.length}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
        />
      </Card>
    </>
  );
}

export default ProblemTypes;
