export const headCellsOrders = [
  { label: "Order Id" },
  { label: "Customer Name" },
  { label: "Category Name" },
  { label: "SubCategory Name" },
  { label: "ProblemType Name" },
  { label: "Scheduled Date Time" },
];

export const orderStatus = [
  { label: "Draft", value: "Draft" },
  { label: "Inspected", value: "Inspected" },
  { label: "Quotation Sent", value: "Quotation_Sent" },
  { label: "Quotation Feedback", value: "Quotation_Feedback" },
  { label: "Quotation Resent", value: "Quotation_Resent" },
  { label: "Quotation Accepted", value: "Quotation_Accepted" },
  { label: "Assigned", value: "Assigned" },
  { label: "Vendor Accepted", value: "Vendor_Accepted" },
  { label: "Vendor Rejected", value: "Vendor_Rejected" },
  { label: "In Progress", value: "In_Progress" },
  { label: "Completed", value: "Completed" },
  { label: "Verified", value: "Verified" },
  { label: "Customer Approved", value: "Customer_Approved" },
  { label: "Customer Rejected", value: "Customer_Rejected" },
  { label: "Bill Generated", value: "Bill_Generated" },
  { label: "Partial Payment Done", value: "Partial_Payment_Done" },
  { label: "Full Payment Done", value: "Full_Payment_Done" },
];
