import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/sectionStyle";
import Button from "Components/CustomButtons/CustomButton";
import Tablerow from "Components/Table/Tablerow";
import Divider from "@material-ui/core/Divider";
import ServiceAreaUpdate from "Components/Dialogs/approvals/ServiceAreaUpdate";
import { callAPI } from "Utils/HttpUtil";
import { updateUserDocuments } from "Utils/ApiEndpoints";

function ServiceArea(props) {
  const [dialog, setDialog] = useState(false);
  const classes = useStyles(props);
  const { servicearea, userId, setUpdate } = props;

  /*const pendingServiceAreas = servicearea.filter(
    (item) => item.status === "Verification"
  );*/

  const handleClose = () => {
    setDialog(false);
  };

  const handleOnEdit = () => {
    setDialog(true);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.responseData === "Status Updated") {
      setUpdate(new Date());
      alert(responseData.data.responseData);
    } else {
      alert(responseData.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
    alert("Internal error please try after sometime");
  };

  const updateStatus = (e, areaId) => {
    callAPI(
      "POST",
      updateUserDocuments,
      {
        userId: userId,
        status: e.currentTarget.value,
        model: "userServiceArea",
        docType: "",
        serviceAreaId: areaId,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };
  const AreaDetails = (props) => {
    const { serviceAreas } = props;

    return (
      <>
        {serviceAreas.map((value, index) => {
          return (
            <div key={index}>
              <Divider />
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <Tablerow name="City" value={value.city} />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Tablerow name="Status" value={value.status} />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Tablerow name="Area" value={value.name} />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Tablerow name="Location Lat" value={value.latitude} />
                </GridItem>
                <GridItem xs={12} md={6}>
                  <Tablerow name="Location Long" value={value.longitude} />
                </GridItem>
              </GridContainer>
              {value.status === "Verification" || value.status === "Pending" ? (
                <div className={classes.btnContainer}>
                  <Button
                    onClick={(e) => updateStatus(e, value.id)}
                    backgroundcolor="#11F79F48"
                    border="#0ED68A"
                    value="Approved"
                  >
                    Approve
                  </Button>
                  <Button
                    onClick={(e) => updateStatus(e, value.id)}
                    backgroundcolor="#F7244D58"
                    border="#FC3D62D8"
                    value="Rejected"
                  >
                    Reject
                  </Button>
                  <Button
                    onClick={(e) => updateStatus(e, value.id)}
                    value="Reassign"
                    disabled
                    backgroundcolor="#F8931D58"
                    border="#F08A14"
                  >
                    Re-assign
                  </Button>
                </div>
              ) : null}
            </div>
          );
        })}
      </>
    );
  };

  return (
    <div>
      <CardHeader
        className={classes.cardHeader}
        title={"Service Location"}
        disableTypography
        action={
          <IconButton aria-label="settings" onClick={handleOnEdit}>
            <EditIcon />
          </IconButton>
        }
      />
      {dialog ? (
        <ServiceAreaUpdate
          open={dialog}
          handleClose={handleClose}
          serviceAreas={servicearea}
          setUpdate={setUpdate}
        />
      ) : null}
      <Card
        className={classes.root}
        style={{ overflowY: "scroll", maxHeight: "40vh" }}
      >
        <AreaDetails serviceAreas={servicearea} />
      </Card>
    </div>
  );
}

export default ServiceArea;
