import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardBody from "Components/Card/CardBody";
import TextField from "Components/TextField/TextField";
import { MenuItem, Typography } from "@material-ui/core";
import Button from "Components/CustomButtons/Button";
import Snackbar from "Components/Snackbar/SnackbarError";
import { useForm, Controller } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { callMockAPI } from "Utils/HttpUtil";
import useStyles from "assets/jss/material-dashboard-react/views/propertyManagement/newProperty";

function NewUser() {
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      noOfFloors: "",
      admin: "",
      propertyType: "",
    },
  });
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onApiSuccessCreateProperty = (res) => {
    if (res.data.message === "SUCCESS") {
      seterror({
        error: true,
        message: "Property created successfully!",
        key: Math.random(),
      });
    }
  };

  const onCreateProperty = (data) => {
    if (data) {
      callMockAPI(
        "POST",
        "/property_management_property/create",
        {
          propertyname: data.propertyName,
          phone: data.mobileNumber,
        },
        onApiSuccessCreateProperty
      );
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          {error.error ? (
            <Snackbar key={error.key} message={error.message} color="success" />
          ) : null}
          <Button
            color="secondary"
            onClick={() => history.push("/admin/property_management")}
            disableRipple
          >
            Back
          </Button>
          <Typography align="center">
            <b>New Property</b>
          </Typography>
          <Typography align="left">
            <b>Property Details</b>
          </Typography>
          <form onSubmit={handleSubmit((data) => onCreateProperty(data))}>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="mobileNumber"
                  label="Mobile Number"
                  margin="normal"
                  inputRef={register({
                    required: "mobile number is required!",
                    pattern: {
                      value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                      message: "invalid mobile number",
                    },
                  })}
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={Boolean(errors.mobileNumber)}
                  helperText={errors.mobileNumber?.message}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="propertyName"
                  label="Property Name"
                  margin="normal"
                  inputRef={register({
                    required: "property name is required!",
                  })}
                  error={Boolean(errors.propertyName)}
                  helperText={errors.propertyName?.message}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Controller
                  name={"propertyType"}
                  control={control}
                  rules={{ required: "admin name is required!" }}
                  render={({ onChange, value, ref }) => {
                    return (
                      <TextField
                        select
                        label="Property Type"
                        margin="normal"
                        error={Boolean(errors.propertyType)}
                        helperText={errors.propertyType?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        inputRef={ref}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            classes: { paper: classes.menuPaper },
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        <MenuItem value="select" disabled>
                          select
                        </MenuItem>
                        <MenuItem value="bulk">Bulk</MenuItem>
                      </TextField>
                    );
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6}>
                <TextField
                  name="propertyAddress"
                  label="Address"
                  margin="normal"
                  inputRef={register({
                    required: "property address is required!",
                  })}
                  error={Boolean(errors.propertyAddress)}
                  helperText={errors.propertyAddress?.message}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="lat"
                  label="Lat"
                  margin="normal"
                  inputRef={register({
                    required: "lat-long is required!",
                  })}
                  error={Boolean(errors.lat)}
                  helperText={errors.lat?.message}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="long"
                  label="Long"
                  margin="normal"
                  inputRef={register({
                    required: "lat-long is required!",
                  })}
                  error={Boolean(errors.long)}
                  helperText={errors.long?.message}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <Controller
                  name={"admin"}
                  control={control}
                  rules={{ required: "admin name is required!" }}
                  render={({ onChange, value, ref }) => {
                    return (
                      <TextField
                        select
                        label="Admin"
                        margin="normal"
                        error={Boolean(errors.admin)}
                        helperText={errors.admin?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        inputRef={ref}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            classes: { paper: classes.menuPaper },
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        <MenuItem value="select" disabled>
                          select
                        </MenuItem>
                        <MenuItem value="admin1">Admin1</MenuItem>
                      </TextField>
                    );
                  }}
                />
              </GridItem>

              <GridItem xs={12} sm={6}>
                <Controller
                  name={"noOfFloors"}
                  control={control}
                  rules={{ required: "floors is required!" }}
                  render={({ onChange, value, ref }) => {
                    return (
                      <TextField
                        select
                        label="Number of Floors"
                        margin="normal"
                        error={Boolean(errors.noOfFloors)}
                        helperText={errors.noOfFloors?.message}
                        onChange={(e) => onChange(e.target.value)}
                        value={value}
                        inputRef={ref}
                        SelectProps={{
                          MenuProps: {
                            anchorOrigin: {
                              vertical: "bottom",
                              horizontal: "left",
                            },
                            classes: { paper: classes.menuPaper },
                            getContentAnchorEl: null,
                          },
                        }}
                      >
                        <MenuItem value="select" disabled>
                          select
                        </MenuItem>
                        <MenuItem value="10">10</MenuItem>
                      </TextField>
                    );
                  }}
                />
              </GridItem>
            </GridContainer>
            <div align="center">
              <Button type="submit" color="primary">
                Create
              </Button>
            </div>
          </form>
        </CardBody>
      </Card>
    </div>
  );
}

export default NewUser;
