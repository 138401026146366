import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import Button from "@material-ui/core/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/approvals/tableStyle";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { callAPI } from "Utils/HttpUtil";
import { getUserDocuments } from "Utils/ApiEndpoints";
import { setUserName } from "Utils/Session";

export default function Tasks() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    userDocuments: [],
    loading: true,
  });
  const [selectedButton, setSelectedButton] = useState("Verification");
  const [searchInput, setSearchInput] = useState("");
  const history = useHistory();

  const headCells = [
    { label: "User Id" },
    { label: "First Name" },
    { label: "Last Name" },
    { label: "Number" },
    { label: "Gender" },
    { label: selectedButton === "Verification" ? "Action" : null },
  ];

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.status === 200) {
      setData({
        userDocuments:
          responseData.data.responseData.vendorDocumentsDetailsDTOList,
        loading: false,
      });
    } else {
      setData({
        userDocuments: null,
        loading: false,
      });
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const handleFilterButton = (e) => {
    setSelectedButton(e.currentTarget.value);
    if (selectedButton !== e.currentTarget.value) {
      callAPI(
        "POST",
        getUserDocuments,
        {
          status: e.currentTarget.value,
        },
        apiSuccessCallback,
        apiErrorCallback
      );
    }
  };

  const onPreview = async (username, userId) => {
    await setUserName(username, userId);
    history.push(`/admin/approvals/onboarding_approvals/${userId}`);
  };

  useEffect(() => {
    callAPI(
      "POST",
      getUserDocuments,
      {
        status: "Verification",
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  }, []);

  if (data.loading === true) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div>
        <Button
          className={classNames(
            classes.toogleButton,
            selectedButton === "Verification"
              ? classes.btnfilterBackground
              : null
          )}
          value="Verification"
          onClick={handleFilterButton}
          disableFocusRipple
          disableRipple
        >
          Pending Approvals
        </Button>
        <Button
          className={classNames(
            classes.toogleButton,
            selectedButton === "Approved" ? classes.btnfilterBackground : null
          )}
          value="Approved"
          onClick={handleFilterButton}
          disableFocusRipple
          disableRipple
        >
          Approved
        </Button>
        <Button
          className={classNames(
            classes.toogleButton,
            selectedButton === "Rejected" ? classes.btnfilterBackground : null
          )}
          value="Rejected"
          onClick={handleFilterButton}
          disableFocusRipple
          disableRipple
        >
          Rejected
        </Button>
      </div>
      <div className={classes.grow}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Orders"
                onChange={(e) => setSearchInput(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>

      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.userDocuments
                ? data.userDocuments
                    .filter((item) => {
                      if (searchInput === "") {
                        return item;
                      } else if (
                        item.userDetails.firstName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.userDetails.lastName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.userDetails.email
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else if (
                        item.userDetails.cellPhone
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    })
                    .slice(page * 10, page * 10 + 10)
                    .map((row, index) => {
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <Tablerow key={row.userDetails.userId}>
                          <Tablecell
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="default"
                            align="left"
                            className={classes.cellLink}
                            //link
                          >
                            {row.userDetails.userId}
                          </Tablecell>
                          <Tablecell align="left">
                            {row.userDetails.firstName}
                          </Tablecell>
                          <Tablecell align="left">
                            {row.userDetails.lastName}
                          </Tablecell>
                          <Tablecell align="left">
                            {row.userDetails.cellPhone}
                          </Tablecell>

                          <Tablecell align="left">
                            {row.userDetails.gender}
                          </Tablecell>

                          {selectedButton !== "Verification" ? null : (
                            <Tablecell align="left">
                              <Button
                                onClick={() =>
                                  onPreview(
                                    row.userDetails.cellPhone,
                                    row.userDetails.userId
                                  )
                                }
                                className={classes.buttonPreview}
                              >
                                Review
                              </Button>
                              <Button className={classes.buttonReject}>
                                Reject
                              </Button>
                            </Tablecell>
                          )}
                        </Tablerow>
                      );
                    })
                : null}
            </TableBody>
          </Table>

          {data.userDocuments ? (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data.userDocuments.length}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : null}
        </TableContainer>
        {!data.userDocuments ? (
          <div className={classes.noResultText}>0 records found</div>
        ) : null}
      </Paper>
    </div>
  );
}
