import React from "react";
import TextField from "@material-ui/core/TextField";

function Textfield(props) {
  const { name, label, onChange, value, ...rest } = props;
  return (
    <TextField
      name={name}
      label={label}
      variant="outlined"
      fullWidth
      onChange={onChange}
      value={value}
      {...rest}
    />
  );
}

export default Textfield;
