import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "Components/TextField/TextField";
import { useForm } from "react-hook-form";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { uploadDocuments, savePanCardDetails } from "Utils/ApiEndpoints";
import {
  callAPI,
  callDocumentUploadAPI,
} from "Utils/HttpUtil";

function PanCardUpdate(props) {
  const classes = styles();
  const { open, handleClose, panCardNumber, setUpdate } = props;
  const [pancardimg, setPancardimg] = useState();
  const username = localStorage.getItem("username");

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      pancardnumber: panCardNumber,
    },
  });

  const onUploadSucess = (res) => {
    if (res.data.message === "SUCCESS") {
      alert("Uploaded Successfully!!");
    } else {
      alert(res.data.message);
      setPancardimg(null);
    }
  };

  const onUploaderror = (err) => {
    console.log(err);
    setPancardimg(null);
  };

  const handlePancardUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setPancardimg(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("docType", "PanCardImage");
      formData.append("userName", username);
      callDocumentUploadAPI(
        "POST",
        uploadDocuments,
        formData,
        onUploadSucess,
        onUploaderror
      );
    }
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated Successfully!");
      handleClose();
    } else {
      alert(responseData.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const updatePanCardDetails = async (details) => {
    await callAPI(
      "POST",
      savePanCardDetails,
      {
        userName: username,
        personalDocumentsDTO: {
          panCardNumber: details.pancardnumber,
        },
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update PAN Card Details
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => updatePanCardDetails(data))}>
          <GridContainer>
            <GridItem xs={12} md={5}>
              <TextField
                name="pancardnumber"
                label="Pan Card Number"
                variant="outlined"
                fullWidth
                className={classes.padding}
                inputRef={register({
                  required: "Pan card number is required.",
                })}
                inputProps={{
                  maxLength: 10,
                }}
                error={Boolean(errors.pancardnumber)}
                helperText={errors.pancardnumber?.message}
              />
              <Typography className={classes.imgName}>
                Pan Card Image
              </Typography>

              <div className={classes.imgContainer}>
                {pancardimg ? (
                  <img src={pancardimg} alt="..." className={classes.img} />
                ) : (
                  <>
                    <PublishIcon className={classes.imgIcon} />
                    <Typography>Upload Image</Typography>
                  </>
                )}
              </div>
              <div className={classes.input}>
                <input
                  accept="image/*"
                  className={classes.input}
                  name="pancardImage"
                  multiple
                  type="file"
                  ref={register({
                    required: "Category image is required",
                  })}
                  onChange={handlePancardUpload}
                />
                {errors.pancardImage && (
                  <Typography align="left" className={classes.error}>
                    Pand card image is required
                  </Typography>
                )}
              </div>
            </GridItem>
          </GridContainer>
          <DialogActions className={classes.dialogAction}>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default PanCardUpdate;
