import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { subCategories } = Data;

mock.onPost("/getSubCategories").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const categoryId = data.categoryId.toString();
      const responseData = subCategories.filter(
        (item) => item.categoryId.toString() === categoryId
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/updateSubCategory").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      subCategories = subCategories.map((item) => {
        if (item.subCategoryId.toString() === data.subCategoryId.toString()) {
          item = {
            ...item,
            status: data.status,
          };
        }
        return item;
      });
      const responseData = subCategories.filter(
        (item) =>
          item.subCategoryId.toString() === data.subCategoryId.toString()
      );

      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/delete/subCategory").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      data.subCategoryId.forEach((i) => {
        subCategories = subCategories.filter(
          (item) => item.subCategoryId.toString() !== i.toString()
        );
      });
      resolve([200, { message: "SUCCESS", responseData: [] }]);
    }, 1000);
  });
});

mock.onPost("/add/subCategory").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      subCategories.push({
        categoryId: data.categoryId,
        subCategoryId: subCategories.length + 1,
        subCategoryName: data.name,
        status: "Active",
      });

      resolve([200, { message: "SUCCESS", responseData: subCategories }]);
    }, 1000);
  });
});
