import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Hidden from "@material-ui/core/Hidden";
import CustomInput from "Components/CustomInput/CustomInput.js";
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import Grow from "@material-ui/core/Grow";
import Paper from "@material-ui/core/Paper";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Poppers from "@material-ui/core/Popper";
import SearchIcon from "@material-ui/icons/Search";
import MenuIcon from "@material-ui/icons/Menu";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import useScrollTrigger from "@material-ui/core/useScrollTrigger";

// @material-ui/icons
// core components
import AdminNavbarLinks from "./AdminNavbarLinks.js";
import Notifications from "@material-ui/icons/Notifications";

import styles from "assets/jss/material-dashboard-react/components/headerStyle.js";
import logo from "assets/img/Capa_1.png";

const useStyles = makeStyles(styles);

function ElevationScroll(props) {
  const { children, window } = props;

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}
ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,

  window: PropTypes.func,
};

export default function Header(props) {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };

  /*function makeBrand() {
    var name;
    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }*/

  const { color } = props;
  const appBarClasses = classNames({
    [" " + classes[color]]: color,
  });
  return (
    <div className={classes.grow}>
      <ElevationScroll {...props}>
        <AppBar className={classes.appBar + appBarClasses}>
          <Toolbar>
            <Hidden smDown>
              <div className={classes.logo}>
                <img src={logo} className={classes.imageLogo} alt="...." />
              </div>
            </Hidden>

            <Hidden mdUp>
              <IconButton
                edge="start"
                color="inherit"
                aria-label="menu"
                onClick={props.handleDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
              <img src={logo} className={classes.img} alt="..." />
            </Hidden>
            <GridContainer spacing={3} style={{ width: "100%" }}>
              <GridItem xs={12}>
                <Paper className={classes.searchbar}>
                  <div className={classes.searchIcon}>
                    <SearchIcon />
                  </div>
                  <CustomInput
                    formControlProps={{
                      className: classes.inputInput,
                    }}
                    inputProps={{
                      placeholder: "Search transactions, invoices or help",
                      inputProps: {
                        "aria-label": "Search",
                      },
                    }}
                  />
                </Paper>
              </GridItem>
            </GridContainer>

            <IconButton
              color="inherit"
              aria-label="notification icon"
              className={classes.notificationicon}
              onClick={handleClickNotification}
              aria-owns={
                openNotification ? "notification-menu-list-grow" : null
              }
              aria-haspopup="true"
            >
              <Notifications />
              <span className={classes.notifications}>5</span>
            </IconButton>

            <Poppers
              open={Boolean(openNotification)}
              anchorEl={openNotification}
              transition
              disablePortal
              className={
                classNames({
                  [classes.popperClose]: !openNotification,
                }) /*+
              " " +
              classes.popperNav*/
              }
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  id="notification-menu-list-grow"
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleCloseNotification}>
                      <MenuList role="menu">
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={classes.dropdownItem}
                        >
                          Mike John responded to your email
                        </MenuItem>
                        <MenuItem
                          onClick={handleCloseNotification}
                          className={classes.dropdownItem}
                        >
                          Mike John responded to your email
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Poppers>
            <Hidden smDown>
              <AdminNavbarLinks />
            </Hidden>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
      <Toolbar />
    </div>
  );
}

Header.propTypes = {
  color: PropTypes.oneOf(["primary", "info", "success", "warning", "danger"]),
  rtlActive: PropTypes.bool,
  handleDrawerToggle: PropTypes.func,
  routes: PropTypes.arrayOf(PropTypes.object),
};
