import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/categories/tableStyle";
import { useHistory } from "react-router-dom";
import { callAPI, callMockAPI } from "Utils/HttpUtil";
import { getCategories } from "Utils/ApiEndpoints";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Button from "Components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";

export default function Tasks() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    categories: [],
    loading: true,
  });
  const [searchInput, setSearchInput] = useState("");

  const headCells = [
    { label: "Category Id" },
    { label: "Category Name" },
    { label: "Status" },
    { label: "Creation Date" },
  ];

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {
      setData({
        categories: responseData.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallback = (err) => {
    setData({
      categories: null,
      loading: false,
    });
  };

  const onClick = (id) => {
    history.push(`/admin/categories/${id}`);
  };

  useEffect(() => {
    callAPI("POST", getCategories, {}, apiSuccessCallback, apiErrorCallback);
  }, []);

  if (data.loading === true) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div align="right">
        <Button
          disableElevation
          disableTouchRipple
          disableRipple
          startIcon={<AddIcon />}
          color="primary"
          className={classes.appBarBtn}
          onClick={() => history.push("/admin/categories/create_category")}
        >
          Create Category
        </Button>
      </div>
      <div className={classes.grow}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Orders"
                onChange={(e) => setSearchInput(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data?.categories
                .filter((item) => {
                  if (searchInput === "") {
                    return item;
                  } else if (
                    item.categoryName
                      .toLowerCase()
                      .includes(searchInput.toLowerCase())
                  ) {
                    return item;
                  } else {
                    return null;
                  }
                })
                .slice(page * 10, page * 10 + 10)
                .map((row, index) => {
                  return (
                    <Tablerow role="checkbox" key={index}>
                      <Tablecell
                        align="left"
                        className={classes.cellLink}
                        onClick={() => onClick(row.categoryId)}
                      >
                        {row.categoryId}
                      </Tablecell>
                      <Tablecell className={classes.tabelCell} align="left">
                        {row.categoryName}
                      </Tablecell>
                      <Tablecell className={classes.tabelCell} align="left">
                        {row.categoryStatus}
                      </Tablecell>
                      <Tablecell className={classes.tabelCell} align="left">
                        {row.createdTime}
                      </Tablecell>
                    </Tablerow>
                  );
                })}
            </TableBody>
          </Table>
          {data.categories.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data.categories.length}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : null}
        </TableContainer>
        {!data.categories.length > 0 ? (
          <div className="noResultText">0 records found</div>
        ) : null}
      </Paper>
    </div>
  );
}
