import React, { useState, useEffect } from "react";
// core Components
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Button from "Components/CustomButtons/Button";
import Card from "Components/Card/Card.js";
import TextField from "@material-ui/core/TextField";
import CardBody from "Components/Card/CardBody.js";
import Typography from "@material-ui/core/Typography";
import styles from "assets/jss/material-dashboard-react/views/customerFormStyle";
import { MenuItem } from "@material-ui/core";
import { useForm, Controller } from "react-hook-form";
import { callAPI } from "Utils/HttpUtil";
import {
  createB2CCustomer,
  getCities,
  getLookupValues,
  fetchB2CCustomer,
} from "Utils/ApiEndpoints";
import { useHistory, useParams } from "react-router-dom";
import AlertMessage from "Components/Snackbar/SnackbarError";

export default function UpdateB2CCustomer() {
  const classes = styles();
  const history = useHistory();
  const params = useParams();
  const customer_id = params.customer_id;

  const [cities, setCities] = useState();
  const [source, setSource] = useState();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const [values, setValues] = useState({
    loading: true,
  });

  const { reset, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      customerFirstName: "",
      customerLastName: "",
      phoneNumber: "",
      email: "",
      type: "B2C",
      sourceId: "",
      address: "",
      area: "",
      zoneCode: "",
      cityId: "",
      pin: "",
    },
  });
  const onCancel = () => {
    history.push("/admin/customers/B2C");
  };

  const onApiSuccessGetCustomer = async (res) => {
    if (res.data.status === 200) {
      reset({
        ...(await res.data.responseData),
      });
      setValues({
        ...(await res.data.responseData),
        loading: false,
      });
    }
  };

  const onApiErrorGetCustomer = (err) => {
    console.log(err.response);
  };

  const onApiSucessCallBackUpdateCustomer = (res) => {
    if (res.data.status === 200) {
      seterror({
        error: true,
        message: "Customer details updated successfully!",
        key: Math.random(),
        color: "success",
      });
    }
  };

  const onApiErrorCallBackUpdateCustomer = (err) => {
    seterror({
      error: true,
      message: err.response.data.message,
      key: Math.random(),
      color: "error",
    });
  };

  const onApiSucessCallGetCities = (res) => {
    if (res.data.status === 200) {
      setCities(res.data.responseData);
    }
  };

  const onApiErrorCallGetCities = (err) => {
    console.log(err);
  };

  const onApiSucessCallBackGetSource = (res) => {
    if (res.data.status === 200) {
      setSource(res.data.responseData);
    }
  };

  const onApiErrorCallBackGetSource = (err) => {
    console.log(err);
  };

  const onSubmit = (data) => {
    callAPI(
      "POST",
      createB2CCustomer,
      {
        userId: values.userId,
        customerFirstName: data.customerFirstName,
        customerLastName: data.customerLastName,
        phoneNumber: data.phoneNumber,
        email: data.email,
        sourceId: data.sourceId,
        addressId: values.addressId,
        address: data.address,
        pin: data.pin,
        zoneCode: data.zoneCode,
        cityId: data.cityId,
        area: data.area,
      },
      onApiSucessCallBackUpdateCustomer,
      onApiErrorCallBackUpdateCustomer
    );
  };

  const getAllDetails = async () => {
    await callAPI(
      "GET",
      getCities,
      {},
      onApiSucessCallGetCities,
      onApiErrorCallGetCities
    );

    await callAPI(
      "POST",
      getLookupValues,
      { lookupType: "Source" },
      onApiSucessCallBackGetSource,
      onApiErrorCallBackGetSource
    );

    await callAPI(
      "POST",
      `${fetchB2CCustomer}${customer_id}`,
      {},
      onApiSuccessGetCustomer,
      onApiErrorGetCustomer
    );
  };

  useEffect(() => {
    getAllDetails();
  }, [customer_id]);

  if (values.loading === true) {
    return null;
  }
  return (
    <form onSubmit={handleSubmit((data) => onSubmit(data))}>
      <Card>
        <CardBody>
          {error.error ? (
            <AlertMessage
              key={error.key}
              message={error.message}
              color={error.color}
            />
          ) : null}
          <Typography variant="h6" align="center">
            <div align="left">
              <Button
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <b> Update Customer</b>
          </Typography>

          <Typography align="left">
            <b>Customer Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"customerFirstName"}
                control={control}
                rules={{
                  required: "You must provide the First name!",
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "You must provide a valid First name!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Customer First Name"
                      error={!!errors.customerFirstName}
                      helperText={errors.customerFirstName?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"customerLastName"}
                control={control}
                rules={{
                  required: "You must provide the Last name!",
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "You must provide a valid Last name!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Customer Last Name"
                      error={!!errors.customerLastName}
                      helperText={errors.customerLastName?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"email"}
                control={control}
                rules={{
                  required: "You must provide the email address!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "You must provide a valid email address!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Customer Email"
                      error={!!errors.email}
                      helperText={errors.email?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"phoneNumber"}
                control={control}
                rules={{
                  required: "You must provide the mobile Number!",
                  pattern: {
                    value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                    message: "You must provide a valid mobile Number!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Customer Number"
                      error={!!errors.phoneNumber}
                      helperText={errors.phoneNumber?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"sourceId"}
                control={control}
                rules={{ required: "Source is required" }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="source"
                      label="Source"
                      name="source"
                      error={!!errors.sourceId}
                      helperText={errors.sourceId?.message}
                      defaultValue="select"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select source
                      </MenuItem>
                      {source
                        ? source.map((value, index) => {
                            return (
                              <MenuItem value={value.lkp_id} key={index}>
                                {value.value}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Customer Address</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"address"}
                control={control}
                rules={{
                  required: "You must provide the address!",
                  pattern: {
                    message: "You must provide a valid address!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Address"
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"area"}
                control={control}
                rules={{
                  required: "You must provide the area!",
                  pattern: {
                    message: "You must provide a valid area!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Area"
                      error={!!errors.area}
                      helperText={errors.area?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"zoneCode"}
                control={control}
                rules={{
                  required: "You must provide the zone code!",
                  pattern: {
                    message: "You must provide a valid zone code!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Zone Code"
                      error={!!errors.zoneCode}
                      helperText={errors.zoneCode?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"cityId"}
                control={control}
                rules={{ required: "City is required" }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="City"
                      error={!!errors.cityId}
                      helperText={errors.cityId?.message}
                      defaultValue="select"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"pin"}
                control={control}
                rules={{
                  required: "You must provide the pin code!",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "You must provide a valid Pin Code!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Pin Code"
                      error={!!errors.pin}
                      helperText={errors.pin?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <div className={classes.Button}>
            <Button type="submit" color="primary" className={classes.submit}>
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    </form>
  );
}
