import React, { useState, useEffect } from "react";
import Typography from "@material-ui/core/Typography";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import { useForm } from "react-hook-form";
import TextField from "Components/TextField/TextField";
import styles from "assets/jss/material-dashboard-react/views/categories/createcategoryStyle";
import Button from "Components/CustomButtons/Button";
import Card from "Components/Card/Card";
import CardBody from "Components/Card/CardBody";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import classNames from "classnames";
import Image from "assets/img/imgupload.jpeg";
import { callAPI } from "Utils/HttpUtil";
import { getCities, saveCategory } from "Utils/ApiEndpoints";
import { useHistory } from "react-router-dom";

function CategoryDetails() {
  const classes = styles();
  const history = useHistory();
  const [selected, setSelected] = useState([]);
  const [data, setData] = useState({
    cities: [],
    loading: true,
  });
  const [values, setValues] = useState({
    categoryImage: "",
    categoryImageUrl: "",
  });

  const defaultValues = {
    categoryName: "",
    categoryDescription: "",
  };

  const { register, handleSubmit, errors } = useForm({ defaultValues });

  const onChange = (id) => {
    let newArray = [...selected, id];
    if (selected.includes(id)) {
      newArray = newArray.filter((cityId) => cityId !== id);
    }
    setSelected(newArray);
  };

  const uploadCategoryImage = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setValues({
          ...values,
          categoryImage: reader.result,
          categoryImageUrl: file,
        });
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const apiSuccessCallbackgetCities = (res) => {
    if (res.data.message === "SUCCESS") {
      setData({
        cities: res.data.responseData,
        loading: false,
      });
    } else {
      setData({
        cities: null,
        loading: false,
      });
    }
  };

  const apiErrorCallbackgetCities = (err) => {
    console.log(err);
  };

  const apiSuccessCallbackSaveCategory = (res) => {
    console.log(res);
    if (res.data.message === "SUCCESS") {
      alert("Category created successfully!");
    } else {
      alert(res.data.message);
    }
  };

  const apiErrorCallbackSaveCategory = (err) => {
    console.log(err);
  };

  const OnSaveCategory = async (details) => {
    const formData = new FormData();
    formData.append("file", values.categoryImageUrl);
    formData.append("categoryName", details.categoryName);
    formData.append("description", details.categoryDescription);
    formData.append("cityIds", selected);
    callAPI(
      "POST",
      saveCategory,
      { formData },
      apiSuccessCallbackSaveCategory,
      apiErrorCallbackSaveCategory
    );
  };

  const onCancel = () => {
    history.push("/admin/categories");
  };

  useEffect(() => {
    callAPI(
      "GET",
      getCities,
      {},
      apiSuccessCallbackgetCities,
      apiErrorCallbackgetCities
    );
  }, []);

  if (data.loading === true) {
    return null;
  }

  return (
    <Card>
      <CardBody>
        <Button
          disableElevation
          disableTouchRipple
          color="secondary"
          onClick={onCancel}
        >
          Cancel
        </Button>
        <div align="center">
          <Typography>
            <b>Create Category</b>
          </Typography>
        </div>
        <form onSubmit={handleSubmit((data) => OnSaveCategory(data))}>
          <div className={classes.root}>
            <GridContainer spacing={3}>
              <GridItem xs={12} md={6}>
                <Typography>
                  <b>Category Image</b>
                </Typography>
                <img
                  alt="...."
                  src={values.categoryImage ? values.categoryImage : Image}
                  className={classes.img}
                />
                {errors.image && (
                  <Typography align="left" className={classes.error}>
                    Category Image is required
                  </Typography>
                )}
                <div className={classes.input}>
                  <input
                    accept="image/x-png,image/jpeg"
                    name="image"
                    multiple
                    type="file"
                    ref={register({
                      required: "Category image is required",
                    })}
                    onChange={uploadCategoryImage}
                  />
                </div>
              </GridItem>
            </GridContainer>
            <Typography className={classNames(classes.padding)}>
              <b>Category Information</b>
            </Typography>

            <GridContainer spacing={3}>
              <GridItem xs={12} sm={6}>
                <TextField
                  label="Category Name"
                  name="categoryName"
                  inputRef={register({
                    required: "Category Name is required.",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: "Invalid Category Name",
                    },
                  })}
                  error={Boolean(errors.categoryName)}
                  helperText={errors.categoryName?.message}
                  className={classes.padding}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  label="Category Description"
                  name="categoryDescription"
                  multiline
                  inputRef={register({
                    required: "Category Description is required.",
                    pattern: {
                      value: /^[a-zA-Z ]*$/,
                      message: "Invalid Category Description",
                    },
                  })}
                  error={Boolean(errors.categoryDescription)}
                  helperText={errors.categoryDescription?.message}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Typography>
                  <b>Active Cities</b>
                </Typography>
                <FormGroup row>
                  {data.cities.map((prop, index) => {
                    return (
                      <FormControlLabel
                        key={index}
                        name="city"
                        classes={{ label: classes.label }}
                        inputRef={register({
                          required: "City is required",
                        })}
                        control={
                          <Checkbox
                            name={prop.categoryName}
                            onChange={() => onChange(prop.cityId)}
                            value={prop.cityId}
                            color="primary"
                            checked={selected.includes(prop.cityId)}
                          />
                        }
                        label={prop.cityName}
                      />
                    );
                  })}
                </FormGroup>
                {errors.city && (
                  <Typography align="left" className={classes.error}>
                    Please select the cities
                  </Typography>
                )}
              </GridItem>
            </GridContainer>
            <div align="center">
              <Button
                disableElevation
                disableTouchRipple
                color="primary"
                type="submit"
              >
                Create Category
              </Button>
            </div>
          </div>
        </form>
      </CardBody>
    </Card>
  );
}

export default CategoryDetails;
