import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "Components/TextField/TextField";
import { useForm } from "react-hook-form";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { updateBankDetails, bankDetailsProof } from "Utils/ApiEndpoints";
import { callDocumentUploadAPI, callAPI } from "Utils/HttpUtil";

function BankDetailsUpdate(props) {
  const classes = styles();
  const { open, handleClose, bankdetails, setUpdate } = props;

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      accountNumber: bankdetails.accountNumber,
      accountName: bankdetails.accountName,
      bankName: bankdetails.bankName,
      branchName: bankdetails.branchName,
      ifscCode: bankdetails.ifscCode,
    },
  });

  const [cheque, setCheque] = useState();
  const username = localStorage.getItem("username");

  const onUploadSucess = (res) => {
    if (res.data.message === "SUCCESS") {
      alert("Uploaded Successfully!!");
    } else {
      alert(res.data.message);
      setCheque(null);
    }
  };

  const onUploaderror = (err) => {
    console.log(err);
    setCheque(null);
  };

  const uploadCheque = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setCheque(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("docType", "AadharCardFrontImage");
      formData.append("userName", username);
      callDocumentUploadAPI(
        "POST",
        bankDetailsProof,
        formData,
        onUploadSucess,
        onUploaderror
      );
    }
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated Successfully!");
      handleClose();
    } else {
      alert(responseData.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const saveBankDetails = async (details) => {
    await callAPI(
      "POST",
      updateBankDetails,
      {
        userName: username,
        bankDetailsDTO: {
          bankName: details.bankName,
          accountNumber: details.accountNumber,
          ifscCode: details.ifscCode,
          branchName: details.branchName,
        },
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update Bank Details
        </Typography>
      </DialogTitle>

      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => saveBankDetails(data))}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <TextField
                name="accountNumber"
                label="Account Number"
                className={classes.padding}
                inputProps={{
                  className: classes.capitialText,
                }}
                inputRef={register({
                  required: "Account Number is required.",
                  pattern: {
                    value: /[0-9]/,
                    message: "Invalid Account Number",
                  },
                })}
                error={Boolean(errors.accountNumber)}
                helperText={errors.accountNumber?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="bankName"
                label="Bank Name"
                className={classes.padding}
                inputProps={{
                  className: classes.capitialText,
                }}
                inputRef={register({
                  required: "Bank Name is required.",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "Invalid Bank Name",
                  },
                })}
                error={Boolean(errors.bankName)}
                helperText={errors.bankName?.message}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <TextField
                name="branchName"
                label="Branch Name"
                className={classes.padding}
                inputProps={{
                  className: classes.capitialText,
                }}
                inputRef={register({
                  required: "Branch Name is required.",
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "Invalid Branch Name",
                  },
                })}
                error={Boolean(errors.branchName)}
                helperText={errors.branchName?.message}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <TextField
                name="ifscCode"
                label="IFSC Code"
                className={classes.padding}
                inputProps={{
                  className: classes.capitialText,
                }}
                inputRef={register({
                  required: "IFSC Code is required.",
                })}
                error={Boolean(errors.ifscCode)}
                helperText={errors.ifscCode?.message}
              />
            </GridItem>
            <GridItem xs={false} md={2} />
            <GridItem xs={12} md={8}>
              <Typography className={classes.imgName}>
                Cancelled Cheque Copy
              </Typography>
              <div className={classes.imgContainer}>
                {cheque ? (
                  <img src={cheque} alt="..." className={classes.img} />
                ) : (
                  <>
                    <PublishIcon className={classes.imgIcon} />
                    <Typography>Upload Image</Typography>
                  </>
                )}
              </div>
              <div className={classes.input}>
                <input
                  accept="image/x-png,image/jpeg"
                  className={classes.input}
                  name="cancelledCheque"
                  multiple
                  type="file"
                  onChange={uploadCheque}
                  ref={register({
                    required: "Cancelled Cheque image is required",
                  })}
                />
                {errors.cancelledCheque && (
                  <Typography align="left" className={classes.error}>
                    Cancelled Cheque image is required
                  </Typography>
                )}
              </div>
            </GridItem>
          </GridContainer>
          <DialogActions className={classes.dialogAction}>
            <Button color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default BankDetailsUpdate;
