import React, { useState, useEffect } from "react";
import Button from "Components/CustomButtons/Button";
import Header from "Components/Header/TitleHeader";
import Card from "Components/Card/Card";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TablePagination from "@material-ui/core/TablePagination";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import AddIcon from "@material-ui/icons/Add";
import IconButton from "@material-ui/core/IconButton";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/categories/subcategoryStyle";
import { useHistory } from "react-router-dom";
import AddSubCategory from "Components/Dialogs/categories/addSubCategory";
import { callAPI } from "Utils/HttpUtil";
import { getSubCategories } from "Utils/ApiEndpoints";

const headCells = [
  { label: "" },
  { label: "Sub Category Id" },
  { label: "Sub Category Name" },
  { label: "Action" },
];

function SubCategories(props) {
  const { categoryId } = props;
  const history = useHistory();

  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    subCategories: [],
    loading: false,
  });
  const [selected, setSelected] = useState([]);
  const [update, setUpdate] = useState();
  const [dialog, setDialog] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleDialog = () => {
    setDialog(!dialog);
  };

  const handleSelectSubCategory = (id) => {
    let newArray = [...selected, id];
    if (selected.includes(id)) {
      newArray = newArray.filter((item) => item !== id);
    }
    setSelected(newArray);
  };

  const handleOnClick = (row) => {
    if (row) {
      history.push(`/admin/categories/subcategories/${row.subCategoryId}`);
    }
  };

  const apiSuccessCallbackGetSubCategories = async (res) => {
    console.log(res);
    if (res.data.message === "SUCCESS") {
      setData({
        subCategories: await res.data.responseData,
        loading: false,
      });
    }
  };

  const apiSuccessCallBackUpdateSubCategory = (res) => {
    if (res.data.message === "SUCCESS") {
      setUpdate(new Date());
    }
  };

  const apiErrorCallbackGetSubCategories = (err) => {
    console.log(err);
  };

  // const onToggle = (id, status) => {
  //   callMockAPI(
  //     "POST",
  //     "/updateSubCategory",
  //     {
  //       subCategoryId: id,
  //       status: status === "Active" ? "Inactive" : "Active",
  //     },
  //     apiSuccessCallBackUpdateSubCategory
  //   );
  // };

  // const onDelete = (id) => {
  //   callMockAPI(
  //     "POST",
  //     "/delete/subCategory",
  //     {
  //       subCategoryId: [id],
  //     },
  //     apiSuccessCallBackUpdateSubCategory
  //   );
  // };

  // const onDeleteAll = () => {
  //   callMockAPI(
  //     "POST",
  //     "/delete/subCategory",
  //     {
  //       subCategoryId: selected,
  //     },
  //     apiSuccessCallBackUpdateSubCategory
  //   );
  // };

  useEffect(() => {
    callAPI(
      "POST",
      getSubCategories,
      { categoryId: categoryId },
      apiSuccessCallbackGetSubCategories,
      apiErrorCallbackGetSubCategories
    );
  }, [update, categoryId]);

  if (data.loading === true) {
    return null;
  }

  return (
    <>
      <Header
        title="Sub Categories"
        rightLinks={
          <>
            <Button
              color="primary"
              startIcon={<AddIcon />}
              className={classes.space}
              onClick={handleDialog}
            >
              Add Sub Category
            </Button>
            <Button
              color="danger"
              // onClick={onDeleteAll}
              startIcon={<DeleteIcon />}
              disabled={selected.length > 0 ? false : true}
            >
              Delete
            </Button>
          </>
        }
      />
      <Card>
        {dialog ? (
          <AddSubCategory
            open={dialog}
            handleClose={handleDialog}
            setUpdate={setUpdate}
            categoryId={categoryId}
          />
        ) : null}
        <TableContainer>
          <Table>
            <TableHead>
              <Tablerow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </Tablerow>
            </TableHead>
            <TableBody>
              {data.subCategories
                .slice(page * 10, page * 10 + 10)
                .map((row, index) => {
                  return (
                    <Tablerow role="checkbox" key={index}>
                      <Tablecell align="left">
                        <Checkbox
                          color="primary"
                          onChange={() =>
                            handleSelectSubCategory(row.subCategoryId)
                          }
                          checked={selected.includes(row.subCategoryId)}
                          value={row.subCategoryId}
                        />
                      </Tablecell>
                      <Tablecell
                        align="left"
                        className={classes.cellLink}
                        onClick={() => handleOnClick(row)}
                      >
                        {row.subCategoryId}
                      </Tablecell>
                      <Tablecell className={classes.tabelCell} align="left">
                        {row.subCategoryName}
                      </Tablecell>

                      <Tablecell className={classes.tabelCell} align="left">
                        <IconButton
                          style={{ color: "#2699FB" }}
                          // onClick={() => onToggle(row.subCategoryId, row.status)}
                        >
                          <EditIcon />
                        </IconButton>
                        <IconButton
                          style={{ color: "#2699FB" }}
                          // onClick={() => onDelete(row.subCategoryId)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      </Tablecell>
                    </Tablerow>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10]}
          component="div"
          count={data.subCategories.length}
          rowsPerPage={10}
          page={page}
          onChangePage={handleChangePage}
        />
      </Card>
    </>
  );
}

export default SubCategories;
