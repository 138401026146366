import Dashboard from "@material-ui/icons/Dashboard";
import DashboardPage from "views/Dashboard/Dashboard.js";
import Person from "@material-ui/icons/Person";

// Auth
import SignIn from "./views/UserManagement/Signinpage";
// Onboarding Approvals
import Approvals from "views/Dashboard/Approvals/Approvals";
import OnboardingDocumentApprovals from "views/Dashboard/Approvals/table/OnboardingDocumentApproval";
//Categories
import Categories from "views/Dashboard/Categories/Table/Categories";
import CreateCategory from "views/Dashboard/Categories/CreateCategory";
import CategoryDetails from "views/Dashboard/Categories/CategoryDetails";
// Subcategories
import SubcategoryDetails from "views/Dashboard/Categories/SubCategoryDetails";
//Skills
import Skills from "views/Dashboard/Categories/Table/Skills";
//Orders
import Orders from "views/Dashboard/Orders/Table/Orders";
import CreateOrder from "views/Dashboard/Orders/CreateOrder";
// Property Management
import ExistingProperty from "views/Dashboard/PropertyManagement/ExistingProperty";
import NewProperty from "views/Dashboard/PropertyManagement/NewProperty";
// B2C Customers
import CustomersB2C from "views/Dashboard/Customers/B2C Customer/table/CustomersB2C";
import CreateB2C from "views/Dashboard/Customers/B2C Customer/CreateB2C";
import UpdateB2CCustomer from "views/Dashboard/Customers/B2C Customer/UpdateB2CCustomer";
// B2B Customers
import CustomersB2B from "views/Dashboard/Customers/B2B Customer/table/CustomersB2B";
import CreateB2B from "views/Dashboard/Customers/B2B Customer/CreateB2B";
import UpdateB2BCustomer from "views/Dashboard/Customers/B2B Customer/UpdateB2BCustomer";
import SubCategoryDetails from "views/Dashboard/Categories/SubCategoryDetails";

// Website Leads
import WebsiteLeads from "views/Dashboard/WebsiteLeas/table/Leads";

const dashboardRoutes = [
  {
    path: "/dashboard",
    name: "Dashboard UI",
    icon: Dashboard,
    component: DashboardPage,
    layout: "/admin",
    render: true,
  },

  // Onboarding Documents Approval Routes
  {
    path: "/approvals/onboarding_approvals/:id",
    name: "Onboarding TaskAppoval",
    component: Approvals,
    layout: "/admin",
    render: false,
  },

  {
    parent: "Vendor Approvals",
    name: "Onboarding Document Approvals",
    path: "/approvals/onboarding_approvals",
    layout: "/admin",
    component: OnboardingDocumentApprovals,
    render: false,
  },

  {
    path: "/approvals",
    name: "Vendor Approvals",
    icon: Dashboard,
    layout: "/admin",
    type: "hierarchy",
    render: true,
    hierarchy: [
      {
        name: "Onboarding Document Approvals",
        layout: "/admin",
        path: "/approvals/onboarding_approvals",
      },
    ],
  },

  // Category Routes

  {
    path: "/categories/skills",
    name: "Skills",
    icon: Dashboard,
    component: Skills,
    layout: "/admin",
    render: false,
  },

  {
    path: "/categories/subcategories/:subcategoryId",
    name: "Subcategories",
    icon: Dashboard,
    component: SubCategoryDetails,
    layout: "/admin",
    render: false,
  },

  {
    path: "/categories/create_category",
    name: "Add Category",
    icon: Dashboard,
    component: CreateCategory,
    layout: "/admin",
    render: false,
  },
  {
    path: "/categories/:categoryId",
    name: "CategoryDetails",
    icon: Dashboard,
    component: CategoryDetails,
    layout: "/admin",
    render: false,
  },

  {
    path: "/categories",
    name: "Categories",
    icon: Dashboard,
    component: Categories,
    layout: "/admin",
    type: "hierarchy",
    render: true,
    hierarchy: [
      {
        name: "Skills",
        layout: "/admin",
        path: "/categories/skills",
      },
    ],
  },

  // Order  Routes

  {
    path: "/orders/new_order",
    name: "Create Order",
    icon: Dashboard,
    component: CreateOrder,
    layout: "/admin",
    render: false,
  },
  {
    path: "/orders",
    name: "Orders",
    icon: Dashboard,
    component: Orders,
    layout: "/admin",
    render: true,
  },

  // Customer Routes

  {
    name: "B2C Customers",
    path: "/customers/B2C/edit/:customer_id",
    layout: "/admin",
    component: UpdateB2CCustomer,
    render: false,
  },

  {
    name: "B2B Customers",
    path: "/customers/B2B/edit/:customer_id",
    layout: "/admin",
    component: UpdateB2BCustomer,
    render: false,
  },

  {
    path: "/customers/B2C/new_user",
    name: "Customer(B2C)",
    icon: Person,
    component: CreateB2C,
    render: false,
    layout: "/admin",
  },

  {
    path: "/customers/B2B/new_user",
    name: "Customer(B2B)",
    icon: Person,
    component: CreateB2B,
    render: false,
    layout: "/admin",
  },

  {
    name: "B2C Customers",
    path: "/customers/B2C",
    layout: "/admin",
    component: CustomersB2C,
    render: false,
  },

  {
    name: "B2B Customers",
    path: "/customers/B2B",
    layout: "/admin",
    component: CustomersB2B,
    render: false,
  },

  {
    path: "/customers",
    name: "Customers",
    icon: Dashboard,
    layout: "/admin",
    type: "hierarchy",
    render: true,
    hierarchy: [
      {
        parent: "Customers",
        name: "B2C Customers",
        layout: "/admin",
        path: "/customers/B2C",
      },
      {
        parent: "Customers",
        name: "B2B Customers",
        layout: "/admin",
        path: "/customers/B2B",
      },
    ],
  },

  {
    path: "/property_management/new_property",
    name: "Property Management",
    icon: Dashboard,
    component: NewProperty,
    layout: "/admin",
    render: false,
  },

  {
    path: "/property_management",
    name: "Property Management",
    icon: Dashboard,
    component: ExistingProperty,
    layout: "/admin",
    render: true,
  },

  // website leads routes

  {
    path: "/website_leads",
    name: "Website Leads",
    icon: Dashboard,
    component: WebsiteLeads,
    layout: "/admin",
    render: true,
  },

  //Auth Routes
  {
    path: "/login",
    name: "Login",
    icon: Dashboard,
    component: SignIn,
    layout: "/auth",
    render: true,
  },
];

export default dashboardRoutes;
