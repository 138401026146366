import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const Tablecell = withStyles((theme) => ({
  head: {
    backgroundColor: "#8BA8EA67",
    color: "#030303",
    font: "normal normal bold 16px/19px Helvetica Neue",
    borderBottom: "none",
    textAlign: "center",
  },
  body: {
    font: "normal normal normal 14px/16px Helvetica Neue",
    opacity: 1,
    textAlign: "center",
    color: "#030303",
    padding: 0,
  },
}))(TableCell);

export const Tablerow = withStyles((theme) => ({
  root: {
    backgroundColor: "FFFFFF",
    borderBottom: "none",
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  cellLink: {
    textDecoration: "underline",
    font: "normal normal 14px/16px Helvetica Neue",
    color: "#1D45EE",
    cursor: "pointer",
  },
  space: {
    marginRight: "10px",
  },
}));
