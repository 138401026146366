import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import PublishIcon from "@material-ui/icons/Publish";
import TextField from "Components/TextField/TextField";
import { useForm } from "react-hook-form";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { uploadDocuments, saveAadhaarCardDetails } from "Utils/ApiEndpoints";
import { callAPI, callDocumentUploadAPI } from "Utils/HttpUtil";

function AadhaarCardUpdate(props) {
  const classes = styles();
  const { aadharCardNumber, setUpdate, open, handleClose } = props;

  const [aadharFront, setaadharFront] = useState();
  const [aadharBack, setaadharBack] = useState();
  const username = localStorage.getItem("username");

  const { register, handleSubmit, errors } = useForm({
    defaultValues: {
      aadhaarnumber: aadharCardNumber,
    },
  });

  const onUploadSucessAadhaarFront = (res) => {
    if (res.data.message === "SUCCESS") {
      alert("Uploaded Successfully!!");
    } else {
      alert(res.data.message);
      setaadharFront(null);
    }
  };

  const onUploaderrorAadhaarFront = (err) => {
    console.log(err);
    setaadharFront(null);
  };

  const onUploadSucessAadhaarBack = (res) => {
    if (res.data.message === "SUCCESS") {
      alert("Uploaded Successfully!!");
    } else {
      alert(res.data.message);
      setaadharBack(null);
    }
  };

  const onUploaderrorAadhaarBack = (err) => {
    console.log(err);
    setaadharBack(null);
  };

  const uploadAadhaarFront = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setaadharFront(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("docType", "AadharCardFrontImage");
      formData.append("userName", username);
      callDocumentUploadAPI(
        "POST",
        uploadDocuments,
        formData,
        onUploadSucessAadhaarFront,
        onUploaderrorAadhaarFront
      );
    }
  };

  const uploadAadhaarBack = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setaadharBack(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
      const formData = new FormData();
      formData.append("file", file);
      formData.append("docType", "AadharCardBackImage");
      formData.append("userName", username);
      callDocumentUploadAPI(
        "POST",
        uploadDocuments,
        formData,
        onUploadSucessAadhaarBack,
        onUploaderrorAadhaarBack
      );
    }
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated Successfully!");
      handleClose();
    } else {
      alert(responseData.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const updateAadhaarCardDetails = async (details) => {
    console.log(details);
    await callAPI(
      "POST",
      saveAadhaarCardDetails,
      {
        userName: username,
        personalDocumentsDTO: {
          aadharCardNumber: details.aadhaarnumber,
        },
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  console.log("render chec");

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update Aadhar Card Details
        </Typography>
      </DialogTitle>
      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => updateAadhaarCardDetails(data))}>
          <GridContainer alignItems="flex-end">
            <GridItem xs={12} md={5}>
              <TextField
                name="aadhaarnumber"
                label="Aadhaar Card Number"
                variant="outlined"
                fullWidth
                className={classes.padding}
                inputRef={register({
                  required: "Aadhaar card number is required.",
                  pattern: {
                    value: /[0-9]/,
                    message: "Invalid aadhaar card number ",
                  },
                })}
                inputProps={{
                  maxLength: 12,
                }}
                error={Boolean(errors.aadhaarnumber)}
                helperText={errors.aadhaarnumber?.message}
              />
              <Typography className={classes.imgName}>
                Aadhar Card Front Image
              </Typography>

              <div className={classes.imgContainer}>
                {aadharFront ? (
                  <img src={aadharFront} alt="..." className={classes.img} />
                ) : (
                  <>
                    <PublishIcon className={classes.imgIcon} />
                    <Typography>Upload Image</Typography>
                  </>
                )}
              </div>
              <div className={classes.input}>
                <input
                  accept="image/jpeg"
                  className={classes.input}
                  name="aadhaarfront"
                  multiple
                  type="file"
                  ref={register({
                    required: "Category image is required",
                  })}
                  onChange={uploadAadhaarFront}
                />
                {errors.aadhaarback && (
                  <Typography align="left" className={classes.error}>
                    Aadhaar card front image is required
                  </Typography>
                )}
              </div>
            </GridItem>
            <GridItem xs={12} md={5}>
              <Typography className={classes.imgName}>
                Aadhar Card Back Image
              </Typography>

              <div className={classes.imgContainer}>
                {aadharBack ? (
                  <img src={aadharBack} alt="..." className={classes.img} />
                ) : (
                  <>
                    <PublishIcon className={classes.imgIcon} />
                    <Typography>Upload Image</Typography>
                  </>
                )}
              </div>
              <div className={classes.input}>
                <input
                  accept="image/jpeg"
                  className={classes.input}
                  multiple
                  type="file"
                  name="aadhaarback"
                  ref={register({
                    required: " Aadhaar card back image is required",
                  })}
                  onChange={uploadAadhaarBack}
                />
                {errors.aadhaarback && (
                  <Typography align="left" className={classes.error}>
                    Aadhaar card back image is required
                  </Typography>
                )}
              </div>
            </GridItem>
          </GridContainer>
          <DialogActions className={classes.dialogAction}>
            <Button type="submit" color="primary">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default AadhaarCardUpdate;
