import { makeStyles, withStyles } from "@material-ui/core/styles";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";

export const Tablecell = withStyles((theme) => ({
  head: {
    backgroundColor: "#F2F2F4",
    color: "#A09B9B",
    font: "normal normal bold 16px/19px Helvetica Neue",
    borderBottom: "none",
    textAlign: "center",
  },
  body: {
    font: "normal normal bold 12px/15px Helvetica Neue",
    opacity: 1,
    borderBottom: "none",
    color: (props) => (props.link ? "#1D45EE" : "#A09B9B"),
    textAlign: "center",
  },
}))(TableCell);

export const Tablerow = withStyles((theme) => ({
  root: {
    backgroundColor: "FFFFFF",
    borderBottom: "none",
    textAlign: "center",
  },
}))(TableRow);

export const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    "& .MuiPaginationItem-icon": {
      backgroundColor: "green",
    },
  },

  toogleButton: {
    width: "auto",
    marginRight: 10,
    height: "50px",
    color: "#070606",
    backgroundColor: "#DAEBFA",
    borderRadius: 0,
    boxShadow: "none",
    cursor: "pointer",
    textTransform: "none",
    "&:hover,&:focus": {
      backgroundColor: "#FFFFFF",
    },
    font: "normal normal bold 16px/19px Helvetica Neue",
    wordWrap: "break-word",
    [theme.breakpoints.down("sm")]: {
      marginBottom: 10,
    },
  },
  buttonPreview: {
    font: "normal normal bold 10px/15px Helvetica Neue",
    borderRadius: "12px",
    height: "23px",
    background: "#F8700BAA",
    color: "#F8F4F4",
    textTransform: "none",
    width: "79px",
    marginRight: 10,
    border: "1px solid #F8700BB5",
    "&:hover": {
      backgroundColor: "#F8700BAA",
    },
    [theme.breakpoints.down("sm")]: {
      marginBottom: "5px",
    },
  },
  buttonReject: {
    font: "normal normal bold 10px/15px Helvetica Neue",
    borderRadius: "12px",
    height: "23px",
    background: "#F52C2CAD",
    color: "#F8F4F4",
    textTransform: "none",
    width: "79px",
    border: "1px solid #F52C2CBF",
    "&:hover": {
      backgroundColor: "#F52C2CAD",
    },
  },

  grow: {
    backgroundColor: "#ffffff",
    flexGrow: 1,
    padding: 20,
  },

  search: {
    position: "relative",
    borderRadius: theme.shape.borderRadius,
    border: "1px solid #DBD8D8",
    marginRight: theme.spacing(2),
    marginLeft: 0,

    height: "47px",
    backgroundColor: "#FFFFFF",
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: "100%",
    position: "absolute",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#918F8F",
  },
  inputRoot: {
    display: "flex",
    font: "normal normal bold 16px/19px Helvetica Neue",
    color: "#A09B9B",
    opacity: 1,
  },
  inputInput: {
    paddingTop: "15px",
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
  },
  filterbtn: {
    height: "47px",
    border: " 1px solid #DBD8D8",
    width: "160px",
    font: " normal normal bold 16px/19px Helvetica Neue",
    color: "#A09B9B",
    textTransform: "none",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },
  appBar: {
    backgroundColor: "white",
    boxShadow: "none",
  },
  count: {
    marginLeft: 10,
    font: "normal normal bold 13px/17px Helvetica Neue",
    color: "#FAF5F5",
    opacity: 1,
    border: "1px solid #56ADF8",
    borderRadius: "21px",
    background: "#3B89F4",
    width: "40px",
  },
  cellLink: {
    textDecoration: "underline",
    font: "normal normal bold 12px/15px Helvetica Neue",
  },

  btnfilterBackground: {
    backgroundColor: "#FFFFFF",
    "&:hover": {
      backgroundColor: "#FFFFFF",
      boxShadow: "none",
    },
  },

  noResultText: {
    textAlign: "center",
    padding: 40,
  },
}));
