import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/sectionStyle";
import PersonalDetailsUpdate from "Components/Dialogs/approvals/PersonalDetailsUpdate";
import Tablerow from "Components/Table/Tablerow";

function PersonalDetails(props) {
  const { personalDetails, userId, setUpdate } = props;
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  const parseDOB = {
    date: ("0" + new Date(personalDetails?.dob).getDate()).slice(-2),
    month: ("0" + (new Date(personalDetails?.dob).getMonth() + 1)).slice(-2),
    year: new Date(personalDetails?.dob).getFullYear(),
  };

  console.log(personalDetails?.dob.slice(-2));

  const handleClose = () => {
    setOpen(false);
  };

  if (!personalDetails) {
    return null;
  }

  return (
    <>
      <CardHeader
        className={classes.cardHeader}
        action={
          <IconButton aria-label="settings" onClick={() => setOpen(true)}>
            <EditIcon />
          </IconButton>
        }
        title="Personal Details"
        disableTypography
      />
      {open ? (
        <PersonalDetailsUpdate
          open={open}
          handleClose={handleClose}
          personalDetails={personalDetails}
          userId={userId}
          setUpdate={setUpdate}
        />
      ) : null}

      <Card className={classes.root}>
        <GridContainer>
          <GridItem xs={12}>
            <div className={classes.columnCount}>
              <Tablerow name="User Id" value={personalDetails.userId} />
              <Tablerow
                name="Name"
                value={`${personalDetails.firstName} ${personalDetails.lastName}`}
              />
              <Tablerow
                name="Date of Birth"
                value={`${parseDOB.date}/${parseDOB.month}/${parseDOB.year}`}
              />
              <Tablerow name=" Address" value={personalDetails.postalAddress} />
              <Tablerow name=" Email" value={personalDetails.email} />
              <Tablerow name=" Number" value={personalDetails.cellPhone} />
              <Tablerow name=" Gender" value={personalDetails.gender} />
              <Tablerow
                name=" City"
                value={personalDetails.cityDTO?.cityName}
              />
            </div>
          </GridItem>
        </GridContainer>
      </Card>
    </>
  );
}

export default PersonalDetails;
