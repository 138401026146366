import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/categories/tableStyle";
import { useHistory } from "react-router-dom";
import { callAPI } from "Utils/HttpUtil";
import { getSkills } from "Utils/ApiEndpoints";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Button from "Components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import IconButton from "@material-ui/core/IconButton";
import AddSkill from "Components/Dialogs/categories/addSkill";

export default function Skills() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    skills: [],
    loading: true,
  });
  const [searchInput, setSearchInput] = useState("");
  const [dialog, setDialog] = useState(false);
  const [update, setUpdate] = useState();

  const handleDialog = () => {
    setDialog(!dialog);
  };

  const headCells = [
    { label: "Skill Name" },
    { label: "Status" },
    { label: "Action" },
  ];

  const history = useHistory();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const apiSuccessCallback = (responseData) => {
    console.log(responseData);
    if (responseData.data.message === "SUCCESS") {
      setData({
        skills: responseData.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallback = (err) => {
    setData({
      skills: [],
      loading: false,
    });
  };

  const onClick = (id) => {
    history.push(`/admin/categories/${id}`);
  };

  useEffect(() => {
    callAPI("POST", getSkills, {}, apiSuccessCallback, apiErrorCallback);
  }, [update]);

  if (data.loading === true) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div align="right">
        <Button
          disableElevation
          disableTouchRipple
          disableRipple
          startIcon={<AddIcon />}
          color="primary"
          className={classes.appBarBtn}
          onClick={() => handleDialog()}
        >
          Add Skill
        </Button>
      </div>

      <AddSkill
        open={dialog}
        handleClose={handleDialog}
        setUpdate={setUpdate}
      />

      <div className={classes.grow}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Skills"
                onChange={(e) => setSearchInput(e.target.value)}
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCells.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.skills.length > 0
                ? data.skills
                    .filter((item) => {
                      if (searchInput === "") {
                        return item;
                      } else if (
                        item.skillName
                          .toLowerCase()
                          .includes(searchInput.toLowerCase())
                      ) {
                        return item;
                      } else {
                        return null;
                      }
                    })
                    .slice(page * 10, page * 10 + 10)
                    .map((row, index) => {
                      return (
                        <Tablerow role="checkbox" key={index}>
                          <Tablecell
                            align="left"
                            className={classes.cellLink}
                            onClick={() => onClick(row.categoryId)}
                          >
                            {row.skillId}
                          </Tablecell>
                          <Tablecell className={classes.tabelCell} align="left">
                            {row.skillName}
                          </Tablecell>

                          <Tablecell className={classes.tabelCell} align="left">
                            <IconButton
                              style={{ color: "#2699FB" }}
                              // onClick={() => onToggle(row.subCategoryId, row.status)}
                            >
                              <EditIcon />
                            </IconButton>
                            <IconButton
                              style={{ color: "#2699FB" }}
                              // onClick={() => onDelete(row.subCategoryId)}
                            >
                              <DeleteIcon />
                            </IconButton>
                          </Tablecell>
                        </Tablerow>
                      );
                    })
                : null}
            </TableBody>
          </Table>
          {data.skills.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data.skills.length}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : null}
        </TableContainer>
        {!data.skills.length > 0 ? (
          <div className="noResultText">0 records found</div>
        ) : null}
      </Paper>
    </div>
  );
}
