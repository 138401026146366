import React, { useState, useEffect } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Button from "Components/CustomButtons/CustomButton";
import Typography from "@material-ui/core/Typography";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/skillsStyle";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import Card from "Components/Card/Card";
import UpdateUserSkills from "Components/Dialogs/approvals/UpdateUserSkills";
import { callAPI } from "Utils/HttpUtil";
import {
  getCategoryBasedSkills,
  updateUserDocuments,
} from "Utils/ApiEndpoints";

function SkillsDetails(props) {
  const classes = useStyles();
  const { userSkillsDTO, setUpdate, userCategories, userId } = props;
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState({
    categorySkills: [],
    loading: true,
  });
  const selectedUserSkills = userSkillsDTO.map((skill) => skill.skillId);

  const categoryNames = [
    ...new Set(data.categorySkills?.map((prop) => prop.categoryName)),
  ];

  const categoryIds = userCategories.map((item) => item.categoryId);

  const handleClose = () => {
    setDialog(false);
  };

  const handleOnEdit = () => {
    setDialog(true);
  };

  const apiSuccessCallbackUpdateStatus = (res) => {
    if (res.status === 200) {
      alert("Updated Successfully!");
      setUpdate(new Date());
    } else {
      alert(res.data.message);
    }
  };
  const apiErrorCallbackUpdateStatus = (err) => {
    alert("Internal error please try after sometime");
    console.log(err);
  };

  const updateStatus = async (e) => {
    const status = e.currentTarget.name;
    let userSkillsIds = [];
    await userSkillsDTO.map((item) => {
      if (
        item.categoryName === e.currentTarget.value &&
        item.status === "Verification" &&
        selectedUserSkills.includes(item.skillId)
      ) {
        return userSkillsIds.push(item.userSkillId);
      } else {
        return null;
      }
    });

    await callAPI(
      "POST",
      updateUserDocuments,
      {
        userId: userId,
        status: status,
        model: "userSkills",
        userSkillId: userSkillsIds,
      },
      apiSuccessCallbackUpdateStatus,
      apiErrorCallbackUpdateStatus
    );
  };

  const apiSuccessCallbackgetCategories = async (res) => {
    let combinedArray = [];
    if (res.data.message === "SUCCESS") {
      res.data.responseData.map((item) =>
        combinedArray.push(...item.skillsDtoList)
      );
      setData({
        categorySkills: combinedArray,
        loading: false,
      });
    }
  };

  const apiErrorCallbackgetCategories = (err) => {
    console.log(err);
  };

  const Skills = (props) => {
    const { category } = props;
    const filterSkills = data.categorySkills.filter(
      (item) => item.categoryName === category
    );

    return (
      <>
        <GridContainer>
          <GridItem xs={12}>
            <FormGroup row>
              {filterSkills.map((prop, index) => {
                return (
                  <FormControlLabel
                    control={
                      <Checkbox
                        value={prop.skillId}
                        name={prop.skillName}
                        color="primary"
                        checked={selectedUserSkills.includes(prop.skillId)}
                      />
                    }
                    label={prop.skillName}
                    key={index}
                    {...props}
                  />
                );
              })}
            </FormGroup>
          </GridItem>
        </GridContainer>
      </>
    );
  };

  useEffect(() => {
    callAPI(
      "POST",
      getCategoryBasedSkills,
      { categoryIds: categoryIds },
      apiSuccessCallbackgetCategories,
      apiErrorCallbackgetCategories
    );
  }, []);

  if (data.loading === true) {
    return null;
  }

  return (
    <div>
      <Card style={{ overflowY: "scroll", maxHeight: "40vh" }}>
        <CardHeader
          className={classes.cardSubHeader}
          title="Selected Skills"
          action={
            <IconButton onClick={handleOnEdit}>
              <EditIcon />
            </IconButton>
          }
        />
        {dialog ? (
          <UpdateUserSkills
            open={dialog}
            handleClose={handleClose}
            selectAbleSkills={data.categorySkills}
            setUpdate={setUpdate}
            userId={userId}
            selectedUserSkills={selectedUserSkills}
          />
        ) : null}
        {categoryNames.map((prop, index) => {
          const isPresent = userSkillsDTO.filter(
            (item) =>
              item.categoryName === prop && item.status === "Verification"
          );
          return (
            <div key={index}>
              <Typography className={classes.typography}>{prop}</Typography>
              <div className={classes.skills}>
                <Skills category={prop} className={classes.taskContainer} />
                {isPresent.length > 0 ? (
                  <div className={classes.btnContainer}>
                    <Button
                      value={prop}
                      backgroundcolor="#11F79F48"
                      border="#0ED68A"
                      onClick={updateStatus}
                      name="Approved"
                    >
                      Approve
                    </Button>
                    <Button
                      value={prop}
                      backgroundcolor="#F7244D58"
                      border="#FC3D62D8"
                      onClick={updateStatus}
                      name="Rejected"
                    >
                      Reject
                    </Button>
                    <Button
                      value={prop}
                      backgroundcolor="#F8931D58"
                      border="#F08A14"
                      onClick={updateStatus}
                      disabled
                    >
                      Re-assign
                    </Button>
                  </div>
                ) : null}
              </div>
            </div>
          );
        })}
      </Card>
    </div>
  );
}

export default SkillsDetails;
