import React, { useState, useEffect } from "react";
import Card from "Components/Card/Card";
import CardBody from "Components/Card/CardBody";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import TextField from "Components/TextField/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import IconButton from "@material-ui/core/IconButton";
import SearchIcon from "@material-ui/icons/Search";
import useStyles from "assets/jss/material-dashboard-react/views/orders/createorderFormStyle";
import { MenuItem } from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { useHistory } from "react-router-dom";
import AlertMessage from "Components/Snackbar/SnackbarError";
import { callAPI } from "Utils/HttpUtil";
import validation from "./validation";
import {
  KeyboardTimePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";

import OrdersList from "./OrdersList";
import {
  getCities,
  getLookupValues,
  getSubCategories,
  getProblemTypes,
  getCityServiceArea,
  getCategories,
} from "Utils/ApiEndpoints";

import Users from "Components/Dialogs/orders/Users";

function CreateOrder() {
  const classes = useStyles();
  const history = useHistory();
  const [userDetails, setUserDetails] = useState({
    email: "",
    phoneNumber: "",
    customerName: "",
    customerType: "",
    cityId: "",
    pin: "",
    address: "",
    serviceArea: " ",
    dialog: true,
  });
  const [cities, setCities] = useState([]);
  const [source, setSource] = useState([]);
  const [categories, setCategories] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [problemTypes, setProblemTypes] = useState([]);
  const [serviceArea, setServiceArea] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [customErrors, setCustomErrors] = useState({});

  const [checkBox, setCheckBox] = useState({
    CheckboxPocDetails: true,
    CheckboxServiceLocation: true,
  });

  const [values, setValues] = useState({
    source: "",
    pocNumber: "",
    pocName: "",
    serviceCity: "",
    long: "",
    lat: "",
    category: "",
    problemType: "",
    subCategory: "",
    description: "",
    scheduleDate: null,
    scheduleTime: null,
  });
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onCancel = () => {
    history.push("/admin/orders");
  };

  const handleSaveOrder = (e) => {
    e.preventDefault();
  };

  const handleAddCategories = (e) => {
    e.preventDefault();
    const errors = validation(values, userDetails, selectedImages, checkBox);
    setCustomErrors(errors);
    let category = {};
    if (Object.keys(errors).length === 0) {
      category.id =
        selectedCategories.length > 0 ? selectedCategories.length + 1 : 0;
      category.category = values.category;
      category.subcategory = values.subCategory;
      category.problemType = values.problemType;
      category.description = values.description;
      category.images = selectedImages;
      const existingProblemType = selectedCategories.filter(
        (item) => item.problemType === values.problemType
      );
      if (existingProblemType.length > 0) {
        seterror({
          error: true,
          message: "Problem type already added to the list!",
          key: Math.random(),
        });
      } else {
        setSelectedCategories((oldArray) => [...oldArray, category]);
        setSelectedImages([]);
        setValues({
          ...values,
          category: "",
          problemType: "",
          subCategory: "",
          description: "",
        });
        setSubCategories([]);
        setProblemTypes([]);
      }
    }
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const onChangeUserDetails = (e) => {
    setUserDetails({ ...values, [e.target.name]: e.target.value });
  };

  const handleCheckBoxChange = (e) => {
    if (e.currentTarget.name === "pocdetails") {
      setCheckBox({
        ...checkBox,
        CheckboxPocDetails: !checkBox.CheckboxPocDetails,
      });
    } else if (e.currentTarget.name === "servicelocation") {
      setCheckBox({
        ...checkBox,
        CheckboxServiceLocation: !checkBox.CheckboxServiceLocation,
      });
    }
  };

  const handleDialog = () => {
    setUserDetails({
      ...userDetails,
      dialog: !userDetails.dialog,
    });
  };

  const handleOnDeleteProblem = (id) => {
    setSelectedCategories(selectedCategories.filter((item) => item.id !== id));
  };

  const uploadFiles = (e) => {
    if (e.target.files) {
      const filesArray = Array.from(e.target.files).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImages((prevImages) => prevImages.concat(filesArray));
    }
  };

  const extractNumber = (string) => {
    const value = string.match(/(\d+)/);
    if (value) {
      return value[0] * 1;
    }
  };

  const apiSuccessCallBackGetCategories = async (res) => {
    if (res.data.message === "SUCCESS") {
      setCategories(await res.data.responseData);
    }
  };

  const apiSuccessCallBackGetSource = async (res) => {
    if (res.data.status === 200) {
      setSource(await res.data.responseData);
    }
  };
  const apiSuccessCallBackGetCities = async (res) => {
    if (res.data.message === "SUCCESS") {
      setCities(await res.data.responseData);
    }
  };

  const apiSuccessCallbackGetSubCategories = async (res) => {
    if (res.data.message === "SUCCESS") {
      setSubCategories(await res.data.responseData);
    }
  };

  const apiSuccessCallbackGetProblemType = async (res) => {
    if (res.data.message === "SUCCESS") {
      setProblemTypes(await res.data.responseData);
    }
  };

  const apiSuccessCallbackGetServiceArea = async (res) => {
    if (res.data.message === "SUCCESS") {
      setServiceArea(await res.data.responseData);
    }
  };

  useEffect(() => {
    callAPI("GET", getCities, {}, apiSuccessCallBackGetCities);
    callAPI("GET", getCategories, {}, apiSuccessCallBackGetCategories);
    callAPI(
      "POST",
      getLookupValues,
      { lookupType: "Source" },
      apiSuccessCallBackGetSource
    );
  }, []);

  useEffect(() => {
    if (values.category !== "") {
      callAPI(
        "POST",
        getSubCategories,
        { categoryId: extractNumber(values.category) },
        apiSuccessCallbackGetSubCategories
      );
    }
  }, [values.category]);

  useEffect(() => {
    if (values.subCategory !== "") {
      callAPI(
        "POST",
        getProblemTypes,
        { subCategoryId: extractNumber(values.subCategory) },
        apiSuccessCallbackGetProblemType
      );
    }
  }, [values.subCategory]);

  useEffect(() => {
    if (values.serviceCity !== "") {
      callAPI(
        "POST",
        getCityServiceArea,
        { cityId: values.serviceCity },
        apiSuccessCallbackGetServiceArea
      );
    }
  }, [values.serviceCity]);

  return (
    <>
      <Card>
        <CardBody>
          {error.error ? (
            <AlertMessage key={error.key} message={error.message} />
          ) : null}
          <div className={classes.header}>
            <div className={classes.backbtn}>
              <Button
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <Typography>
              <b>New Order</b>
            </Typography>
          </div>
          <form className={classes.form}>
            <Typography align="left">
              <b>Order Details</b>
            </Typography>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="email"
                  label="Customer Email"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton edge="end" onClick={handleDialog}>
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                    readOnly: true,
                  }}
                  margin="normal"
                  value={userDetails.email}
                  error={Boolean(customErrors.email)}
                  helperText={customErrors?.email}
                />
              </GridItem>
              {userDetails.dialog ? (
                <Users
                  open={userDetails.dialog}
                  onClose={handleDialog}
                  setUserDetails={setUserDetails}
                />
              ) : null}
              <GridItem xs={12} sm={6}>
                <TextField
                  name="phoneNumber"
                  margin="normal"
                  label="Customer Number"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={userDetails.phoneNumber}
                  error={Boolean(customErrors.phoneNumber)}
                  helperText={customErrors?.phoneNumber}
                />
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="customerName"
                  margin="normal"
                  label="Customer Name"
                  InputProps={{
                    readOnly: true,
                  }}
                  value={userDetails.customerName}
                  error={Boolean(customErrors.customerName)}
                  helperText={customErrors?.customerName}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  name="source"
                  margin="normal"
                  label="Source"
                  value={values.source}
                  onChange={onChange}
                  error={Boolean(customErrors.source)}
                  helperText={customErrors?.source}
                  select
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    select source
                  </MenuItem>
                  {source.map((item, index) => {
                    return (
                      <MenuItem value={item.lkp_id} key={index}>
                        {item.value}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </GridItem>
              <GridItem xs={12} md={6}>
                <TextField
                  margin="normal"
                  label="City"
                  value={userDetails.cityId}
                  select
                  error={Boolean(customErrors.city)}
                  helperText={customErrors?.city}
                  InputProps={{
                    readOnly: true,
                  }}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  <MenuItem value="" disabled>
                    select city
                  </MenuItem>
                  {cities.map((item, index) => {
                    return (
                      <MenuItem value={item.cityId} key={index}>
                        {item.cityName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </GridItem>
            </GridContainer>
            <div className={classes.sectionTitle}>
              <Typography align="left">
                <b>POC Details</b>
              </Typography>
            </div>
            {userDetails.customerType === "B2B" ? null : (
              <div>
                <div>
                  <FormControlLabel
                    classes={{ label: classes.checkboxLabel }}
                    control={
                      <Checkbox
                        checked={checkBox.CheckboxPocDetails}
                        onChange={handleCheckBoxChange}
                        color="primary"
                        name="pocdetails"
                      />
                    }
                    label="Self"
                  />
                </div>
              </div>
            )}
            {userDetails.customerType === "B2B" ||
            (userDetails.customerType === "B2C" &&
              checkBox.CheckboxPocDetails === false) ? (
              <GridContainer>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="POC Name"
                    name="pocName"
                    value={values.pocName}
                    onChange={onChange}
                    error={Boolean(customErrors.pocName)}
                    helperText={customErrors?.pocName}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="POC Number"
                    name="pocNumber"
                    value={values.pocNumber}
                    onChange={onChange}
                    error={Boolean(customErrors.pocNumber)}
                    helperText={customErrors?.pocNumber}
                  />
                </GridItem>
              </GridContainer>
            ) : null}

            <Typography align="left">
              <b>Service Location</b>
            </Typography>
            {userDetails.customerType === "B2B" ? null : (
              <div>
                <FormControlLabel
                  classes={{ label: classes.checkboxLabel }}
                  control={
                    <Checkbox
                      checked={checkBox.CheckboxServiceLocation}
                      onChange={handleCheckBoxChange}
                      color="primary"
                      name="servicelocation"
                    />
                  }
                  label="Self"
                />
              </div>
            )}

            {userDetails.customerType === "B2B" ||
            (userDetails.customerType === "B2C" &&
              checkBox.CheckboxServiceLocation === false) ? (
              <GridContainer>
                <GridItem xs={12} md={6}>
                  <TextField
                    name="serviceCity"
                    margin="normal"
                    label="City"
                    value={values.serviceCity}
                    onChange={onChange}
                    select
                    error={Boolean(customErrors.serviceCity)}
                    helperText={customErrors?.serviceCity}
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        classes: { paper: classes.menuPaper },
                        getContentAnchorEl: null,
                      },
                    }}
                  >
                    <MenuItem value=" " disabled>
                      select city
                    </MenuItem>
                    {cities.map((item, index) => {
                      return (
                        <MenuItem value={item.cityId} key={index}>
                          {item.cityName}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </GridItem>
                <GridItem xs={12} md={6}>
                  <TextField
                    margin="normal"
                    label="Service Area"
                    name="serviceArea"
                    value={userDetails.serviceArea}
                    onChange={onChangeUserDetails}
                    error={Boolean(customErrors.serviceArea)}
                    helperText={customErrors?.serviceArea}
                    select
                    SelectProps={{
                      MenuProps: {
                        anchorOrigin: {
                          vertical: "bottom",
                          horizontal: "left",
                        },
                        classes: { paper: classes.menuPaper },
                        getContentAnchorEl: null,
                      },
                    }}
                  >
                    <MenuItem value="" disabled>
                      select service area
                    </MenuItem>
                    {serviceArea.map((item, index) => {
                      return (
                        <MenuItem value={item.serviceAreaId} key={index}>
                          {item.name}
                        </MenuItem>
                      );
                    })}
                  </TextField>
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="Address"
                    name="address"
                    value={userDetails.address}
                    onChange={onChangeUserDetails}
                    error={Boolean(customErrors.address)}
                    helperText={customErrors?.address}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="Pin Code"
                    name="pin"
                    value={userDetails.pin}
                    onChange={onChangeUserDetails}
                    error={Boolean(customErrors.pin)}
                    helperText={customErrors?.pin}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="Latitude"
                    name="lat"
                    value={values.lat}
                    onChange={onChange}
                  />
                </GridItem>
                <GridItem xs={12} sm={6}>
                  <TextField
                    margin="normal"
                    label="Longitude"
                    name="long"
                    value={values.long}
                    onChange={onChange}
                  />
                </GridItem>
              </GridContainer>
            ) : null}
            <Typography align="left">
              <b>Service Appointment</b>
            </Typography>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    autoOk
                    disablePast
                    margin="normal"
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Schedule Date"
                    format="MM/dd/yyyy"
                    value={values.scheduleDate}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(date) =>
                      setValues({ ...values, scheduleDate: date })
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    error={Boolean(customErrors.scheduleDate)}
                    helperText={customErrors?.scheduleDate}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardTimePicker
                    autoOk
                    margin="normal"
                    fullWidth
                    variant="inline"
                    inputVariant="outlined"
                    label="Schedule Date"
                    mask="__:__ _M"
                    minutesStep={30}
                    value={values.scheduleTime}
                    InputAdornmentProps={{ position: "end" }}
                    onChange={(time) =>
                      setValues({ ...values, scheduleTime: time })
                    }
                    InputProps={{
                      readOnly: true,
                    }}
                    error={Boolean(customErrors.scheduleTime)}
                    helperText={customErrors?.scheduleTime}
                  />
                </MuiPickersUtilsProvider>
              </GridItem>
            </GridContainer>

            <div className={classes.sectionTitle}>
              <Typography align="left">
                <b>Service Details</b>
              </Typography>
            </div>
            <GridContainer>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="category"
                  margin="normal"
                  label="Category"
                  value={values.category}
                  onChange={onChange}
                  select
                  error={Boolean(customErrors.category)}
                  helperText={customErrors?.category}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  <MenuItem value=" " disabled>
                    select category
                  </MenuItem>
                  {categories.map((item, index) => {
                    return (
                      <MenuItem
                        value={`${item.categoryName} ${item.categoryId}`}
                        key={index}
                      >
                        {item.categoryName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="subCategory"
                  margin="normal"
                  label="Sub Category"
                  value={values.subCategory}
                  onChange={onChange}
                  select
                  error={Boolean(customErrors.subCategory)}
                  helperText={customErrors?.subCategory}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  <MenuItem value=" " disabled>
                    select subcategory
                  </MenuItem>
                  {subCategories.map((item, index) => {
                    return (
                      <MenuItem
                        value={`${item.subCategoryName} ${item.subCategoryId}`}
                        key={index}
                      >
                        {item.subCategoryName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </GridItem>
              <GridItem xs={12} sm={6}>
                <TextField
                  name="problemType"
                  margin="normal"
                  label="Problem Type"
                  value={values.problemType}
                  onChange={onChange}
                  select
                  error={Boolean(customErrors.problemType)}
                  helperText={customErrors?.problemType}
                  SelectProps={{
                    MenuProps: {
                      anchorOrigin: {
                        vertical: "bottom",
                        horizontal: "left",
                      },
                      classes: { paper: classes.menuPaper },
                      getContentAnchorEl: null,
                    },
                  }}
                >
                  <MenuItem value=" " disabled>
                    select problem type
                  </MenuItem>
                  {problemTypes.map((item, index) => {
                    return (
                      <MenuItem
                        value={`${item.problemTypeName} ${item.problemTypeId}`}
                        key={index}
                      >
                        {item.problemTypeName}
                      </MenuItem>
                    );
                  })}
                </TextField>
              </GridItem>

              <GridItem xs={12} sm={6}>
                <TextField
                  margin="normal"
                  label="Description"
                  name="description"
                  multiline
                  rows={2}
                  value={values.description}
                  onChange={onChange}
                  error={Boolean(customErrors.description)}
                  helperText={customErrors?.description}
                />
              </GridItem>
              <GridItem xs={12} sm={12}>
                <div>
                  <input
                    accept="image/*"
                    id="contained-button-file"
                    multiple
                    type="file"
                    name="images"
                    onClick={uploadFiles}
                    style={{ display: "none" }}
                  />
                  <label htmlFor="contained-button-file">
                    <Button
                      variant="contained"
                      color="secondary"
                      style={{
                        textTransform: "none",
                      }}
                      component="span"
                    >
                      Upload Issue Pics
                    </Button>
                  </label>
                  {customErrors.image ? (
                    <span className={classes.error}>
                      Issue images are required
                    </span>
                  ) : null}
                </div>
                <Button
                  color="secondary"
                  style={{ textTransform: "none", marginBottom: "30px" }}
                  disableElevation
                  disableTouchRipple
                  type="submit"
                  onClick={handleAddCategories}
                >
                  Add
                </Button>
              </GridItem>
            </GridContainer>
          </form>
          <OrdersList
            selectedCategories={selectedCategories}
            onDelete={handleOnDeleteProblem}
          />
          <div align="center" style={{ marginTop: "30px" }}>
            <Button
              disabled={selectedCategories.length > 0 ? false : true}
              color="primary"
              onClick={handleSaveOrder}
            >
              Create Order
            </Button>
          </div>
        </CardBody>
      </Card>
    </>
  );
}

export default CreateOrder;
