import React from "react";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(AlertProps) {
  return <MuiAlert elevation={6} variant="filled" {...AlertProps} />;
}

function AlertMessage({ message, color }) {
  const [open, setOpen] = React.useState(true);

  const vertical = "top";
  const horizontal = "right";

  return (
    <div>
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={6000}
        onClose={() => setOpen(false)}
      >
        <Alert onClose={() => setOpen(false)} severity={color}>
          {message}
        </Alert>
      </Snackbar>
    </div>
  );
}
export default AlertMessage;
