const validation = (values, userDetails, selectedImages, checkBox) => {
  let errors = {};
  if (!values.category) {
    errors.category = "Category is required";
  }

  if (!values.subCategory) {
    errors.subCategory = "Subcategory is required";
  }

  if (!values.problemType) {
    errors.problemType = "Problem type is required";
  }

  if (!values.serviceCity && checkBox.CheckboxServiceLocation === false) {
    errors.serviceCity = "Service city is required";
  }

  if (!userDetails.pin && checkBox.CheckboxServiceLocation === false) {
    errors.pin = "Pincode is required";
  }

  if (!userDetails.address && checkBox.CheckboxServiceLocation === false) {
    errors.address = "Address is required";
  }

  if (!userDetails.serviceArea && checkBox.CheckboxServiceLocation === false) {
    errors.serviceArea = "Service area is required";
  }
  if (!values.source) {
    errors.source = "Source is required";
  }

  if (!values.scheduleDate) {
    errors.scheduleDate = "Schedule date is required";
  }

  if (!values.scheduleTime) {
    errors.scheduleTime = "Schedule time is required";
  }

  if (checkBox.CheckboxPocDetails === false && !values.pocNumber) {
    errors.pocNumber = "POC Number is required";
  }

  if (checkBox.CheckboxPocDetails === false && !values.pocName) {
    errors.pocName = "POC Name is required";
  }

  //   if (!values.pocName) {
  //     errors.pocName = "POC Name is required";
  //   }

  if (!values.description) {
    errors.description = "Description is required";
  }

  if (selectedImages.length === 0) {
    errors.image = "Issue images are required";
  }

  if (!userDetails.email) {
    errors.email = "Email is required";
  }

  if (!userDetails.phoneNumber) {
    errors.phoneNumber = "Phone number is required";
  }

  if (!userDetails.customerName) {
    errors.customerName = "Customer name is required";
  }

  return errors;
};

export default validation;
