import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Button from "Components/CustomButtons/Button";
import SubCategory from "./SubCategory";
import ProblemTypes from "./ProblemTypes";

function SubCategoryDetails() {
  const params = useParams();
  const history = useHistory();
  const subcategoryId = params.subcategoryId;

  return (
    <div>
      <Button color="secondary" onClick={() => history.goBack()}>
        Back
      </Button>
      <SubCategory subcategoryId={subcategoryId} />
      <ProblemTypes subcategoryId={subcategoryId} />
    </div>
  );
}

export default SubCategoryDetails;
