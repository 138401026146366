import React, { useState, useEffect } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import TextField from "Components/TextField/TextField";
import { useForm, Controller } from "react-hook-form";
import MenuItem from "@material-ui/core/MenuItem";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { callAPI } from "Utils/HttpUtil";
import { getCities, updateServiceAreaDetails } from "Utils/ApiEndpoints";

function ServiceAreaUpdate(props) {
  const classes = styles();
  const { open, handleClose, serviceAreas, setUpdate } = props;
  const [cities, setCities] = useState([]);

  const { register, handleSubmit, errors, control } = useForm({
    defaultValues: {
      city: "",
      area: "",
      locationLink: "",
      latitude: "",
      longitude: "",
    },
  });

  const apiSuccessCallback = (res) => {
    if (res.data.message === "ServiceArea Details Updated") {
      setUpdate(new Date());
      alert("Update Successfully!");
      handleClose();
    } else {
      alert(res.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
    alert("Internal error please try after sometime");
  };

  const updateServiceArea = (details) => {
    const getAreaName = serviceAreas.filter(
      (item) => item.serviceAreaId === details.area
    );
    const area = getAreaName.map((item) => item.name);

    callAPI(
      "POST",
      updateServiceAreaDetails,
      {
        cityId: details.city,
        longitude: details.longitude,
        latitude: details.latitude,
        name: area[0],
        serviceAreaId: details.area,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  const apiSuccessCallbackgetCities = (res) => {
    if (res.data.message === "SUCCESS") {
      setCities(res.data.responseData);
    }
  };

  const apiErrorCallbackgetCities = (err) => {
    console.log(err);
  };

  useEffect(() => {
    callAPI(
      "GET",
      getCities,
      {},
      apiSuccessCallbackgetCities,
      apiErrorCallbackgetCities
    );
  }, []);

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>
          Update Service Location
        </Typography>
      </DialogTitle>

      <DialogContent style={{ marginTop: 30 }}>
        <form onSubmit={handleSubmit((data) => updateServiceArea(data))}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Controller
                name={"city"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      label="City"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      className={classes.padding}
                      inputRef={ref}
                      select
                      error={Boolean(errors.city)}
                      helperText={errors.city?.message}
                    >
                      <MenuItem value="" disabled />
                      {cities.map((item, index) => {
                        return (
                          <MenuItem value={item.cityId} key={index}>
                            {item.cityName}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }}
                rules={{ required: "Select the city" }}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Controller
                name={"area"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      label="Area Name"
                      value={value}
                      onChange={(event) => onChange(event.target.value)}
                      className={classes.padding}
                      inputRef={ref}
                      defaultValue=""
                      select
                      error={Boolean(errors.area)}
                      helperText={errors.area?.message}
                    >
                      <MenuItem value="" disabled />

                      {serviceAreas.map((item, index) => {
                        return (
                          <MenuItem value={item.serviceAreaId} key={index}>
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </TextField>
                  );
                }}
                rules={{ required: "Select the service area" }}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <TextField
                name="locationLink"
                label="Location Link"
                className={classes.padding}
                inputRef={register({
                  required: "Location Link is required.",
                })}
                error={Boolean(errors.locationLink)}
                helperText={errors.locationLink?.message}
              />
            </GridItem>

            <GridItem xs={12} md={6}>
              <TextField
                name="latitude"
                label="Latitude"
                className={classes.padding}
                inputRef={register({
                  required: "Latitude is required.",
                  pattern: {
                    value: /[0-9.]/,
                    message: "Invalid Latitude ",
                  },
                })}
                error={Boolean(errors.latitude)}
                helperText={errors.latitude?.message}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <TextField
                name="longitude"
                label="Longitude"
                className={classes.padding}
                inputRef={register({
                  required: "Longitude is required.",
                  pattern: {
                    value: /[0-9.]/,
                    message: "Invalid Longitude",
                  },
                })}
                error={Boolean(errors.longitude)}
                helperText={errors.longitude?.message}
              />
            </GridItem>
          </GridContainer>
          <DialogActions className={classes.dialogAction}>
            <Button color="primary" type="submit">
              Save
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default ServiceAreaUpdate;
