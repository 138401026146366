import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: "0px",
    marginTop: "0px",
  },

  status: {
    color: "#D51734",
    font: "normal normal bold 16px/19px Helvetica Neue",
    marginRight: 30,
  },
  btnContainer: {
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: 10,
  },

  skillsContainer: {
    padding: 20,
    font: "normal normal normal 16px/18px Helvetica Neue",
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },
  typography: {
    marginLeft: 30,
    font: "normal normal bold 20px/25px Helvetica Neue",
    textTransform: "capitalize",
    marginTop: "20px",
  },
  taskContainer: {
    font: "normal normal normal 16px/18px Helvetica Neue",
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },

  skills: {
    backgroundColor: "#E2DFDF45",
    marginLeft: 20,
    paddingLeft: 20,
  },

  cardHeader: {
    backgroundColor: "#F2F2F4",
    font: "normal normal bold 24px/29px Helvetica Neue",
    color: "#070606",
    opacity: 1,
  },
  cardSubHeader: {
    backgroundColor: "#B4D3F745",
  },
}));
export default useStyles;
