import mock from "../../../utils/mock";
import Data from "MockServer/db.json";

let { retriveUserDocuments } = Data;

mock.onPost("/getUsersDocumentsByDocumentStatus").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const status = data.status;
      const responseData = retriveUserDocuments.filter(
        (item) => item.userDetails.userDocumentStatus === status
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/getUsersDocumentsByUsername").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const username = data.username;
      const responseData = retriveUserDocuments.filter(
        (item) => item.userDetails.cellPhone === username
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
