import mock from "../../utils/mock";
import Data from "MockServer/db.json";

let { categories } = Data;

mock.onPost("/categories").reply(() => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const responseData = categories;
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/getCategory").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const categoryId = parseInt(data.categoryId);
      const responseData = categories.filter(
        (item) => item.categoryId === categoryId
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/getCityBasedCategories").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const cityid = data.cityId;
      let responseData = [];
      categories.map((item) =>
        item.cityIds.forEach((id) => {
          if (id === cityid) {
            responseData.push(item);
          } else {
            return null;
          }
        })
      );
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});

mock.onPost("/catetory/create").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      categories.push({
        categoryId: categories.length + 1,
        ...data,
      });

      resolve([200, { message: "SUCCESS", responseData: categories }]);
    }, 1000);
  });
});

mock.onPost("/updateCategory").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      categories = categories.map((item) => {
        if (item.categoryId.toString() === data.categoryId.toString()) {
          item = {
            ...item,
            categoryName: data.categoryName,
            categoryDescription: data.categoryDescription,
            categoryImage: data.categoryImage,
            cityIds: data.cityIds,
          };
        }
        return item;
      });
      const responseData = categories.filter(
        (item) => item.categoryId.toString() === data.categoryId.toString()
      );

      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
