import React, { useState, useEffect } from "react";
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Card from "Components/Card/Card.js";
import CardBody from "Components/Card/CardBody.js";
import Button from "Components/CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "assets/jss/material-dashboard-react/views/customerFormStyle";
import { useForm, Controller } from "react-hook-form";
import { MenuItem } from "@material-ui/core";
import Documentcard from "./DocumentUpload";
import { useHistory, useParams } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { callDocumentUploadAPI, callAPI } from "Utils/HttpUtil";
import {
  createB2BCustomer,
  getCities,
  getLookupValues,
  fetchB2BCustomer,
} from "Utils/ApiEndpoints";
import AlertMessage from "Components/Snackbar/SnackbarError";
import NoImage from "assets/img/noimage.jpeg";

export default function UpdateB2BCustomer() {
  const classes = styles();
  const history = useHistory();
  const params = useParams();
  const customer_id = params.customer_id;
  const [source, setSource] = useState();
  const [cities, setCities] = useState();

  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const [values, setValues] = useState({
    loading: true,
  });

  const disableButton =
    values.gstnCopyInByte === null ||
    values.tinCopyInByte === null ||
    values.agreementCopyByte === null ||
    values.cancelledCheckInByte === null
      ? true
      : false;

  const { register, handleSubmit, errors, control, reset } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      companyName: "",
      spocEmail: "",
      pocNumber: "",
      pocName: "",
      sourceId: "",
      type: "",
      address: "",
      area: "",
      pinCode: "",
      cityId: "",
      accountName: "",
      accountNumber: "",
      ifscCode: "",
      branchCode: "",
      gstnNumber: "",
      gstnCityId: "",
      tinNumber: "",
      agreementDate: "",
      agreementCopy: "",
      agreementCopyByte: "",
      gstnCopy: "",
      gstnCopyInByte: "",
      tinCopy: "",
      tinCopyInByte: "",
      cancelledCheck: "",
      cancelledCheckInByte: "",
    },
  });

  const handleGstnUploadCopy = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setValues({
          ...values,
          gstnCopy: file,
          gstnCopyInByte: reader.result.split(",")[1],
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleTinUploadCopy = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setValues({
          ...values,
          tinCopy: file,
          tinCopyInByte: reader.result.split(",")[1],
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //

  const handleAgreementCopyUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setValues({
          ...values,
          agreementCopy: file,
          agreementCopyInByte: reader.result.split(",")[1],
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCancelledChequeUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setValues({
          ...values,
          cancelledCheck: file,
          cancelledCheckInByte: reader.result.split(",")[1],
        });
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onCancel = () => {
    history.push("/admin/customers/B2B");
  };

  const onApiSuccessGetCustomer = async (res) => {
    if (res.data.status === 200) {
      reset({
        ...(await res.data.responseData),
        agreementDate: `${new Date(
          res.data.responseData.agreementDate
        ).getFullYear()}-${(
          "0" +
          (new Date(res.data.responseData.agreementDate).getMonth() + 1)
        ).slice(-2)}-${(
          "0" + new Date(res.data.responseData.agreementDate).getDate()
        ).slice(-2)}`,
      });
      setValues({
        ...(await res.data.responseData),
        loading: false,
      });
    }
  };

  const onApiErrorGetCustomer = (err) => {
    console.log(err.response);
  };

  const onApiSucessCallBackUpdateCustomer = (res) => {
    if (res.data.status === 200) {
      seterror({
        error: true,
        message: "Customer details updated successfully!",
        key: Math.random(),
        color: "success",
      });
    }
  };

  const onApiErrorCallBackUpdateCustomer = (err) => {
    seterror({
      error: true,
      message: err.response.data.error || err.response.data.message,
      key: Math.random(),
      color: "error",
    });
  };

  const onApiSucessCallGetCities = (res) => {
    if (res.data.status === 200) {
      setCities(res.data.responseData);
    }
  };

  const onApiErrorCallGetCities = (err) => {
    console.log(err);
  };

  const onApiSucessCallBackGetSource = (res) => {
    if (res.data.status === 200) {
      setSource(res.data.responseData);
    }
  };

  const onApiErrorCallBackGetSource = (err) => {
    console.log(err);
  };

  function b64toBlob(b64Data, contentType, sliceSize) {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let byteCharacters = atob(b64Data);
    let byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumbers);

      byteArrays.push(byteArray);
    }

    let blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }
  const onSubmit = (data) => {
    const contentType = "image/jpeg";
    const cancelledCheckCopy = b64toBlob(
      values.cancelledCheckInByte,
      contentType
    );
    const agreementCopy = b64toBlob(values.agreementCopyInByte, contentType);
    const tinCopy = b64toBlob(values.tinCopyInByte, contentType);
    const gstnCopy = b64toBlob(values.gstnCopyInByte, contentType);

    const formData = new FormData();
    formData.append("userId", values.userId);
    formData.append("companyName", data.companyName);
    formData.append("pocName", data.pocName);
    formData.append("spocEmail", data.spocEmail);
    formData.append("pocNumber", data.pocNumber);
    formData.append("sourceId", data.sourceId);
    formData.append("type", "B2B");
    formData.append("address", data.address);
    formData.append("area", data.area);
    formData.append("pinCode", data.pinCode);
    formData.append("cityId", data.cityId);
    // formData.append("addressId", values.addressId);
    formData.append("accountName", data.accountName);
    formData.append("accountNumber", data.accountNumber);
    formData.append("ifscCode", data.ifscCode);
    formData.append("branchCode", data.branchCode);
    formData.append("gstnNumber", data.gstnNumber);
    formData.append("gstnCityId", data.gstnCityId);
    formData.append("tinNumber", data.tinNumber);
    formData.append("agreementDate", `${data.agreementDate}T00:00:00.45+01:00`);
    formData.append("cancelledCheck", cancelledCheckCopy);
    formData.append("agreementCopy", agreementCopy);
    formData.append("tinCopy", tinCopy);
    formData.append("gstnCopy", gstnCopy);
    callDocumentUploadAPI(
      "POST",
      createB2BCustomer,
      formData,
      onApiSucessCallBackUpdateCustomer,
      onApiErrorCallBackUpdateCustomer
    );
  };

  const getAllDetails = async () => {
    await callAPI(
      "GET",
      getCities,
      {},
      onApiSucessCallGetCities,
      onApiErrorCallGetCities
    );

    await callAPI(
      "POST",
      getLookupValues,
      { lookupType: "Source" },
      onApiSucessCallBackGetSource,
      onApiErrorCallBackGetSource
    );

    await callAPI(
      "POST",
      `${fetchB2BCustomer}${customer_id}`,
      {},
      onApiSuccessGetCustomer,
      onApiErrorGetCustomer
    );
  };

  // console.log(values.agreementDate.split("T")[1]);

  useEffect(() => {
    getAllDetails();
  }, [customer_id]);

  if (values.loading === true) {
    return null;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardBody>
          {error.error ? (
            <AlertMessage
              key={error.key}
              message={error.message}
              color={error.color}
            />
          ) : null}
          <Typography variant="h6" align="center">
            <div align="left">
              <Button
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <b> Update Customer</b>
          </Typography>
          <Typography align="left">
            <b>Customer Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"companyName"}
                control={control}
                rules={{
                  required: "You must provide the company name!",
                  pattern: {
                    value: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
                    message: "You must provide a valid company name!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="companyName"
                      label="Company Name"
                      error={!!errors.companyName}
                      helperText={errors.companyName?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"pocName"}
                control={control}
                rules={{
                  required: "You must provide the POC name!",
                  pattern: {
                    value: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
                    message: "You must provide a valid POC name!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="pocName"
                      label="POC Name"
                      name="pocName"
                      error={!!errors.pocName}
                      helperText={errors.pocName?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"spocEmail"}
                control={control}
                rules={{
                  required: "You must provide the SPOC E-mail address!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "You must provide a valid SPOC E-mail address!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="spocEmail"
                      label="SPOC Email"
                      name="spocEmail"
                      error={!!errors.spocEmail}
                      helperText={errors.spocEmail?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"pocNumber"}
                control={control}
                rules={{
                  required: "You must provide the POC Number!",
                  pattern: {
                    value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                    message: "You must provide a valid POC Number!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      id="pocNumber"
                      label="POC Number"
                      name="pocNumber"
                      error={!!errors.pocNumber}
                      helperText={errors.pocNumber?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"sourceId"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="Source"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select source
                      </MenuItem>
                      {source
                        ? source.map((value, index) => {
                            return (
                              <MenuItem value={value.lkp_id} key={index}>
                                {value.value}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>

          <Typography align="left">
            <b>Company Address</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"address"}
                control={control}
                rules={{
                  required: "You must provide the address",
                  pattern: {
                    message: "You must provide a valid address",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Address"
                      error={!!errors.address}
                      helperText={errors.address?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"area"}
                control={control}
                rules={{
                  required: "You must provide the area",
                  pattern: {
                    message: "You must provide a valid area",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Area"
                      error={!!errors.area}
                      helperText={errors.area?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"pinCode"}
                control={control}
                rules={{
                  required: "You must provide the pin code!",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "You must provide a valid Pin Code!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Pincode"
                      error={!!errors.pinCode}
                      helperText={errors.pinCode?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"cityId"}
                control={control}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>

          <Typography align="left">
            <b>Company Account Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"accountName"}
                control={control}
                rules={{
                  required: "You must provide the account name!",
                  pattern: {
                    value: /^[a-zA-Z ]*$/,
                    message: "You must provide a valid account name!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Account Name"
                      error={!!errors.accountName}
                      helperText={errors.accountName?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"accountNumber"}
                control={control}
                rules={{
                  required: "You must provide the account Number!",
                  pattern: {
                    value: /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/,
                    message: "You must provide a valid account Number!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Account Number"
                      error={!!errors.accountNumber}
                      helperText={errors.accountNumber?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"ifscCode"}
                control={control}
                rules={{
                  required: "You must provide the IFSC code!",
                  pattern: {
                    // value: /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,
                    message: "You must provide a valid IFSC code!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="IFSC Code"
                      error={!!errors.ifscCode}
                      helperText={errors.ifscCode?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"branchCode"}
                control={control}
                rules={{
                  required: "You must provide the IFSC code!",
                  pattern: {
                    // value: /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,
                    message: "You must provide a valid IFSC code!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Branch Code"
                      error={!!errors.branchCode}
                      helperText={errors.branchCode?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company GSTN Details</b>
          </Typography>

          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"gstnNumber"}
                control={control}
                rules={{
                  required: "You must provide the GSTN Number!",
                  pattern: {
                    // value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    message: "You must provide a valid GSTN Number!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="GSTN Number"
                      error={!!errors.gstnNumber}
                      helperText={errors.gstnNumber?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>

            <GridItem xs={12} sm={6}>
              <Controller
                name={"gstnCityId"}
                control={control}
                rules={{
                  required: "gstn city is required",
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      label="GSTN City"
                      error={!!errors.gstnCityId}
                      helperText={errors.gstnCityId?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company TIN Number</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"tinNumber"}
                control={control}
                rules={{
                  required: "You must provide the TIN Number!",
                  pattern: {
                    // value: /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/,
                    message: "You must provide a valid TIN Number!",
                  },
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      // required
                      fullWidth
                      label="Tin Number"
                      error={!!errors.tinNumber}
                      helperText={errors.tinNumber?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                    />
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Agreement Copy</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"agreementDate"}
                control={control}
                render={({ onChange, value }) => {
                  return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="agreementDate"
                        label="Agreement Date"
                        margin="normal"
                        fullWidth
                        required
                        autoOk
                        inputVariant="outlined"
                        format="yyyy-MM-dd"
                        inputRef={register}
                        onChange={(date) =>
                          onChange(
                            `${date.getFullYear()}-${
                              date.getMonth() + 1
                            }-${date.getDate()}`
                          )
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        value={new Date(value)}
                      />
                    </MuiPickersUtilsProvider>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Doucument Uploads</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <span className={classes.span}>Cancelled Cheque Copy</span>
              <Documentcard
                description={"Cancelled Check Copy"}
                actionUpload={handleCancelledChequeUpload}
                className={classes.card}
                selected={
                  values.cancelledCheckInByte !== null
                    ? `data:image/jpeg;base64,${values.cancelledCheckInByte}`
                    : NoImage
                }
                imageHandler={handleCancelledChequeUpload}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span className={classes.span}>GSTN Copy</span>
              <Documentcard
                description={"GSTN Copy"}
                actionUpload={handleGstnUploadCopy}
                className={classes.card}
                selected={
                  values.gstnCopyInByte !== null
                    ? `data:image/jpeg;base64,${values.gstnCopyInByte}`
                    : NoImage
                }
                imageHandler={handleGstnUploadCopy}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span className={classes.span}>TIN Copy</span>
              <Documentcard
                description={"TIN Copy"}
                actionUpload={handleTinUploadCopy}
                className={classes.card}
                selected={
                  values.tinCopyInByte !== null
                    ? `data:image/jpeg;base64,${values.tinCopyInByte}`
                    : NoImage
                }
                imageHandler={handleTinUploadCopy}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span className={classes.span}>Agreement Copy</span>
              <Documentcard
                description={"Agreement Copy"}
                actionUpload={handleAgreementCopyUpload}
                className={classes.card}
                selected={
                  values.agreementCopyInByte !== null
                    ? `data:image/jpeg;base64,${values.agreementCopyInByte}`
                    : NoImage
                }
                imageHandler={handleAgreementCopyUpload}
              />
            </GridItem>
          </GridContainer>
          <div className={classes.Button}>
            <Button type="submit" color="primary" disabled={disableButton}>
              Update
            </Button>
          </div>
        </CardBody>
      </Card>
    </form>
  );
}
