import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer.js";
import GridItem from "Components/Grid/GridItem.js";
import Typography from "@material-ui/core/Typography";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import { callAPI } from "Utils/HttpUtil";
import TextField from "Components/TextField/TextField";
import { createSubCategory } from "Utils/ApiEndpoints";
import AlertMessage from "Components/Snackbar/SnackbarError";

function AddSubCategory(props) {
  const classes = styles();
  const { open, handleClose, setUpdate, categoryId } = props;
  const [subCategoryname, setSubCategoryName] = useState();
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
    color: null,
  });

  const onChange = (e) => {
    setSubCategoryName(e.target.value);
  };

  const apiSuccessCreateSubCategory = (res) => {
    if (res.data.message === "SUCCESS") {
      seterror({
        error: true,
        message: "Subcategory created successfully!",
        key: Math.random(),
        color: "success",
      });
      setUpdate(new Date());
    }
  };

  const apiErrorCallbackCreateSubCategory = (err) => {
    seterror({
      error: true,
      message: err.response.data.message,
      key: Math.random(),
      color: "error",
    });
    console.log(err);
  };

  const onSave = (e) => {
    if (subCategoryname) {
      callAPI(
        "POST",
        createSubCategory,
        {
          subCategoryName: subCategoryname,
          categoryId: categoryId,
        },
        apiSuccessCreateSubCategory,
        apiErrorCallbackCreateSubCategory
      );
    }
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Add Sub Category</Typography>
      </DialogTitle>

      <DialogContent style={{ marginTop: 30 }}>
        {error.error ? (
          <AlertMessage
            key={error.key}
            message={error.message}
            color={error.color}
          />
        ) : null}
        <GridContainer>
          <GridItem xs={12} md={6}>
            <TextField
              name="subCategoryName"
              label="Sub Category Name"
              onChange={onChange}
              required
            />
          </GridItem>
        </GridContainer>
        <DialogActions className={classes.dialogAction}>
          <Button type="submit" onClick={onSave} color="primary">
            Save
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
}

export default AddSubCategory;
