import React from "react";
import { makeStyles } from "@material-ui/core/styles";

// @material-ui/core
// @material-ui/icons

// core components

import styles from "../../assets/jss/material-dashboard-react/views/dashboardStyle";

const useStyles = makeStyles(styles);

export default function Dashboard() {
  const classes = useStyles();

  return <div className={classes.section}>Dashboard</div>;
}
