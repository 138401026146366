import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { mapSkillsToVendor, removeUserSkills } from "Utils/ApiEndpoints";
import { callAPI } from "Utils/HttpUtil";

function UserSkills(props) {
  const classes = styles();
  const {
    open,
    handleClose,
    selectAbleSkills,
    setUpdate,
    userId,
    selectedUserSkills,
  } = props;
  const userSkills = [...new Set(selectedUserSkills.map((id) => id))];
  const [selected, setSelected] = useState(userSkills);

  let username = localStorage.getItem("username");

  const disable = selected.length > 0 ? false : true;

  const categories = [
    ...new Set(selectAbleSkills.map((prop) => prop.categoryName)),
  ];

  const handleChange = (id) => {
    let newArray = [...selected, id];
    if (selected.includes(id)) {
      newArray = newArray.filter((skillId) => skillId !== id);
    }
    setSelected(newArray);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated Successfully!");
      handleClose();
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
    alert("Internal error please try after");
  };
  const onSaveSkills = async () => {
    const newSkills = selected.filter((item) => !userSkills.includes(item));
    if (newSkills.length > 0) {
      await callAPI(
        "POST",
        mapSkillsToVendor,
        {
          userName: username,
          userSkillsDTO: {
            skillsIds: newSkills,
          },
        },
        apiSuccessCallback,
        apiErrorCallback
      );
    } else {
      alert("Please select the skills to add");
    }
  };

  const onDelete = () => {
    callAPI(
      "POST",
      removeUserSkills,
      {
        skillsIds: selected,
        userId: userId,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  const AvailableSkills = (props) => {
    const { categoryName } = props;
    return (
      <FormGroup row>
        {selectAbleSkills.map((prop, index) => {
          if (prop.categoryName === categoryName) {
            return (
              <FormControlLabel
                classes={{ label: classes.label }}
                control={
                  <Checkbox
                    name={prop.skillName}
                    onChange={() => handleChange(prop.skillId)}
                    value={prop.skillId}
                    color="primary"
                    checked={selected.includes(prop.skillId)}
                  />
                }
                label={prop.skillName}
                key={index}
              />
            );
          } else {
            return null;
          }
        })}
      </FormGroup>
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title">
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogContent
        style={{
          marginTop: 30,
        }}
      >
        <GridContainer>
          {categories.map((prop, index) => {
            return (
              <GridItem xs={12} key={index}>
                <Typography
                  className={classes.Typography}
                >{`Select ${prop} Skills`}</Typography>
                <AvailableSkills categoryName={prop} />
              </GridItem>
            );
          })}
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSaveSkills} color="primary" disabled={disable}>
          Add
        </Button>
        <Button onClick={onDelete} color="danger" disabled={disable}>
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UserSkills;
