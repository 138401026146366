import React, { useEffect, useState } from "react";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import SearchIcon from "@material-ui/icons/Search";
import InputBase from "@material-ui/core/InputBase";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Tablecell,
  Tablerow,
  useStyles,
} from "assets/jss/material-dashboard-react/views/orders/tableStyle";
import { useHistory } from "react-router-dom";
import { callAPI } from "Utils/HttpUtil";
import { headCellsOrders, orderStatus } from "constants/Data";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import CustomButton from "Components/CustomButtons/Button";
import AddIcon from "@material-ui/icons/Add";
import TextField from "Components/TextField/TextField";
import { fetOrdersByStatus } from "Utils/ApiEndpoints";

export default function Tasks() {
  const classes = useStyles();
  const [page, setPage] = useState(0);
  const [data, setData] = useState({
    orders: [],
    loading: true,
  });
  const [filterStatus, setFilterStatus] = useState("Draft");

  const history = useHistory();

  const handleOnEdit = () => {
    history.push("/admin/orders/new_order");
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.status === 200) {
      setData({
        orders: responseData.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const onClick = (id) => {
    history.push(`/admin/orders/${id}`);
  };

  const handleChangeFilterStatus = (e) => {
    setFilterStatus(e.target.value);
  };

  useEffect(() => {
    callAPI(
      "POST",
      fetOrdersByStatus,
      {
        orderHeaderStatus: filterStatus,
        allStatus: false,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  }, [filterStatus]);

  if (data.loading === true) {
    return null;
  }

  return (
    <div className={classes.root}>
      <div className={classes.tableHeader}>
        <div className={classes.filter}>
          <TextField
            select
            variant="outlined"
            label="Status"
            fullWidth
            value={filterStatus}
            onChange={handleChangeFilterStatus}
            SelectProps={{
              MenuProps: {
                anchorOrigin: {
                  vertical: "bottom",
                  horizontal: "left",
                },
                classes: { paper: classes.menuPaper },
                getContentAnchorEl: null,
              },
            }}
          >
            {orderStatus.map((item) => (
              <MenuItem value={item.value}>{item.label}</MenuItem>
            ))}
          </TextField>
        </div>

        <CustomButton
          disableElevation
          disableTouchRipple
          disableRipple
          startIcon={<AddIcon />}
          color="primary"
          onClick={handleOnEdit}
          className={classes.appBarBtnContainer}
        >
          Create Order
        </CustomButton>
      </div>

      <div className={classes.grow}>
        <GridContainer>
          <GridItem xs={12} md={6}>
            <div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search Orders"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
                inputProps={{ "aria-label": "search" }}
              />
            </div>
          </GridItem>
        </GridContainer>
      </div>
      <Paper className={classes.paper}>
        <TableContainer>
          <Table className={classes.table}>
            <TableHead>
              <TableRow>
                {headCellsOrders.map((headCell, index) => (
                  <Tablecell key={index} align="left" padding="default">
                    {headCell.label}
                  </Tablecell>
                ))}
              </TableRow>
            </TableHead>

            <TableBody>
              {data?.orders?.length > 0
                ? data.orders
                    .slice(page * 10, page * 10 + 10)
                    .map((row, index) => {
                      return (
                        <Tablerow key={index}>
                          <Tablecell
                            component="th"
                            scope="row"
                            padding="default"
                            align="left"
                            className={classes.cellLink}
                            onClick={() => onClick(row.orderId)}
                          >
                            {row.orderHeaderId}
                          </Tablecell>
                          <Tablecell align="left">{row.customerName}</Tablecell>
                          <Tablecell align="left">{row.categoryName}</Tablecell>
                          <Tablecell align="left">
                            {row.subCategoryName}
                          </Tablecell>
                          <Tablecell align="left">
                            {row.problemTypeName}
                          </Tablecell>
                          <Tablecell align="left">
                            {row.scheduledDateTime}
                          </Tablecell>
                        </Tablerow>
                      );
                    })
                : null}
            </TableBody>
          </Table>

          {data?.orders?.length > 0 ? (
            <TablePagination
              rowsPerPageOptions={[10]}
              component="div"
              count={data?.orders?.length}
              rowsPerPage={10}
              page={page}
              onChangePage={handleChangePage}
            />
          ) : null}
        </TableContainer>
        {!data.orders ? (
          <div className={classes.noResultText}>0 records found</div>
        ) : null}
      </Paper>
    </div>
  );
}
