import React from "react";
import { useParams, useHistory } from "react-router-dom";
import Button from "Components/CustomButtons/Button";
import Category from "./Category";
import SubCategories from "./SubCategories";

function CategoryDetails() {
  const params = useParams();
  const history = useHistory();
  const categoryId = params.categoryId;

  return (
    <div>
      <Button
        color="secondary"
        onClick={() => history.push("/admin/categories")}
      >
        Back
      </Button>
      <Category categoryId={categoryId} />
      <SubCategories categoryId={categoryId} />
    </div>
  );
}

export default CategoryDetails;
