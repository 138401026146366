import mock from "../../../utils/mock";
import Data from "MockServer/db.json";

let { retriveUserDocuments } = Data;

mock.onPost("/updateUserDocumentStatus").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const username = data.username;
      console.log(data.serviceAreaId);
      const user = retriveUserDocuments.filter(
        (item) => item.userDetails.cellPhone === username
      );
      const responseData = user.map((item) => {
        let res = [];
        if (data.serviceAreaId !== null) {
          item.serviceArea
            .filter((item) => item.serviceAreaId === data.serviceAreaId)
            .map((prop) => {
              prop = {
                ...prop,
                status: data.status,
              };
              return res.push(prop);
            });
          return res;
        } else if (
          data.model === "personalDocuments" &&
          data.docType === "pan"
        ) {
          item.personalDocuments = {
            ...item.personalDocuments,
            statusPanCard: data.status,
          };
          return item.personalDocuments;
        } else if (
          data.model === "personalDocuments" &&
          data.docType === "aadhar"
        ) {
          item.personalDocuments = {
            ...item.personalDocuments,
            statusAadhar: data.status,
          };
          return item.personalDocuments;
        } else if (data.model === "bankDetails") {
          item.bankDetails = {
            ...item.bankDetails,
            status: data.status,
          };
          return item.bankDetails;
        }
        return null;
      });
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
