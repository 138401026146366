import { trackPromise } from "react-promise-tracker";
import axios from "axios";
import { readCookieByName } from "./Session";
import instance from "MockServer/utils/axios";
import "MockServer/mock/index";

export const config = {
  "Content-Type": "application/json",
  Authorization: "Bearer " + readCookieByName("access_token"),
};

export const callAPIOpen = (
  method,
  path,
  details,
  successCallback,
  errorCallback
) => {
  trackPromise(
    axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
      },
      data: details,
    })
      .then((responseData) => {
        successCallback(responseData);
      })
      .catch((err) => {
        errorCallback(err);
      })
  );
};

export const callAPI = (
  method,
  path,
  details,
  successCallback,
  errorCallback
) => {
  trackPromise(
    axios({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + readCookieByName("access_token"),
      },
      data: details,
    })
      .then((responseData) => {
        successCallback(responseData);
      })
      .catch((err) => {
        errorCallback(err);
      })
  );
};

export const callDocumentUploadAPI = (
  method,
  path,
  formData,
  successCallback,
  errorCallback
) => {
  trackPromise(
    axios({
      method: method,
      url: path,
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + readCookieByName("access_token"),
      },
      data: formData,
    })
      .then((responseData) => {
        successCallback(responseData);
      })
      .catch((err) => {
        errorCallback(err);
      })
  );
};

export const callMockAPI = (
  method,
  path,
  details,
  successCallback,
  errorCallback
) => {
  trackPromise(
    instance({
      method: method,
      url: path,
      headers: {
        "Content-Type": "application/json",
      },
      data: details,
    })
      .then((responseData) => {
        successCallback(responseData);
      })
      .catch((err) => {
        errorCallback(err);
      })
  );
};
