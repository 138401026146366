import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  cityContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  spacing: {
    marginRight: "10px",
  },

  img: {
    height: "120px",
    width: "200px",
    [theme.breakpoints.down("sm")]: {
      width: "150px",
    },
    border: "1px solid #D3CDCD",
    backgroundColor: "#FFFFFF",
  },
}));

export default useStyles;
