import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  checkboxLabel: {
    font: "normal normal bold 16px Helvetica Neue",
    color: " #070606",
    opacity: 1,
    textTransform: "capitalize",
  },

  spacing: {
    marginRight: 20,
  },

  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    width: "100%",
    verticalAlign: "center",
  },
  backbtn: {
    flexGrow: 0.4,
  },

  form: {
    marginTop: "50px",
    padding: 0,
  },
  sectionTitle: {
    marginTop: "20px",
    padding: 0,
  },

  error: {
    marginLeft: "10px",
    color: "#ff0000",
  },
  menuPaper: {
    maxHeight: 200,
  },
}));

export default useStyles;
