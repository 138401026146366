import React, { useState, useEffect } from "react";
import GridItem from "Components/Grid/GridItem.js";
import GridContainer from "Components/Grid/GridContainer.js";
import Card from "Components/Card/Card.js";
import CardBody from "Components/Card/CardBody.js";
import Button from "Components/CustomButtons/Button";
import Typography from "@material-ui/core/Typography";
import TextField from "@material-ui/core/TextField";
import styles from "assets/jss/material-dashboard-react/views/customerFormStyle";
import { useForm, Controller } from "react-hook-form";
import { MenuItem } from "@material-ui/core";
import Documentcard from "./DocumentUpload";
import { useHistory } from "react-router-dom";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import { callDocumentUploadAPI, callAPI } from "Utils/HttpUtil";
import {
  createB2BCustomer,
  getCities,
  getLookupValues,
} from "Utils/ApiEndpoints";
import AlertMessage from "Components/Snackbar/SnackbarError";

export default function CustomerB2B() {
  const classes = styles();
  const history = useHistory();
  const [source, setSource] = useState();
  const [cities, setCities] = useState();
  const [gstnCopy, setGstnCopy] = useState({
    image: null,
    imgUrl: null,
  });
  const [tinCopy, setTinCopy] = useState({
    image: null,
    imgUrl: null,
  });
  const [agreementCopy, setAgreementCopy] = useState({
    image: null,
    imgUrl: null,
  });
  const [cancelledCheque, setCancelledCheque] = useState({
    image: null,
    imageUrl: null,
  });
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const disableButton =
    gstnCopy.image === null ||
    tinCopy.image === null ||
    cancelledCheque.image === null ||
    agreementCopy.image === null
      ? true
      : false;

  const { register, handleSubmit, errors, control } = useForm({
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      companyName: "",
      pocName: "",
      spocEmail: "",
      pocNumber: "",
      source: "",
      type: "",
      address: "",
      area: "",
      pinCode: "",
      city: "",
      accountName: "",
      accountnumber: "",
      ifscCode: "",
      branchCode: "",
      cancelledCheckCopy: "",
      gstnNumber: "",
      gstnCopy: "",
      gstnCity: "",
      tinNumber: "",
      tinCopy: "",
      agreementDate: null,
      agreementCopy: "",
    },
  });

  const handleGstnUploadCopy = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setGstnCopy({
          image: reader.result,
          imageUrl: file,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleTinUploadCopy = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setTinCopy({
          image: reader.result,
          imageUrl: file,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  //

  const handleAgreementCopyUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setAgreementCopy({
          image: reader.result,
          imageUrl: file,
        });
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleCancelledChequeUpload = (e) => {
    e.preventDefault();
    const reader = new FileReader();
    const file = e.target.files[0];
    if (file !== null && file !== undefined) {
      reader.onloadend = () => {
        setCancelledCheque({
          image: reader.result,
          imageUrl: file,
        });
      };

      reader.readAsDataURL(e.target.files[0]);
    }
  };
  const onCancel = () => {
    history.push("/admin/customers/B2B");
  };

  const onApiSucessCallBackSaveCustomer = (res) => {
    if (res.data.status === 200) {
      seterror({
        error: true,
        message: "Customer created successfully!",
        key: Math.random(),
        color: "success",
      });
    }
  };

  const onApiErrorCallBackSaveCustomer = (err) => {
    seterror({
      error: true,
      message: err.response.data.message,
      key: Math.random(),
      color: "error",
    });
  };

  const onApiSucessCallGetCities = (res) => {
    if (res.data.status === 200) {
      setCities(res.data.responseData);
    }
  };

  const onApiErrorCallGetCities = (err) => {
    console.log(err);
  };

  const onApiSucessCallBackGetSource = (res) => {
    if (res.data.status === 200) {
      setSource(res.data.responseData);
    }
  };

  const onApiErrorCallBackGetSource = (err) => {
    console.log(err);
  };

  const onSubmit = (data) => {
    console.log(data);
    const formData = new FormData();
    formData.append("companyName", data.companyName);
    formData.append("pocName", data.pocName);
    formData.append("spocEmail", data.spocEmail);
    formData.append("pocNumber", data.pocNumber);
    formData.append("sourceId", data.source);
    formData.append("type", "B2B");
    formData.append("address", data.address);
    formData.append("area", data.area);
    formData.append("pinCode", data.pinCode);
    formData.append("cityId", data.city);
    formData.append("accountName", data.accountName);
    formData.append("accountNumber", data.accountNumber);
    formData.append("ifscCode", data.ifscCode);
    formData.append("branchCode", data.branchCode);
    formData.append("gstnNumber", data.gstnNumber);
    formData.append("gstnCityId", data.gstnCity);
    formData.append("tinNumber", data.tinNumber);
    formData.append("agreementDate", `${data.agreementDate}T00:00:00.45+01:00`);
    formData.append("cancelledCheckCopy", cancelledCheque.imageUrl);
    formData.append("agreementCopy", agreementCopy.imageUrl);
    formData.append("tinCopy", tinCopy.imageUrl);
    formData.append("gstnCopy", gstnCopy.imageUrl);
    callDocumentUploadAPI(
      "POST",
      createB2BCustomer,
      formData,
      onApiSucessCallBackSaveCustomer,
      onApiErrorCallBackSaveCustomer
    );
  };

  useEffect(() => {
    callAPI(
      "GET",
      getCities,
      {},
      onApiSucessCallGetCities,
      onApiErrorCallGetCities
    );
  }, []);

  useEffect(() => {
    callAPI(
      "POST",
      getLookupValues,
      { lookupType: "Source" },
      onApiSucessCallBackGetSource,
      onApiErrorCallBackGetSource
    );
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Card>
        <CardBody>
          {error.error ? (
            <AlertMessage
              key={error.key}
              message={error.message}
              color={error.color}
            />
          ) : null}
          <Typography variant="h6" align="center">
            <div align="left">
              <Button
                disableElevation
                disableTouchRipple
                color="secondary"
                onClick={onCancel}
              >
                Cancel
              </Button>
            </div>
            <b> Create Customer)</b>
          </Typography>
          <Typography align="left">
            <b>Customer Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="companyName"
                label="Company Name"
                name="companyName"
                error={!!errors.companyName}
                helperText={errors.companyName?.message}
                inputRef={register({
                  // required: "You must provide the company name!",
                  pattern: {
                    value: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
                    message: "You must provide a valid company name!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="pocName"
                label="POC Name"
                name="pocName"
                error={!!errors.pocName}
                helperText={errors.pocName?.message}
                inputRef={register({
                  required: "You must provide the POC name!",
                  pattern: {
                    value: /^[.@&]?[a-zA-Z0-9 ]+[ !.@&()]?[ a-zA-Z0-9!()]+/,
                    message: "You must provide a valid POC name!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="spocEmail"
                label="SPOC Email"
                name="spocEmail"
                error={!!errors.spocEmail}
                helperText={errors.spocEmail?.message}
                inputRef={register({
                  required: "You must provide the SPOC E-mail address!",
                  pattern: {
                    value: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                    message: "You must provide a valid SPOC E-mail address!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="pocNumber"
                label="POC Number"
                name="pocNumber"
                error={!!errors.pocNumber}
                helperText={errors.pocNumber?.message}
                inputRef={register({
                  required: "You must provide the POC Number!",
                  pattern: {
                    value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                    message: "You must provide a valid POC Number!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"source"}
                control={control}
                rules={{
                  required: "source is required",
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="source"
                      label="Source"
                      name="source"
                      defaultValue="select"
                      error={!!errors.source}
                      helperText={errors.source?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select source
                      </MenuItem>
                      {source
                        ? source.map((value, index) => {
                            return (
                              <MenuItem value={value.lkp_id} key={index}>
                                {value.value}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company Address</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="address"
                label="Address"
                name="address"
                error={!!errors.address}
                helperText={errors.source?.message}
                inputRef={register({
                  required: "You must provide the address",
                  pattern: {
                    message: "You must provide a valid address",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="area"
                label="Area"
                name="area"
                error={!!errors.area}
                helperText={errors.area?.message}
                inputRef={register({
                  required: "You must provide the area",
                  pattern: {
                    message: "You must provide a valid area",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="pinCode"
                label="Pincode"
                name="pinCode"
                error={!!errors.pinCode}
                helperText={errors.pinCode?.message}
                inputRef={register({
                  required: "You must provide the pin code!",
                  pattern: {
                    value: /^\d{6}$/,
                    message: "You must provide a valid Pin Code!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"city"}
                control={control}
                rules={{
                  required: "City is required",
                }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="city"
                      label="City"
                      name="city"
                      defaultValue="select"
                      error={!!errors.city}
                      helperText={errors.city?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company Account Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="accountName"
                label="Account Name"
                name="accountName"
                error={!!errors.accountName}
                helperText={errors.accountName?.message}
                inputRef={register({
                  required: "You must provide the account name!",
                  pattern: {
                    value: /^[a-zA-Z]*$/,
                    message: "You must provide a valid account name!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="accountnumber"
                label="Account Number"
                name="accountnumber"
                error={!!errors.accountnumber}
                helperText={errors.accountnumber?.message}
                inputRef={register({
                  required: "You must provide the account Number!",
                  pattern: {
                    // value: /^([0-9]{11})|([0-9]{2}-[0-9]{3}-[0-9]{6})$/,
                    message: "You must provide a valid account Number!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="ifscCode"
                label="IFSC Code"
                name="ifscCode"
                error={!!errors.ifscCode}
                helperText={errors.ifscCode?.message}
                accountNumber
                inputRef={register({
                  required: "You must provide the IFSC code!",
                  pattern: {
                    // value: /[A-Z|a-z]{4}[0][a-zA-Z0-9]{6}$/,
                    message: "You must provide a valid IFSC code!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="branchCode"
                label="Branch Code"
                name="branchCode"
                error={!!errors.branchCode}
                helperText={errors.branchCode?.message}
                inputRef={register({
                  required: "You must provide the branch code!",
                  pattern: {
                    message: "You must provide a valid branch code",
                  },
                })}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company GSTN Details</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="gstnNumber"
                label="GSTN Number"
                name="gstnNumber"
                error={!!errors.gstnNumber}
                helperText={errors.gstnNumber?.message}
                inputRef={register({
                  required: "You must provide the GSTN Number!",
                  pattern: {
                    // value: /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/,
                    message: "You must provide a valid GSTN Number!",
                  },
                })}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"gstnCity"}
                control={control}
                rules={{ required: "city is required" }}
                render={({ onChange, value, ref }) => {
                  return (
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      id="gstnCity"
                      label="GSTN City"
                      name="gstnCity"
                      defaultValue="select"
                      error={!!errors.gstnCity}
                      helperText={errors.gstnCity?.message}
                      onChange={(event) => onChange(event.target.value)}
                      value={value}
                      inputRef={ref}
                      select
                    >
                      <MenuItem value="select" disabled>
                        select city
                      </MenuItem>
                      {cities
                        ? cities.map((value, index) => {
                            return (
                              <MenuItem value={value.cityId} key={index}>
                                {value.cityName}
                              </MenuItem>
                            );
                          })
                        : null}
                    </TextField>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Company TIN Number</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <TextField
                variant="outlined"
                margin="normal"
                // required
                fullWidth
                id="tinNumber"
                label="TIN Number"
                name="tinNumber"
                error={!!errors.tinNumber}
                helperText={errors.tinNumber?.message}
                inputRef={register({
                  required: "You must provide the TIN Number!",
                  pattern: {
                    // value: /^(?:\d{3}-\d{2}-\d{4}|\d{2}-\d{7})$/,
                    message: "You must provide a valid TIN Number!",
                  },
                })}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Agreement Copy</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <Controller
                name={"agreementDate"}
                control={control}
                rules={{ required: "agreement date is required" }}
                render={({ onChange, value }) => {
                  return (
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        name="agreementDate"
                        label="Agreement Date"
                        margin="normal"
                        fullWidth
                        required
                        autoOk
                        disablePast
                        error={!!errors.agreementDate}
                        helperText={errors.agreementDate?.message}
                        inputVariant="outlined"
                        format="yyyy-MM-dd"
                        inputRef={register}
                        onChange={(date) =>
                          onChange(
                            `${date.getFullYear()}-${
                              date.getMonth() + 1
                            }-${date.getDate()}`
                          )
                        }
                        InputProps={{
                          readOnly: true,
                        }}
                        value={value}
                      />
                    </MuiPickersUtilsProvider>
                  );
                }}
              />
            </GridItem>
          </GridContainer>
          <Typography align="left">
            <b>Doucument Uploads</b>
          </Typography>
          <GridContainer>
            <GridItem xs={12} sm={6}>
              <span>Cancelled Check Copy</span>
              <Documentcard
                description={"Cancelled Check Copy"}
                actionUpload={handleCancelledChequeUpload}
                className={classes.card}
                selected={cancelledCheque.image}
                imageHandler={handleCancelledChequeUpload}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span>GSTN Copy</span>
              <Documentcard
                description={"GSTN Copy"}
                actionUpload={handleGstnUploadCopy}
                className={classes.card}
                selected={gstnCopy.image}
                imageHandler={handleGstnUploadCopy}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span>TIN Copy</span>
              <Documentcard
                description={"TIN Copy"}
                actionUpload={handleTinUploadCopy}
                className={classes.card}
                selected={tinCopy.image}
                imageHandler={handleTinUploadCopy}
              />
            </GridItem>
            <GridItem xs={12} sm={6}>
              <span>Agreement Copy</span>
              <Documentcard
                description={"Agreement Copy"}
                actionUpload={handleAgreementCopyUpload}
                className={classes.card}
                selected={agreementCopy.image}
                imageHandler={handleAgreementCopyUpload}
              />
            </GridItem>
          </GridContainer>
          <div className={classes.Button}>
            <Button type="submit" color="primary" disabled={disableButton}>
              Create
            </Button>
          </div>
        </CardBody>
      </Card>
    </form>
  );
}
