import React, { useState } from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Button from "Components/CustomButtons/Button";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Typography from "@material-ui/core/Typography";
import { styles } from "assets/jss/material-dashboard-react/components/dialogStyle";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { callAPI } from "Utils/HttpUtil";
import { updateUserCategories, removeUserCategories } from "Utils/ApiEndpoints";

function UpdateCategories(props) {
  const classes = styles();
  const {
    open,
    handleClose,
    cityCategories,
    setUpdate,
    userId,
    categories,
  } = props;

  const userCategories = [
    ...new Set(categories.map((category) => category.categoryId)),
  ];
  const [selected, setSelected] = useState(userCategories);

  let username = localStorage.getItem("username");

  const onChange = (id) => {
    let newArray = [...selected, id];
    if (selected.includes(id)) {
      newArray = newArray.filter((skillId) => skillId !== id);
    }
    setSelected(newArray);
  };

  const disable = selected.length > 0 ? false : true;

  const apiSuccessCallback = (res) => {
    if (res.data.message === "SUCCESS") {
      setUpdate(new Date());
      alert("Updated sucessfully");
      handleClose();
    } else {
      alert(res.data.message);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
    alert("Internal error please try after some time");
  };

  const onSave = () => {
    const newCategories = selected.filter(
      (item) => !userCategories.includes(item)
    );
    if (newCategories.length > 0) {
      callAPI(
        "POST",
        updateUserCategories,
        {
          categoryIds: selected,
          userName: username,
        },
        apiSuccessCallback,
        apiErrorCallback
      );
    } else {
      alert("Please select the categories to add.");
    }
  };

  const onDelete = () => {
    callAPI(
      "POST",
      removeUserCategories,
      {
        categoryIds: selected,
        userId: userId,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  return (
    <Dialog
      aria-labelledby="customized-dialog-title"
      open={open}
      maxWidth={"lg"}
      fullWidth
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogTitle id="customized-dialog-title" style={{ paddingBottom: 0 }}>
        <Button variant="contained" color="secondary" onClick={handleClose}>
          Back
        </Button>
      </DialogTitle>
      <DialogTitle className={classes.title}>
        <Typography className={classes.titleText}>Select Categories</Typography>
      </DialogTitle>

      <DialogContent
        style={{
          marginTop: 30,
        }}
      >
        <GridContainer justify="center">
          <GridItem xs={12} md={4}>
            <FormGroup row>
              {cityCategories.map((prop, index) => {
                return (
                  <GridItem xs={12} md={6} key={index}>
                    <FormControlLabel
                      classes={{ label: classes.label }}
                      control={
                        <Checkbox
                          name={prop.categoryName}
                          onChange={() => onChange(prop.categoryId)}
                          value={prop.categoryId}
                          color="primary"
                          checked={selected.includes(prop.categoryId)}
                        />
                      }
                      label={prop.categoryName}
                    />
                  </GridItem>
                );
              })}
            </FormGroup>
          </GridItem>
        </GridContainer>
      </DialogContent>
      <DialogActions>
        <Button onClick={onSave} disabled={disable} color="primary">
          Add
        </Button>
        <Button onClick={onDelete} disabled={disable} color="danger">
          Remove
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default UpdateCategories;
