import React, { useState, useEffect } from "react";
import Card from "Components/Card/Card.js";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import "assets/css/style.css";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/skillsStyle";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import UpdateCategories from "Components/Dialogs/approvals/UpdateCategories";
import { callAPI } from "Utils/HttpUtil";
import { cityBasedCategories } from "Utils/ApiEndpoints";

function Categories(props) {
  const classes = useStyles(props);
  const { categories, setUpdate, cityId, userId } = props;
  const [dialog, setDialog] = useState(false);
  const [data, setData] = useState({
    cityCategories: [],
    loading: true,
  });
  const selectedCategories = categories.map((category) => category.categoryId);

  const handleClose = () => {
    setDialog(false);
  };

  const handleOnEdit = () => {
    setDialog(true);
  };

  const apiSuccessCallback = (res) => {
    if (res.data.message === "SUCCESS") {
      //setCityCategories(res.data.responseData);
      setData({
        cityCategories: res.data.responseData,
        loading: false,
      });
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  useEffect(() => {
    callAPI(
      "POST",
      cityBasedCategories,
      { cityId: cityId },
      apiSuccessCallback,
      apiErrorCallback
    );
  }, [cityId]);

  if (data.loading === true) {
    return null;
  }

  return (
    <div>
      <CardHeader
        className={classes.cardHeader}
        title="Category Details"
        disableTypography
      />
      {dialog ? (
        <UpdateCategories
          open={dialog}
          handleClose={handleClose}
          setUpdate={setUpdate}
          cityCategories={data.cityCategories}
          userId={userId}
          categories={categories}
        />
      ) : null}
      <Card className={classes.root}>
        <CardHeader
          className={classes.cardSubHeader}
          action={
            <IconButton onClick={handleOnEdit}>
              <EditIcon />
            </IconButton>
          }
          title="Selected Categories"
        />
        <FormGroup row>
          {data.cityCategories.map((prop, index) => {
            return (
              <FormControlLabel
                className={classes.skillsContainer}
                control={
                  <Checkbox
                    name={prop.categoryName}
                    value={prop.categoryId}
                    color="primary"
                    checked={selectedCategories.includes(prop.categoryId)}
                  />
                }
                label={prop.categoryName}
                key={index}
              />
            );
          })}
        </FormGroup>
      </Card>
    </div>
  );
}

export default Categories;
