import mock from "../../../utils/mock";
import Data from "MockServer/db.json";

let { retriveUserDocuments } = Data;

mock.onPost("/mapCategoriesToVendor").reply((config) => {
  return new Promise(function (resolve) {
    setTimeout(function () {
      const data = JSON.parse(config.data);
      const username = data.username;
      const user = retriveUserDocuments.filter(
        (item) => item.userDetails.cellPhone === username
      );
      data.categories.forEach((item) =>
        user.map((i) =>
          i.userDetails.userCategoryDTOList.push({
            categoryId: i.userDetails.userCategoryDTOList.length + 1,
            categoryName: item,
          })
        )
      );
      const responseData = retriveUserDocuments.map((item) => {
        if (item.userDetails.cellPhone === username) {
          return item.userDetails.userCategoryDTOList;
        } else {
          return null;
        }
      });
      resolve([200, { message: "SUCCESS", responseData: responseData }]);
    }, 1000);
  });
});
