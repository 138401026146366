import Button from "@material-ui/core/Button";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme) => ({
  button: {
    backgroundColor: (props) => props.backgroundcolor,
    color: "#070606",
    borderRadius: "25px",
    width: "105px",
    [theme.breakpoints.down("sm")]: {
      width: "auto",
      fontSize: "8px",
    },
    marginRight: "10px",
    fontWeight: "bold",
    fontSize: "14px",
    border: (props) => `1px solid ${props.border}`,
    "&:hover": {
      backgroundColor: (props) => props.backgroundcolor,
    },
  },
}));

function CustomButton(props) {
  const { children, ...rest } = props;
  const classes = useStyles(props);
  return (
    <Button {...rest} className={classes.button}>
      {children}
    </Button>
  );
}

export default CustomButton;
