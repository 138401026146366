import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardBody from "Components/Card/CardBody";
import TextField from "Components/TextField/TextField";
import { Typography } from "@material-ui/core";
import Button from "Components/CustomButtons/Button";
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { callMockAPI } from "Utils/HttpUtil";
import useStyles from "assets/jss/material-dashboard-react/views/propertyManagement/existingProperty";
import Snackbar from "Components/Snackbar/SnackbarError";

function ExistingUser() {
  const classes = useStyles();
  const history = useHistory();

  const { register, handleSubmit, errors } = useForm();
  const [userDetails, setUserDetails] = useState([]);
  const [error, seterror] = useState({
    error: null,
    message: null,
    key: null,
  });

  const onApiSuccessGetDetails = (res) => {
    if (res.data.message === "SUCCESS") {
      setUserDetails(res.data.responseData);
    } else {
      setUserDetails([]);
      seterror({
        error: true,
        message: "no property details found",
        key: Math.random(),
      });
    }
  };

  const getDetails = (data) => {
    if (data) {
      callMockAPI(
        "POST",
        "/property_management_property_details",
        { phone: data.mobileNumber },
        onApiSuccessGetDetails
      );
    }
  };

  return (
    <div>
      <Card>
        <CardBody>
          {error.error ? (
            <Snackbar key={error.key} message={error.message} color="error" />
          ) : null}
          <Typography align="center">
            <b>Existing Property</b>
          </Typography>
          <div className={classes.add_property_btn}>
            <Button
              color="primary"
              onClick={() =>
                history.push("/admin/property_management/new_property")
              }
              disableRipple
            >
              Add Property
            </Button>
          </div>
          <form onSubmit={handleSubmit((data) => getDetails(data))}>
            <GridContainer>
              <GridItem xs={12} md={4}>
                <TextField
                  name="mobileNumber"
                  label="Mobile Number"
                  margin="normal"
                  inputRef={register({
                    required: "mobile number is required!",
                    pattern: {
                      value: /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/,
                      message: "invalid mobile number",
                    },
                  })}
                  inputProps={{
                    maxLength: 10,
                  }}
                  error={Boolean(errors.mobileNumber)}
                  helperText={errors.mobileNumber?.message}
                />
                <Button color="primary" type="submit" disableRipple>
                  Get Details
                </Button>
              </GridItem>
            </GridContainer>
          </form>
          {userDetails.length > 0 ? (
            <GridContainer>
              {userDetails.map((item) =>
                item.properties.map((prop, index) => {
                  return (
                    <GridItem xs={6} sm={4} md={3} key={index}>
                      <div className={classes.property_details}>
                        {prop.propertyname}
                      </div>
                    </GridItem>
                  );
                })
              )}
            </GridContainer>
          ) : null}
        </CardBody>
      </Card>
    </div>
  );
}

export default ExistingUser;
