import React, { useState } from "react";
import GridContainer from "Components/Grid/GridContainer";
import GridItem from "Components/Grid/GridItem";
import Card from "Components/Card/Card";
import CardHeader from "@material-ui/core/CardHeader";
import IconButton from "@material-ui/core/IconButton";
import EditIcon from "@material-ui/icons/Edit";
import useStyles from "assets/jss/material-dashboard-react/views/approvals/sectionStyle";
import Button from "Components/CustomButtons/CustomButton";
import BankDetailsUpdate from "Components/Dialogs/approvals/BankDetailsUpdate";
import { callAPI } from "Utils/HttpUtil";
import { updateUserDocuments } from "Utils/ApiEndpoints";
import Tablerow from "Components/Table/Tablerow";

function BankDetails(props) {
  const [dialog, setDialog] = useState(false);
  const { bankdetails, userId, setUpdate } = props;
  const classes = useStyles(props);

  const handleClose = () => {
    setDialog(false);
  };

  const handleOnEdit = () => {
    setDialog(true);
  };

  const apiSuccessCallback = (responseData) => {
    if (responseData.data.responseData === "Status Updated") {
      setUpdate(new Date());
      alert(responseData.data.responseData);
    } else {
      alert(responseData.data.responseData);
    }
  };

  const apiErrorCallback = (err) => {
    console.log(err);
  };

  const updateStatus = (e) => {
    callAPI(
      "POST",
      updateUserDocuments,
      {
        userId: userId,
        status: e.currentTarget.value,
        model: "bankDetails",
        docType: "",
        serviceAreaId: null,
      },
      apiSuccessCallback,
      apiErrorCallback
    );
  };

  if (!bankdetails) {
    return null;
  }

  return (
    <div>
      <CardHeader
        className={classes.cardHeader}
        disableTypography
        title={"Bank Account Details"}
        action={
          <IconButton aria-label="settings" onClick={handleOnEdit}>
            <EditIcon />
          </IconButton>
        }
      />
      {dialog ? (
        <BankDetailsUpdate
          open={dialog}
          handleClose={handleClose}
          bankdetails={bankdetails}
          setUpdate={setUpdate}
        />
      ) : null}
      <Card className={classes.root}>
        <div className={classes.root}>
          <GridContainer>
            <GridItem xs={12} md={6}>
              <Tablerow
                name="Account Number"
                value={bankdetails.accountNumber}
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Tablerow
                name="Branch Name"
                value={bankdetails.branchName}
                capitalize="uppercase"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Tablerow
                name="Bank Name"
                value={bankdetails.bankName}
                capitalize="uppercase"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Tablerow
                name="IFSC Code"
                value={bankdetails.ifscCode}
                capitalize="uppercase"
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Tablerow
                name="Cancelled Cheque Copy"
                value={
                  bankdetails.proof === null ? (
                    "null"
                  ) : (
                    <img
                      className={classes.img}
                      alt="...."
                      src={`data:image/jpeg;base64,${bankdetails.proof}`}
                    />
                  )
                }
              />
            </GridItem>
            <GridItem xs={12} md={6}>
              <Tablerow name="Status" value={bankdetails.status} />
            </GridItem>
          </GridContainer>
          {bankdetails.status === "Verification" ||
          bankdetails.status === "Pending" ? (
            <div className={classes.btnContainer}>
              <Button
                backgroundcolor="#11F79F48"
                border="#0ED68A"
                value="Approved"
                onClick={updateStatus}
              >
                Approve
              </Button>
              <Button
                backgroundcolor="#F7244D58"
                border="#FC3D62D8"
                value="Rejected"
                onClick={updateStatus}
              >
                Reject
              </Button>
              <Button
                backgroundcolor="#F8931D58"
                border="#F08A14"
                value="Reassign"
                onClick={updateStatus}
                disabled
              >
                Re-assign
              </Button>
            </div>
          ) : null}
        </div>
      </Card>
    </div>
  );
}

export default BankDetails;
